// hooks/usePagination.js
import { useState, useEffect } from "react";

const usePagination = (data, initialRowsPerPage = 10) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [currentRows, setCurrentRows] = useState([]);
  const totalPages = Math.ceil(data.length / rowsPerPage);

  useEffect(() => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    setCurrentRows(data.slice(indexOfFirstRow, indexOfLastRow));
  }, [currentPage, rowsPerPage, data]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return {
    currentPage,
    currentRows,
    setCurrentRows,
    totalPages,
    paginate,
    setCurrentPage,
    setRowsPerPage,
    rowsPerPage, // Ensure this is returned for use in PaginationControls
  };
};

export default usePagination;
