import React, { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { format, parseISO } from 'date-fns';
import html2canvas from 'html2canvas';

const BookmarkDetails = ({ bookmarkId }) => {
  const chartRef = useRef(null);
  const [favosData, setFavosData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = useSelector(state => state?.authSlice?.id);
  const [selectedPeriod, setSelectedPeriod] = useState('month');
  const [yAxisMin, setYAxisMin] = useState(0);
  const [yAxisMax, setYAxisMax] = useState(100);

  useEffect(() => {
    const fetchFavosData = async () => {
      try {
        const favosResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/DisplayFavos/${userId}/${bookmarkId}/`);
        if (favosResponse.status === 200) {
          setFavosData(favosResponse.data);
        } else {
          console.error('Error fetching favos data:', favosResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching favos data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFavosData();
  }, [userId, bookmarkId]);

  useEffect(() => {
    handleFilter(selectedPeriod);
  }, [favosData, selectedPeriod]);

  const handleFilter = (period) => {
    const currentDate = new Date();
    let startDate;

    if (period === 'day') {
      startDate = new Date(currentDate);
      startDate.setHours(0, 0, 0, 0);
    } else if (period === 'week') {
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 6);
    } else if (period === 'month') {
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 30);
    } else {
      startDate = new Date(currentDate);
    }

    const filtered = favosData.filter(item => {
      const itemDate = new Date(item.rank.date);
      return itemDate >= startDate && itemDate <= currentDate;
    });

    const averageRank = calculateAverageRank(filtered);
    setFilteredData([{ rank: { rank: averageRank, date: format(new Date(), 'yyyy-MM-dd') } }]);
    setYAxisMin(averageRank);
    setYAxisMax(averageRank);
  };

  const calculateAverageRank = (data) => {
    const { sum, count } = data.reduce(({ sum, count }, item) => {
      if (item.rank.rank !== 101) {
        sum += item.rank.rank;
      }
      return { sum, count: count + 1 };
    }, { sum: 0, count: 0 });

    const average = count > 0 ? parseFloat((sum / count).toFixed(1)) : 0;
    return average;
  };

  useEffect(() => {
    if (favosData.length > 0) {
      const averageRank = calculateAverageRank(favosData);
      setFilteredData([{ rank: { date: new Date().toISOString(), rank: averageRank } }]);
      const yAxisAdjustment = Math.max(Math.abs(averageRank - 5), 0);
      setYAxisMin(Math.min(0, averageRank - yAxisAdjustment));
      setYAxisMax(averageRank + yAxisAdjustment);
    }
  }, [favosData]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ClipLoader color={'#2563EB'} loading={loading} size={50} />
        <p className="ml-2 text-lg text-black">Loading...</p>
      </div>
    );
  }

  const chartData = [{
    x: new Date(filteredData[0].rank.date).getTime(),
    y: filteredData[0].rank.rank,
  }];

  const chartOptions = {
    chart: {
      id: 'chart-id',
      type: 'line',
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: '#50e915',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    xaxis: {
      title: {
        text: 'Date',
      },
      reversed: true,
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: 'Rank',
      },
      labels: {
        formatter: (value) => value.toFixed(0),
      },
      reversed: true,
      min: yAxisMin,
      max: yAxisMax,
    },
    colors: ['#50e915'],
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -5,
      style: {
        fontSize: '14px',
        colors: ['#fff'],
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      background: {
        enabled: true,
        foreColor: '#000',
        borderRadius: 2,
        padding: 3,
        borderColor: '#50e915',
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45,
      },
    },
  };

  const exportToExcel = async () => {
    const wb = XLSX.utils.book_new();
    const wsData = [];
    wsData.push(['Date', 'Rank (Win)']);
    filteredData.forEach((item) => {
      wsData.push([format(parseISO(item.rank.date), 'MMM. d, yyyy'), item.rank.rank]);
    });

    const canvas = await html2canvas(chartRef.current);
    const chartImage = canvas.toDataURL('image/png');

    wsData.push(['Chart Image', '', '']);

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    const chartWs = XLSX.utils.json_to_sheet([{ image: chartImage }]);
    XLSX.utils.book_append_sheet(wb, chartWs, 'Chart');

    XLSX.writeFile(wb, `QueryData_${bookmarkId}.xlsx`);
  };

  return (
    <div className="relative flex flex-col z-[100] bg-white max-w-screen-xl mx-auto p-3 mt-4 border-2 border-gray-200 rounded-lg">
      <div className="flex flex-col items-center justify-between w-full p-2 space-y-2 text-black rounded-lg lg:flex-row lg:space-y-0 lg:space-x-2">
        <button
          onClick={exportToExcel}
          className="px-2 py-1 text-white transition-all duration-300 ease-in-out bg-green-500 border border-green-500 rounded-lg hover:bg-green-600 hover:text-white"
        >
          Export 
        </button>

        <div className="flex items-center justify-center space-x-2">
          {['day', 'week', 'month'].map(period => (
            <button
              key={period}
              className={`px-4 py-2 text-sm font-bold rounded-md transition-colors duration-300 ${selectedPeriod === period ? 'bg-mainColor text-white shadow-sm' : 'bg-white text-mainColor hover:bg-mainColor hover:text-white hover:shadow'
                }`}
              onClick={() => {
                setSelectedPeriod(period);
                handleFilter(period);
              }}
            >
              {period.charAt(0).toUpperCase() + period.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div className="w-full mt-4" ref={chartRef}>
        <Chart options={chartOptions} series={[{ data: chartData }]} type="line" height={250} />
      </div>
    </div>
  );
};

export default BookmarkDetails;
