import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { formatDateForBackend, processDateForExcel } from '../utils/Functions';

const useExportToExcel = (setIsLoading) => {

  const fetchDataFromApi = async (userId, projectId) => {
    setIsLoading(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/userRankExcel/${userId}/${projectId}/`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false)
      return data;
    } catch (error) {
      console.error('There has been a problem with your fetch operation:', error);
      toast.error("Failed to fetch data for export.");
      return [];
    }
  };

  const exportToExcel = async (userId, projectId) => {
    if (!userId || !projectId) {
      toast.error("User ID and Project ID are required for export.");
      return;
    }

    const rawData = await fetchDataFromApi(userId, projectId);
    if (rawData.length > 0) {
     try{
      const processedData = rawData.map(item => {
        const { date, time } = processDateForExcel(item.date);
        const formattedDate = formatDateForBackend(date);
        const { date: _, ...rest } = item;
        return {
          ...rest,
          date: formattedDate,
          time,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(processedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'FilteredKeywords');

      const fileName = `FilteredKeywords_${new Date().toISOString().slice(0, 19).replace(/:/g, "-")}.xlsx`;

      XLSX.writeFile(workbook, fileName);
      toast.success("Data exported successfully");

    
     }
    catch (error) {
      toast.error("Failed to export data.");
    }
  }
     else {
      toast.info("No data available for export.");
    }
  };

  return { exportToExcel };
};

export default useExportToExcel;
