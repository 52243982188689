const ManagedUserInfo = ({ managedUserName, revertToOriginalUser }) => {
  return (
    <div className="text-md 3xl:mx-40 2xl:mx-16 xl:mx-9 lg:mx-3">
      You are currently managing {managedUserName} -{" "}
      <button
        onClick={revertToOriginalUser}
        className="py-2 text-black underline transition duration-300 ease-in-out rounded"
      >
        Back to My Account
      </button>
    </div>
  );
};
export default ManagedUserInfo;
