// import { createSlice } from "@reduxjs/toolkit";


// const initialState = {
//   // isAuth: false,
//   token:null,
//   id:null,
//   verfied:false,
  
// };
// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     login: (state, action) => {
//       //  state.isAuth = action.payload.isAuth;
//       state.user = action.payload;
//       state.token = action.payload.token;
//       state.id = action.payload.id; 
//       state.verfied = action.payload.verfied; 


//     },
//     logOut: (state, action) => {
//     //  state.isAuth = false;
//       state.user = null;
//       state.token = null;
//       state.id = null;
//       state.verfied = null;
//     },
//   },
// });


// export const { login, logOut } = authSlice.actions;
// export default authSlice.reducer;
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   token: null,
//   id: null,
//   verified: false,
//   managed_original: false,
  
// };

// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     login: (state, action) => {
//       state.user = action.payload;
//       state.token = action.payload.token;
//       state.id = action.payload.id;
//       state.verified = action.payload.verified;
//       state.managed_original = false; // Ensure managed is false on initial login
//     },
//     logOut: (state, action) => {
//       state.user = null;
//       state.token = null;
//       state.id = null;
//       state.verified = null;
//       state.managed_original = false; // Reset managed state on logout
//     },
//     setManaged_original: (state, action) => {
//       state.managed = action.payload;
//     },
//   },
// });

// export const { login, logOut, setManaged_original } = authSlice.actions;
// export default authSlice.reducer;



import { createSlice,createAsyncThunk  } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  id: null,
  verified: false,
  managed: false,
  // dailyQuota: 0,    // New field for daily quota
  accountType: null,  // New field for account type

};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      console.log(state,"state")
      console.log(action,state,"action")

      state.user = action.payload;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.verified = action.payload.verified;
      state.managed = action.payload.Roles && action.payload.Roles.length > 0 && Object.keys(action.payload.Roles[0]).length > 0;
      // state.dailyQuota = action.payload.daily_quota;
      state.accountType = action.payload.ACCOUNT_TYPE;

    },
    logOut: (state, action) => {
      state.user = null;
      state.name = null;
      state.token = null;
      state.id = null;
      state.verified = null;
      state.managed = false;
      // state.dailyQuota = 0;   // Reset daily quota on logout
      state.accountType = null;  // Reset account type on logout
    },
    setManaged: (state, action) => {
      state.managed = action.payload;
    },
    // decrementDailyQuota: (state, action) => {
    //   const decrementAmount = action.payload || 1;  // Default to decrementing by 1
    //   state.dailyQuota = Math.max(state.dailyQuota - decrementAmount, 0);  // Ensure quota doesn't go below 0
    // },
  },
});

export const { login, logOut, setManaged 
  ,
  // decrementDailyQuota  
} = authSlice.actions;
export default authSlice.reducer;
