import React, { useState, useEffect, useMemo } from "react";

const RankRange = ({ queries }) => {

  const calculateRangeData = useMemo(() => {
    const rangeCounts = {
      "1-3": 0,
      "4-10": 0,
      "11-20": 0,
      "21-50": 0,
      "51+": 0,
    };

    queries.forEach(query => {
      const rank = query.rank;
      if (rank >= 1 && rank <= 3) rangeCounts["1-3"]++;
      else if (rank >= 4 && rank <= 10) rangeCounts["4-10"]++;
      else if (rank >= 11 && rank <= 20) rangeCounts["11-20"]++;
      else if (rank >= 21 && rank <= 50) rangeCounts["21-50"]++;
      else if (rank >= 51) rangeCounts["51+"]++;
    });

    const totalQueries = queries.length;
    const calculatedRangeData = {};
    Object.keys(rangeCounts).forEach(range => {
      calculatedRangeData[range] = {
        count: rangeCounts[range],
        percentage: totalQueries > 0 ? Math.round((rangeCounts[range] / totalQueries) * 100) : 0,
      };
    });

    return calculatedRangeData;
  }, [queries]);

  const [rangeData, setRangeData] = useState(calculateRangeData);

  useEffect(() => {
    setRangeData(calculateRangeData);
  }, [calculateRangeData]);

  const getRangeColorClass = (range) => {
    switch (range) {
      case "1-3": return "bg-green-600";
      case "4-10": return "bg-green-400";
      case "11-20": return "bg-yellow-400";
      case "21-50": return "bg-red-400";
      case "51+": return "bg-red-600";
      default: return "bg-gray-800";
    }
  };

  return (
    <div className="flex flex-col justify-between p-4 bg-white dark:bg-slate rounded h-full ">
      <h2 className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
        Rank Ranges
      </h2>

      <ul className="mt-[20px] max-w-[85%] md:m-auto">
        {Object.entries(rangeData).map(([range, data]) => (
          <li
            key={range}
            className={`grid grid-cols-6 items-center  py-2 rounded-lg text-sm text-gray-700 dark:text-gray-300 mb-2
          
          `}
          >
            <div
              className={`inline-block w-3 h-3 mr-2 rounded-full col-span-1 text-center ${getRangeColorClass(
                range
              )}`}
            ></div>
            <div className="col-span-2">{range}</div>

            <div className="col-span-2">{data.percentage}%</div>

            <div className="font-bold  col-span-1">({data.count})</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RankRange;
