import React, { useState, useEffect, useRef, useCallback } from "react";
import Content from "./Content";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from "../redux/lib/errorSlice";
import useWebSocket from "../hook/useWebSocket";
import { login, setManaged } from "../redux/lib/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../components/Error/ErrorMessage";
import { MdOutlineFeedback } from "react-icons/md";
import FeedbackButton from "../components/FeedbackButton";
import Navbar from "../components/Navbar/Navbar";
import Login from "../pages/auth/Login";
import axios from "axios";
import Cookies from "js-cookie";
import QuotaBar from "../components/Navbar/QuotaBar";

const Layout = () => {
  const [show, setShow] = useState(window.innerWidth >= 1012);
  const errorMessage = useSelector((state) => state.errorSlice.message);
  const verified = useSelector((state) => state.authSlice.user.verified);

  const dispatch = useDispatch();
  const storedUserId = useRef(null);
  const userId = useSelector((state) => state?.authSlice?.id);
  const managed = useSelector((state) => state.authSlice.managed);
  const originalUser = useSelector((state) => state.originalUserSlice);
  const isAuth = useSelector((state) => state?.authSlice?.user?.token);
  const [processComplete, setProcessCompleted] = useState(false);
  const [activeProject, setActiveProject] = useState(null); // Active project state
  const [topThreeProjects, setTopThreeProjects] = useState([]); // Top three projects state
  const [isQuotaBarVisible, setIsQuotaBarVisible] = useState(true);
  const [pin_project, setPin_project] = useState(false);
  const handleDismissQuotaBar = () => {
    setIsQuotaBarVisible(false);
  };

  const navigate = useNavigate();
  const { wsMessage, closeMsg, openMsg } = useWebSocket(
    `wss://${process.env.REACT_APP_API_URL_WS}/ws`
  );

  const location = useLocation();
  const projectDetails = location.state?.projectDetails;
  const revertToOriginalUser = useCallback(() => {
    if (originalUser) {
      dispatch(
        login({
          token: originalUser.token,
          id: originalUser.id,
          name: originalUser.name,
          email: originalUser.email,
          verified: verified,
          Roles: [],
        })
      );

      dispatch(setManaged(false));

      toast.info("Returned to your original account");
      navigate("/projects");
    } else {
      toast.error("No original user data found. Please log in again.");
    }
  }, [dispatch, navigate]);

  const toggle = () => {
    setShow(!show);
  };

  const clearCookies = () => {
    const allCookies = Cookies.get();
    Cookies.set("userId", userId);
  };

  useEffect(() => {
    clearCookies();
    const handleResize = () => {
      setShow(window.innerWidth >= 1012);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (wsMessage) {
      const regex = /Permissions updated successfully:(\d+)/;
      if (typeof wsMessage === "string") {
        const match = wsMessage.match(regex);

        if (match && match[1]) {
          const extractedUserId = parseInt(match[1], 10);
          storedUserId.current = userId;
          if (extractedUserId !== userId && managed) {
            revertToOriginalUser();
            toast.success(
              "Permissions updated successfully, You have been logged out",
              {
                autoClose: 10000,
              }
            );
          }
        }
      }
    }
  }, [wsMessage, userId, managed, revertToOriginalUser]);

  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);
  const isThreeSegmentPath = pathSegments.length === 2;
console.log(pathSegments,"pathSegments")



const path_Segments = location.pathname
  .split("/")
  .filter((segment) => segment);

console.log(path_Segments[0], "first index of pathSegments");

  return (
    <>
      {isAuth ? (
        <div className="flex mx-auto ">
          <Sidebar
            toggle={toggle}
            show={show}
            openedProjectName={projectDetails?.name}
            activeProject={activeProject}
            setActiveProject={setActiveProject}
            topThreeProjects={topThreeProjects}
            setTopThreeProjects={setTopThreeProjects}
            pin_project={pin_project} setPin_project={setPin_project}
            path_Segments={path_Segments}
          />
          <div
            className={`fixed bottom-0 right-0 w-full  text-center text-white z-[1005]`}
          >
            {!verified && (
              <p className="w-full bg-green-600 py-2 text-sm md:text-base">
                Please verify your account through email to access all features.
              </p>
            )}
          </div>

          <div className={`w-full z-5 ${show ? "ml-[15%]" : "ml-0"}`}>
            <Navbar
              toggle={toggle}
              show={show}
              setProcessCompleted={setProcessCompleted}
              processComplete={processComplete}
            />
            <Content
              show={show}
              processComplete={processComplete}
              activeProject={activeProject}
              setActiveProject={setActiveProject}
              topThreeProjects={topThreeProjects}
              setTopThreeProjects={setTopThreeProjects}
              pin_project={pin_project} setPin_project={setPin_project}

            />
          </div>
          {!isThreeSegmentPath && <FeedbackButton />}
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Layout;
