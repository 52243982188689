import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../../redux/lib/auth';
import confirmation from '../../assets/images/image.png'
const CheckExecitance = () => {
    const { token } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showSignUp, setShowSignUp] = useState(false);  // To manage Sign Up button visibility
    const [showLogin, setShowLogin] = useState(false);  
    const currentUserToken = useSelector(state => state.authSlice.token); // Assuming token is stored in auth state
    // const currentUserEmail = localStorage.getItem("EmailUser"); // Make sure you have user email in your auth state, trimming spaces for safety
    const currentUserEmail = useSelector(state => state.authSlice.email); // Assuming token is stored in auth state

    useEffect(() => {
        const checkTokenExistence = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check_existence/${token}/`);
                if (response.data.error) {
                    setErrorMessage(response.data.error);
                    if (response.data.error.includes('No user found')) {
                        if (currentUserToken) {
                            dispatch(logOut());
                        }
                        else{
                        setShowSignUp(true); 

                        }
                     

                    }
                    if (response.data.error.includes('Invitation role already exists for this user.')) {
                        setShowLogin(true); // Show login button if the user exists but isn't logged in

                        if (currentUserEmail !== response.data.email) {
                            dispatch(logOut()); // You still need to log out the current user, but do it silently.
                            setErrorMessage(response.data.error);
                            setShowLogin(true); // Instead of navigating immediately, show the login button.
                            // Do not navigate('/login') here; let the user decide to click the login button.
                        } else {
                            navigate('/projects'); // Redirect to projects page

                        }
                        
                        } else {
                            setErrorMessage(response.data.error);
                        }
                    

                    
                } else {
                    setErrorMessage("this is erorroo messs")
                }
            } catch (error) {
                console.error("Error checking token existence:", error);
                setErrorMessage('Unable to verify the token, please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        checkTokenExistence();
    }, [token, currentUserToken, dispatch, navigate]);

   
    return (
<div className="relative flex items-center
 justify-center min-h-screen bg-gray-100 ">
  <div className="relative w-full max-w-md p-6 
  text-center bg-white rounded-lg shadow-lg h-[400px] ">
    <img src={confirmation} alt="Confirmation" className="mx-auto image-container" />

    {errorMessage && (
      <p className="mt-4 text-gray-400 text-md">{errorMessage}</p>
    )}

      {showSignUp && (
                    <button
                        onClick={() => navigate(`/register/${token}`)}
                        className="absolute bottom-0 left-[-18px] right-0 w-full py-2 mx-6 font-medium text-white transition duration-300 ease-in-out bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-orange-500"
                    >
                        Sign Up
                    </button>
                )}
                  {showLogin && (
                    <button
                        onClick={() => navigate('/login')}
                        className="absolute bottom-0 left-[-18px] 
                        right-0 w-full py-2 mx-6 font-medium text-white transition duration-300 ease-in-out bg-gradient-to-r from-orange-400 via-orange-500 to-orange-600 hover:bg-gradient-to-br focus:outline-none focus:ring-2 focus:ring-orange-500"
                    >
                        Login
                    </button>
                )}

  </div>
</div>

    );
};
export default CheckExecitance;