import React from "react";

// Spinner component
const Spinner = () => (
  <div className="w-4 h-4 mx-2 border-b-2 border-white rounded-full animate-spin"></div>
);

const Confirmation_Update = ({
  isOpen,
  onClose,
  onConfirm,
  message,
  isUpdating,
  numberOfKeywords,
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
      className="pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen place-items-center bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
    >
      <div className="w-full max-w-sm p-4 bg-white rounded-lg shadow-lg">
        <h3 className="text-lg font-semibold">Notification</h3>
        <p className="mt-4">
          You are about to refresh <strong>{numberOfKeywords}</strong>{" "}
          {numberOfKeywords > 1 ? "keywords" : "keyword"}.
        </p>
        <p className="mt-2 text-sm text-gray-600">
          Please confirm to proceed refresh your keyword.
        </p>
        <div className="flex justify-end mt-6 space-x-4">
          <button
            className="px-4 py-2 font-bold text-black bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
            disabled={isUpdating}
          >
            Cancel
          </button>
          <button
            className="flex items-center px-4 py-2 font-bold text-white rounded bg-mainColor hover:bg-mainColor-700"
            onClick={onConfirm}
            disabled={isUpdating}
          >
            {isUpdating ? (
              <>
                <Spinner /> Updating...
              </>
            ) : (
              "Proceed"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation_Update;
