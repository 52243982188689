import React from "react";

const SkeletonCard = ({ count }) => {
  return (
    <div className="flex flex-wrap w-full p-4 m-auto mt-4 space-x-3 justify-evenly">
      {Array.from({ length: count }, (_, index) => (
        <div
          key={index}
          className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 bg-white rounded-lg h-52 animate-pulse m-2"
        >
          <div className="w-4/5 h-4 mx-auto mt-4 bg-gray-200 rounded-md animate-shimmer"></div>
          <div className="w-3/5 h-4 mx-auto mt-2 bg-gray-200 rounded-md animate-shimmer"></div>
          <div className="w-4/6 h-4 mx-auto mt-2 bg-gray-200 rounded-md animate-shimmer"></div>
          <div className="w-2/5 h-4 mx-auto mt-2 bg-gray-200 rounded-md animate-shimmer"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonCard;
