export const locationOptions = [
  { value: 'All Locations', label: 'All Locations' },
  { value: 'US', label: 'USA', image: 'https://flagcdn.com/us.svg' },
  { value: 'EG', label: 'Egypt', image: 'https://flagcdn.com/eg.svg' },
  { value: 'AE', label: 'UAE', image: 'https://flagcdn.com/ae.svg' },
  { value: 'UK', label: 'United Kingdom', image: 'https://flagcdn.com/gb.svg' },
  { value: 'FR', label: 'France', image: 'https://flagcdn.com/fr.svg' },
  { value: 'DE', label: 'Germany', image: 'https://flagcdn.com/de.svg' },
  { value: 'JP', label: 'Japan', image: 'https://flagcdn.com/jp.svg' },
  { value: 'IN', label: 'India', image: 'https://flagcdn.com/in.svg' },
  { value: 'CA', label: 'Canada', image: 'https://flagcdn.com/ca.svg' },
  { value: 'AU', label: 'Australia', image: 'https://flagcdn.com/au.svg' },
  { value: 'BR', label: 'Brazil', image: 'https://flagcdn.com/br.svg' },
  { value: 'MX', label: 'Mexico', image: 'https://flagcdn.com/mx.svg' },
  { value: 'RU', label: 'Russia', image: 'https://flagcdn.com/ru.svg' },
  { value: 'ZA', label: 'South Africa', image: 'https://flagcdn.com/za.svg' },
  { value: 'KR', label: 'South Korea', image: 'https://flagcdn.com/kr.svg' },
  { value: 'SE', label: 'Sweden', image: 'https://flagcdn.com/se.svg' },
  { value: 'IT', label: 'Italy', image: 'https://flagcdn.com/it.svg' },
  { value: 'ES', label: 'Spain', image: 'https://flagcdn.com/es.svg' },
  { value: 'NL', label: 'Netherlands', image: 'https://flagcdn.com/nl.svg' },
  { value: 'TR', label: 'Turkey', image: 'https://flagcdn.com/tr.svg' },
  { value: 'SG', label: 'Singapore', image: 'https://flagcdn.com/sg.svg' },
  { value: 'MY', label: 'Malaysia', image: 'https://flagcdn.com/my.svg' },
  { value: 'TH', label: 'Thailand', image: 'https://flagcdn.com/th.svg' },
  { value: 'ID', label: 'Indonesia', image: 'https://flagcdn.com/id.svg' },
  { value: 'CN', label: 'China', image: 'https://flagcdn.com/cn.svg' },

  { value: 'SA', label: 'Saudi Arabia', image: 'https://flagcdn.com/sa.svg' },
  { value: 'NO', label: 'Norway', image: 'https://flagcdn.com/no.svg' },
  { value: 'DK', label: 'Denmark', image: 'https://flagcdn.com/dk.svg' },
  { value: 'FI', label: 'Finland', image: 'https://flagcdn.com/fi.svg' },
  { value: 'PL', label: 'Poland', image: 'https://flagcdn.com/pl.svg' },

];
