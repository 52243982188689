import React from "react";

const ResetFiltersButton = ({ onClick }) => {
  return (
    <>
      <div className="Back">
        <button
          onClick={onClick}
          className="w-full  items-center justify-center font-semibold mr-2 ml-[8px] bg-mainColor
           px-4 py-2 h-[40px] hover:bg-greeng text-white rounded transition duration-150 ease-in-out lg:text-sm text-xs hidden lg:block"
        >
          Back to All data
        </button>
      </div>
      <div className="Back">
        <button
          onClick={onClick}
          className="w-full  items-center justify-center font-semibold mr-2 ml-[8px] bg-mainColor p-2 h-[40px] hover:bg-greeng text-white rounded transition duration-150 ease-in-out lg:text-sm text-xs block lg:hidden"
        >
          Back
        </button>
      </div>
    </>
  );
};

export default ResetFiltersButton;
