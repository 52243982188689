import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessing";
import InitialDataDisplay from "../combined/InitialDataDisplay";

const DetailsUrl = () => {
  const params = useParams();
  const Params_id = params.id;
  const userId = useSelector((state) => state?.authSlice?.id);
  const apiUrl = `${process.env.REACT_APP_API_URL}/keyword/url-data/${userId}/${params.id}/`;
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit, isKeywordLoading, isUrlLoading, keywordData, urlData } =
    useKeywordAndUrlProcessing(dispatch, userId);
  const h2Columns = [
    { name: "Keyword", selector: (row) => row.keyword, sortable: true },
    { name: "Count", selector: (row) => row.count, sortable: true },
  ];

  const h3Columns = [
    { name: "Keyword", selector: (row) => row.keyword, sortable: true },
    { name: "Count", selector: (row) => row.count, sortable: true },
  ];

  const fetchData = async (retryCount = 5) => {
    setIsLoading(true);
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        if (response.status === 502 && retryCount > 0) {
          // Retry after 1 second if a 502 error occurs
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return fetchData(retryCount - 1); // Retry the request
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const result = await response.json();

      result.forEach((item) => {
        // Parse and log h3_count
        try {
          const h3Count = JSON.parse(
            item.h3_count.replace(/\\/g, "").replace(/'/g, '"')
          );
          Object.entries(h3Count).forEach(([key, value]) => {
            console.log(`${key}: ${value}`);
          });
        } catch (error) {
          console.error("Error parsing h3_count:", error);
        }

        // Parse and log h2_count
        try {
          const h2Count = JSON.parse(
            item.h2_count.replace(/\\/g, "").replace(/'/g, '"')
          );
          Object.entries(h2Count).forEach(([key, value]) => {
            console.log(`${key}: ${value}`);
          });
        } catch (error) {
          console.error("Error parsing h2_count:", error);
        }

        // Parse and log results_sample
        try {
          const resultsSample = JSON.parse(
            item.results_sample.replace(/\\/g, "").replace(/'/g, '"')
          );
          resultsSample.forEach((keyword) => {
            console.log(
              `Keyword: ${keyword.keyword}, Count: ${keyword.count}, Avg: ${keyword.avg}`
            );
          });
        } catch (error) {
          console.error("Error parsing results_sample:", error);
        }
      });

      setInitialData(result);
      setIsLoading(false);
    } catch (error) {
      if (retryCount > 0) {
        console.log(`Retrying... Attempts left: ${retryCount - 1}`);
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
        return fetchData(retryCount - 1); // Retry the request
      } else {
        setError(error.message);
        console.error("Error fetching data:", error);
      }
    } finally {
    }
  };
  useEffect(() => {
    fetchData();
  }, [apiUrl]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching details</div>;

  // const dataToRender = initialData;
  // const h2DataArray = urlData?.H2
  //   ? Object.entries(urlData.H2).map(([keyword, count]) => ({ keyword, count }))
  //   : [];
  // const h3DataArray = urlData?.H3
  //   ? Object.entries(urlData.H3).map(([keyword, count]) => ({ keyword, count }))
  //   : [];
  console.log(urlData, "urlData");
  return (
    <div className="relative w-full  mx-auto mt-[5rem] rounded-lg bg-baseColor md:mt-[2.5rem]  md:p-10">
      <InitialDataDisplay
        handleSubmit={handleSubmit}
        isKeywordLoading={isKeywordLoading}
        isUrlLoading={isUrlLoading}
        initialData={initialData}
        h2Columns={h2Columns}
        h3Columns={h3Columns}
        urlData={urlData}
        Params_id={Params_id}
        setInitialData={setInitialData}
      />
    </div>
  );
};

export default DetailsUrl;
