import React from 'react';
import { FiCheck, FiX } from "react-icons/fi";

const PasswordRequirements = ({ passwordStrength, initialView, requirements = [] }) =>
   {
    const passwordRequirements = [
      { text: "At least 8 characters long", key: "length" },
      { text: "Contains at least one uppercase letter", key: "uppercase" },
      { text: "Contains at least one lowercase letter", key: "lowercase" },
      { text: "Contains at least one number", key: "number" },
      { text: "Contains at least one special character", key: "special" },
    ];
  return(
    <div className="mb-4">
    <h3 className="mb-2 font-semibold">Password Requirements:</h3>
    <ul className="list-none text-sm">
      {passwordRequirements.map((req, index) => (
        <li key={index} className="flex items-center mb-1">
          {initialView ? (
            <span className="mr-2 text-black font-bold">•</span>
          ) : (
            passwordStrength[req.key] ? (
              <FiCheck className="mr-2 text-green-500" />
            ) : (
              <FiX className="mr-2 text-red-500" />
            )
          )}
          <span className={initialView ? "text-black" : (passwordStrength[req.key] ? "text-green-500" : "text-red-500")}>
            {req.text}
          </span>
        </li>
      ))}
    </ul>
  </div>
  )
};

export default PasswordRequirements;
