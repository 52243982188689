import * as z from "zod";
const domainPattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+\/?$/;
const passwordSchema = z.string()
  .min(8, { message: "Password must be at least 8 characters long" })
  .regex(/[a-z]/, { message: "Password must contain at least one lowercase letter" })
  .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
  .regex(/[0-9]/, { message: "Password must contain at least one number" })
  .regex(/[^a-zA-Z0-9]/, { message: "Password must contain at least one special character" });

export const loginSchema = z.object({
  identifier: z.union([z.string().email(), z.string().min(1, 'Username/Email is required')]),
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

export const keywordschema = z.object({
  keywords: z.string().nonempty('Keyword(s) is required'),
  url: z.string().regex(domainPattern, { message: "Please enter a valid Url" }),
}).optional();


export const rankschema = z.object({
  keywords: z.string().nonempty('Keyword(s) is required'),
  url: z.string().nonempty('URL is required'),
}).optional();

export const registrationSchema = z.object({
  username: z
  .string()
  .min(3, "Username must be at least 3 characters long")
  .max(30, "Username must not exceed 30 characters")
  .regex(/^[a-zA-Z0-9]+$/, "Username must contain only English letters and numbers"),
  email: z.string().email(),
  // password: z.string().min(8),
  // password2: z.string().min(8),
  password: passwordSchema,
  password2:passwordSchema

}).refine((data) => data.password === data.password2, {
  message: "Passwords don't match",
  path: ["rePassword"], 
});
export const registrationTokenSchema = z.object({
  username: z.string().min(1, 'Username is required'), 
  // password: z.string().min(8),
  // password2: z.string().min(8),
    password: passwordSchema,
  password2:passwordSchema
}).refine((data) => data.password === data.password2, {
  message: "Passwords don't match",
  path: ["rePassword"], 
});

export const formSchema = z.object({
  firstDropdown: z.string().nonempty({ message: "This field is required" }),
  secondDropdown: z.array(z.string()).nonempty({ message: "This field is required" }),
});


export const resetPasswordSchema = z.object({
  password: z.string()
    .min(8, "Password must be at least 8 characters long")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[0-9]/, "Password must contain at least one number")
    .regex(/[^A-Za-z0-9]/, "Password must contain at least one special character"),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});