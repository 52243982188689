import React from 'react';

const SkeletonTabs = () => {
  return (
    <div className="max-w-screen-3xl mx-auto py-4 mt-4 animate-pulse">
      <div className="flex space-x-4 mb-4">
        <div className="w-[50%] h-6 bg-gray-200 rounded">

        </div>
        <div className="w-[50%] h-6 bg-gray-200 rounded">

        </div>
      </div>
      {/* <div className="mt-4 overflow-x-auto border-2 border-gray-200 rounded-md h-64 bg-gray-100 mb-4"></div>
      <div className="mt-4 overflow-x-auto border-2 border-gray-200 rounded-md h-64 bg-gray-100"></div> */}
    </div>
  );
};

export default SkeletonTabs;
