import React from 'react';

const HeadingTitle = ({ title }) => {
  return (
    <h1 className="font-semibold px-2 text-2xl 
     border-l-4 border-mainColor 
    rounded transition-all duration-300 ease-in-out">
      {title}
    </h1>
  );
};

export default HeadingTitle;