import React from "react";
import { FaCheck } from "react-icons/fa";

const CheckHead = ({
  row,
  checkedRows,
  handleSelectChange,
  handleSelectAllClick,
  isSelectAll,
  isAllSelected,
}) => {
  return (
    <div
      className=" px-5 py-[2px]
   text-center"
    >
      <input
        type="checkbox"
        id={isSelectAll ? "select-all-checkbox" : `checkbox-${row.query_id}`}
        className="peer hidden"
        checked={isAllSelected()}
        onChange={(e) => {
          e.stopPropagation();
          isSelectAll
            ? handleSelectAllClick(e)
            : handleSelectChange(e, row.query_id);
        }}
      />
      <label
        htmlFor={
          isSelectAll ? "select-all-checkbox" : `checkbox-${row?.query_id}`
        }
        className="flex items-center justify-center border-2 border-gray-300 
      rounded w-4 h-4
       bg-white peer-checked:bg-mainColor peer-checked:border-mainColor"
      >
        <FaCheck className="   text-white" />
      </label>
    </div>
  );
};

export default CheckHead;
