import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { keywordschema } from "../utils/validation";
import { addKeyword } from "../redux/lib/keyword";
import { fetchData } from "../redux/lib/fetchData";
import { extractDomainName } from "../utils/Functions";
import createLogQuery from "../pages/SidebarPages/Log/createLogQuiries";
import { decrementDailyQuota } from "../redux/lib/auth";
import { selectAuthDetails } from "../redux/selectors";

const useAddQuery = (
  userId,
  projectId,
  managed,
  roles,
  projectDetails,
  setFilteredData,
  isFolderSelected,
  selectedBookmarkId,
  selectedLocation,
  completedAdds,
  setCompletedAdds,
  addErrors,
  setAddErrors,
  showAddProgressBar,
  setShowAddProgressBar,
  totalAdds,
  setTotalAdds,
  pin_project,
  setPin_project
) => {
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const originalUser = useSelector((state) => state.originalUserSlice.name);
  console.log(originalUser,"originalUser")

  const NameUser = useSelector((state) => state.authSlice.user.name);
console.log(NameUser,"NameUser")
  // const dailyQuota = useSelector((state) => state.authSlice.dailyQuota);
  const { reset, setError } = useForm({
    defaultValues: {
      url: projectDetails?.url || "", // Ensure projectDetails is defined
      keywords: "",
    },
    resolver: zodResolver(keywordschema),
  });
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const normalizeQueries = (queries) => {
    return queries.map((query) => {
      const words = query
        .split(/\s+/)
        .map((word) => word.trim())
        .filter((word) => word.length > 0);
      console.log(words, "wordswordswordswords");
      if (words.includes('"')) {
        return words.join("");
      } else {
        return words.join(" ");
      }
    });
  };

  // const onBulkSubmit = async (data) => {
  //   // Check if user has enough quota
  //   // if (dailyQuota < 1) {
  //   //   toast.error("You have reached your daily quota limit.");
  //   //   return;
  //   // }

  //   setShowAddProgressBar(true); // Show the progress bar

  //   setIsDataLoading(true);
  //   if (isSubmitting) return;
  //   setIsSubmitting(true);
  //   const currentDate = new Date();
  //   let queries = data.keywords
  //     .split("\n")
  //     .map((line) => line.trim())
  //     .filter((line) => line);

  //   // Normalize queries
  //   queries = normalizeQueries(queries);

  //   if (managed) {
  //     // Only check permissions if the user is managed
  //     if (queries.length > 1 && !roles.Add_bulk_query) {
  //       // Handling bulk queries
  //       setError("keywords", {
  //         type: "custom",
  //         message: "You do not have permission to add bulk queries.",
  //       });
  //       setIsDataLoading(false);
  //       setIsSubmitting(false);
  //       return;
  //     } else if (queries.length === 1 && !roles.Add_single_query) {
  //       // Handling single query
  //       setError("keywords", {
  //         type: "custom",
  //         message: "You do not have permission to add single queries.",
  //       });
  //       setIsDataLoading(false);
  //       setIsSubmitting(false);
  //       return;
  //     }
  //   }
  //   setIsAdding(true);
  //   const currentUrl = data.url;
  //   const newQuery = data.keywords;
  //   reset({
  //     keywords: "",
  //     url: currentUrl,
  //   });
  //   const domainName = extractDomainName(currentUrl); // Use the extract function

  //   setTotalAdds(queries.length);
  //   console.log(data, "data");

  //   const tempKeywordData = queries.map((query) => ({
  //     new_query: query,
  //     target_url: domainName,
  //     google_domain: data?.location,
  //     temp: true,
  //     date: currentDate,
  //     rank: "Loading...",
  //     best_rank: "Loading...",
  //     origin: "Loading...",
  //   }));
  //   setFilteredData((currentData) => [...tempKeywordData, ...currentData]);

  //   // Chunk queries to avoid overwhelming the server
  //   const queryChunks = chunkArray(queries, 10);
  //   for (const chunk of queryChunks) {
  //     // Check if enough quota remains for the chunk
  //     // if (dailyQuota < chunk.length) {
  //     //   toast.error("Not enough daily quota remaining to add all queries.");
  //     //   break;
  //     // }

  //     const addQueries = chunk.map(async (query) => {
  //       try {
  //         // Attempt to add the query using the retryRequest function
  //         const response = await retryRequest(
  //           query,
  //           data.url,
  //           data?.location,
  //           data.bookmark // Include bookmark data here
  //         );
  //     console.log(response,"response")
      
  //         // If successful, create a log entry for the added query
  //         const logMessage = `Query: ${query} Added with Target_url: ${domainName} in project ${projectId} by ${originalUser}`;
  //         await createLogQuery(logMessage, userId);
      
  //         return response;
  //       } catch (error) {
  //         console.error("Error adding keyword:", error);
  //         throw error; // Ensure the error is thrown so it can be caught in the Promise.all block
  //       }
  //     });
      

  //     try {
  //       await Promise.all(addQueries);
  //       // setPin_project(!pin_project);

  //       // Decrement daily quota by the number of queries successfully added
  //     } catch (error) {
  //       if (error.name !== "AbortError") {
  //         console.error("Error in bulk update:", error);
  //       }
  //       // setPin_project(!pin_project);
  //     } finally {
  //       // setPin_project(!pin_project);
  //       // dispatch(fetchData({ userId, projectId }));
  //     }
  //   }
  //   setPin_project(!pin_project);

  //   setIsSubmitting(false);
  //   setIsAdding(false);
  //   setIsDataLoading(false);
  //   setHasSubmitted(true);
  // };
  const onBulkSubmit = async (data) => {
    setShowAddProgressBar(true); // Show the progress bar
  
    setIsDataLoading(true);
    if (isSubmitting) return;
    setIsSubmitting(true);
    const currentDate = new Date();
    let queries = data.keywords
      .split("\n")
      .map((line) => line.trim())
      .filter((line) => line);
  
    // Normalize queries
    queries = normalizeQueries(queries);
  
    if (managed) {
      // Only check permissions if the user is managed
      if (queries.length > 1 && !roles.Add_bulk_query) {
        setError("keywords", {
          type: "custom",
          message: "You do not have permission to add bulk queries.",
        });
        setIsDataLoading(false);
        setIsSubmitting(false);
        return;
      } else if (queries.length === 1 && !roles.Add_single_query) {
        setError("keywords", {
          type: "custom",
          message: "You do not have permission to add single queries.",
        });
        setIsDataLoading(false);
        setIsSubmitting(false);
        return;
      }
    }
    setIsAdding(true);
    const currentUrl = data.url;
    const domainName = extractDomainName(currentUrl) || "Invalid URL";
    
    setTotalAdds(queries.length);
    console.log(data, "data");
  
    const tempKeywordData = queries.map((query) => ({
      new_query: query,
      target_url: domainName,
      google_domain: data?.location,
      temp: true,
      date: currentDate,
      rank: "Loading...",
      best_rank: "Loading...",
      origin: "Loading...",
    }));
    setFilteredData((currentData) => [...tempKeywordData, ...currentData]);
  
    // Chunk queries to avoid overwhelming the server
    const queryChunks = chunkArray(queries, 10);
    for (const chunk of queryChunks) {
      const addQueries = chunk.map(async (query) => {
        try {
          const response = await retryRequest(
            query,
            data.url,
            data?.location,
            data.bookmark // Include bookmark data here
          );
  
          console.log(response, "response");
  
          const logMessage = `Query: ${query} Added with Target_url: ${domainName} in project ${projectId} by ${NameUser}`;
          await createLogQuery(logMessage, userId);
  
          return response;
        } catch (error) {
          console.error("Error adding keyword:", error);
          throw error; // Ensure the error is thrown so it can be caught in the Promise.all block
        }
      });
  
      try {
        await Promise.all(addQueries);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error in bulk update:", error);
        }
      } finally {
        dispatch(fetchData({ userId, projectId }));
      }
    }
    setPin_project(!pin_project);
  
    setIsSubmitting(false);
    setIsAdding(false);
    setIsDataLoading(false);
    setHasSubmitted(true);
  };
  
  const retryRequest = async (query, targetUrl, googleDomain, bookmarkId) => {
    const retryLimit = 10;
    let attempt = 0;
    let requestSuccessful = false;
    bookmarkId = isFolderSelected ? selectedBookmarkId : bookmarkId; // Use the provided bookmarkId if not in folder mode
    while (attempt < retryLimit && !requestSuccessful) {
      try {
        const response = await dispatch(
          addKeyword({
            keywordData: {
              query,
              target_url: targetUrl,
              google_domain: googleDomain,
            },
            userId,
            projectId,
          })
        ).unwrap();
        const queryId = response[0][0].query_id;

        if (bookmarkId && response) {
          await addQueryToBookmark(queryId, bookmarkId);
        }
        if (response && Array.isArray(response) && response.length > 0) {
          // Handle successful request
          const rankData = response[0][0];
          updateFilteredDataWithSuccess(query, rankData);
          requestSuccessful = true;
        } else {
          throw new Error(
            "Invalid response format or no data returned for query: " + query
          );
        }
      } catch (error) {
        console.error("Error adding keyword:", error);
        attempt++;
        await new Promise((resolve) => setTimeout(resolve, 2000 * attempt)); // Exponential back-off
      }
    }
    if (!requestSuccessful) {
      // Handle failed request after all attempts
      setAddErrors((prev) => prev + 1);
    }
  };

  const updateFilteredDataWithSuccess = (query, rankData, isSmallScreen) => {
    setFilteredData((currentData) => {
      const newData = currentData.filter((item) => item.new_query !== query);
      const updatedQuery = {
        ...currentData.find((item) => item.temp && item.new_query === query),
        ...rankData,
        temp: false,
      };
      return [updatedQuery, ...newData];
    });
    let rank = JSON.parse(sessionStorage.getItem("rankAdd")) || [];
    rank = rank.concat(query);
    let newRank = [...new Set(rank)];
    sessionStorage.setItem("rankAdd", JSON.stringify(newRank));
    setCompletedAdds((prev) => prev + 1);
  };

  const addQueryToBookmark = async (queryId, bookmarkId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/favourites/${userId}/${queryId}/${bookmarkId}/${projectId}/`;
      const response = await axios.get(apiUrl);
      if (response.status === 201) {
        dispatch(fetchData({ userId, projectId }));
      } else {
        console.error("Failed to add query to bookmark: ", response);
        toast.error("Failed to add query to bookmark.");
      }
    } catch (error) {
      console.error("Error adding query to bookmark:", error);
      toast.error("An error occurred while adding the query to the bookmark.");
    }
  };

  return {
    isDataLoading,
    isSubmitting,
    totalAdds,
    completedAdds,
    addErrors,
    showAddProgressBar,
    hasSubmitted,
    onBulkSubmit,
    addQueryToBookmark,
  };
};

export default useAddQuery;
