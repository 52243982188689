import React from 'react';

const ContentBrief = ({wordCount }) => (
    <div className="flex flex-col justify-between flex-grow ">
        <p><strong>Avg Count:</strong> </p>
       <p> {wordCount} <span> words </span> </p>
    </div>
);

export default ContentBrief;
