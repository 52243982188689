import React, { useState } from "react";
import KeywordsTable from "./KeywordsTable";
import ArticleContent from "./ArticleContent";
import SkeletonDataTable from "../../../Skeleton/SkeletonDataTable";
import SkeletonArticleContent from "../../../Skeleton/SkeletonArticleContent";
import { jsonrepair } from "jsonrepair";

const CompanyTabs = ({ initialData ,isUrlLoading }) => {
  const [selectedKeyword, setSelectedKeyword] = useState("");
  // Replace single quotes with double quotes
  const validJsonString = jsonrepair(initialData[0]?.results_sample)

// initialData[0]?.results_sample.replace(/'/g, '"');
  // Parse the JSON string to an array
  const dataArray = JSON.parse(validJsonString);

  const handleKeywordClick = (keyword) => {
    setSelectedKeyword(keyword);
  };
console.log(dataArray,'initialData');


  return (
    <>
      <div
        className="lg:grid lg:grid-cols-5   mx-auto mt-8
        gap-6  md:justify-between 
      max-w-screen-2xl"
      >
       

{isUrlLoading ? (
        <>
          <SkeletonDataTable />
          <SkeletonArticleContent />
        </>
      ) : (
        <>
          <KeywordsTable onKeywordClick={handleKeywordClick} data={dataArray} />
          <ArticleContent
            selectedKeyword={selectedKeyword}
            initialData={initialData[0]}
          />
        </>
      )}

      </div>
    </>
  );
};

export default CompanyTabs;
