import React from 'react';
import { FaCheck } from 'react-icons/fa';

const CheckboxComponent = ({ row, checkedRows, handleSelectChange }) => {
  return (
    <td className=" xl:w-[3%] 
    ">
    <input
      type="checkbox"
      id={`checkbox-${row.query_id}`}
      className="peer hidden"
      checked={checkedRows.includes(row.query_id)}
      onChange={(e) => {
        e.stopPropagation();
        handleSelectChange(e, row.query_id);
      }}
    />
    <label
      htmlFor={`checkbox-${row.query_id}`}
      className="flex items-center justify-center
      w-4 h-4 ml-5 border-2 border-gray-300 
      rounded bg-white peer-checked:bg-mainColor peer-checked:border-mainColor"
    >
             <FaCheck className="   text-white" />

    </label>
  </td>
  );
};

export default CheckboxComponent;
