import React from 'react';
import RankRange from '../../Range/RankRange';

const RankRangeComponent = ({ tableData }) => (
  <div className='w-full transition duration-500 transform
  border-2 border-gray-200 dark:border-gray-500 rounded-lg '>
    <RankRange queries={tableData} />
  </div>
);

export default RankRangeComponent;
