import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { registrationTokenSchema } from "../../utils/validation";


const RegistrationToken = () => {
  const navigate = useNavigate();
  const [csrfToken, setCsrfToken] = useState('');

  // Initialize react-hook-form with validation schema
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(registrationTokenSchema),
  });

  // Fetch the CSRF token from the meta tag on initial render
  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    setCsrfToken(token);
  }, []);

  const { token } = useParams(); // Get the token from the URL parameters

  // Handle form submission and registration logic
  const onSubmit = async (data) => {
    try {
      const registrationData = {
        username: data.username,
        password: data.password,
        password2: data.password2,
      };

      // Include the token in the endpoint URL
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/registerToken/${token}/`,
        registrationData,
        {
          headers: {
            "Content-Type": "application/json",
            // 'CSRF-Token': csrfToken, // Uncomment if CSRF token is required
          },
        }
      );

      toast.success("Registration successful");
      navigate("/login"); // Navigate to login page after successful registration

    } catch (error) {
      toast.error("Registration failed");
      console.error("Error details:", error.response ? error.response.data : error.message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="2xl:max-w-2xl md:max-w-lg w-full mx-auto shadow-custom bg-white
      flex flex-col p-[60px] rounded-lg mt-[80px]"
    >
      <h2 className="text-center text-mainColor font-extrabold text-3xl tracking-[0.3rem]
      hover:translate-x-8 duration-300 ease-in-out mb-3">RankTracker</h2>
      <h3 className="mt-3 text-lg font-bold text-mainColor lg:text-3xl md:text-2xl">Create an Account</h3>
      <p className="mt-4 mb-3 text-md">Fill in the details below to create your account.</p>
      
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Name:
        </label>
        <input
          type="text"
          placeholder="Enter your name"
          {...register("username")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.username && (
          <p className="mt-1 text-xs text-red-500">{errors.username.message}</p>
        )}
      </div>

      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Password:
        </label>
        <input
          placeholder="Enter your password"
          type="password"
          {...register("password")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.password && (
          <p className="mt-1 text-xs text-red-500">{errors.password.message}</p>
        )}
      </div>

      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Confirm Password:
        </label>
        <input
          placeholder="Re-enter your password"
          type="password"
          {...register("password2")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.password2 && (
          <p className="mt-1 text-xs text-red-500">{errors.password2.message}</p>
        )}
      </div>

      <button
        type="submit"
        className="px-4 py-2 mt-5 font-bold text-white rounded bg-mainColor"
      >
        Create Account
      </button>

      <div className="mb-4 text-center">
        <p className="mt-4 text-md">
          Already have an account?{" "}
          <span className="cursor-pointer text-mainColor">
            <Link to="/login"
           

            >Sign In</Link>
          </span>
        </p>
      </div>
    </form>
  );
};

export default RegistrationToken;
