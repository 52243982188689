// ManagePermissionsModal.js
import React from "react";
import { useSelector } from "react-redux";

const ManagePermissionsModal = ({
  showManagePopup,
  setShowManagePopup,
  permissions,
  handlePermissionChange,
  updatePermissions,
  handleDeleteClick,
  currentManageId,
}) => {
  const roles = useSelector((state) => state.roleSlice.roles);

  const modifiedPermissions = {
    ...permissions,
    KWRT: true,
    Add_single_query: true,
  };

  return showManagePopup ? (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setShowManagePopup(false);
        }
      }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
    >
      <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow">
        <h2 className="m-2 text-lg font-bold text-center">
          Manage Permissions
        </h2>
        <form className="space-y-4">
          <table className="w-full text-left border-collapse">
            <thead>
              <tr>
                <th className="p-2 border-b-2">Permission</th>
                <th className="p-2 text-center border-b-2">Allow</th>
                <th className="p-2 text-center border-b-2">Not Allow</th>
              </tr>
            </thead>
            <tbody>
              {permissions &&
                Object.entries(permissions).map(([key, value]) => (
                  <tr key={key}>
                    <td className="p-2 capitalize border-b">
                      {key.replace(/([A-Z])/g, " $1").trim()}
                    </td>
                    {/* <td className="p-2 text-center border-b">
                            <input
                              type="radio"
                              checked={permissions[key] === true}
                              onChange={() => handlePermissionChange(key, true)}
                              name={key}
                              value="true"
                              disabled={
                                key === "KWRT" || key === "Add_single_query"
                              } // Disabled for KWRT and Add_single_query
                            />
                          </td>
                          <td className="p-2 text-center border-b">
                            <input
                              type="radio"
                              checked={permissions[key] === false}
                              onChange={() =>
                                handlePermissionChange(key, true)
                              }
                              name={key}
                              value="false"
                              disabled={
                                key === "KWRT" || key === "Add_single_query"
                              } // Disabled for KWRT and Add_single_query
                            />
                            
                          </td> */}
                    <td className="p-2 text-center border-b">
                      <input
                        type="radio"
                        checked={modifiedPermissions[key] === true}
                        onChange={() => handlePermissionChange(key, true)}
                        name={key}
                        value="true"
                        disabled={key === "KWRT" || key === "Add_single_query"} // Disabled for KWRT and Add_single_query
                      />
                    </td>
                    <td className="p-2 text-center border-b">
                      <input
                        type="radio"
                        checked={modifiedPermissions[key] === false}
                        onChange={() => handlePermissionChange(key, false)}
                        name={key}
                        value="false"
                        disabled={key === "KWRT" || key === "Add_single_query"} // Disabled for KWRT and Add_single_query
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex justify-end mt-4 space-x-4">
            <button
              type="button"
              onClick={() => setShowManagePopup(false)}
              className="px-4 py-2 text-white bg-gray-600 rounded-lg hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={updatePermissions}
              className="px-4 py-2 text-white rounded-lg bg-mainColor hover:bg-mainColor"
            >
              Save Changes
            </button>
            <button
              type="button"
              className="px-4 py-2 text-white bg-red-700 rounded-lg "
              onClick={() => handleDeleteClick(currentManageId)}
            >
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

export default ManagePermissionsModal;
