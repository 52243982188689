// const customTableStylesLight = {
//     table: {
//       style: {
//         backgroundColor: "#fff",
//         color: "#000",
//       },
//     },
//     headRow: {
//       style: {
//         backgroundColor: "#f0f0f0",
//         color: "#000",
//       },
//     },
//     headCells: {
//       style: {
//         backgroundColor: "#fff",
  
//         color: "#000",
//       },
//     },
//     rows: {
//       style: {
//         backgroundColor: "#fff",
//         color: "#000",
//         "&:nth-of-type(odd)": {
//           backgroundColor: "#f0f0f0",
//         },
//       },
//     },
//     pagination: {
//       style: {
//         backgroundColor: "#fff",
//         color: "#000",
//       },
//     },
//   };
//   export default customTableStylesLight



