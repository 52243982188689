import { createSlice } from '@reduxjs/toolkit';

export const roleSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: {},

  },
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload;
    },

  },
});

export const { setRoles  } = roleSlice.actions;

export default roleSlice.reducer;
