import React from "react";
import DataTable from "react-data-table-component";
import customTableStylesLight from "../../customTableStyles";
import customTableStylesDark from "../../customTableStylesDark";

const isDarkMode = localStorage.getItem("darkMode") === "true";
const customTableStyles = isDarkMode ? customTableStylesDark : customTableStylesLight;

const DataTables = ({ title, columns, data, onSort }) => (
  <div className="w-full">
    <h2 className="text-lg font-bold text-darkblue dark:text-gray-300">
      {title}
    </h2>
    <div className="mt-4 overflow-x-auto border-2 border-gray-200 dark:border-gray-500 rounded-md">
      <DataTable
        customStyles={customTableStyles}
        className="overflow"
        columns={columns}
        data={data}
        pagination
        paginationPerPage={5}
        onSort={onSort}
        sortServer
        noDataComponent={
          <div className="w-full p-4 text-center text-gray-500 ">
            No data available
          </div>
        }
      />
    </div>
  </div>
);

export default DataTables;
