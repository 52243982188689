import React from 'react';
import DataTable from 'react-data-table-component';
import SkeletonTable from '../../../components/Skeleton/SkeletonTable';
import { extractActionDetails } from './extractActionDetails';

// Function to extract action and user details


const OtherActivityLog = ({ logs, isLoading }) => {
    const columns = [
        { 
            name: 'User', 
            selector: row => row.user, 
            sortable: true 
        },
        { 
            name: 'Created At', 
            selector: row => new Date(row.created_at).toLocaleString(), 
            sortable: true 
        },
        { 
            name: 'Action', 
            selector: row => row.action, 
            sortable: true 
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: '600',
                color: '#374151',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
        cells: {
            style: {
                fontSize: '14px',
                color: '#4B5563',
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
    };

    return (
        <div className='overflow-x-auto'>
            {isLoading ? (
                <SkeletonTable />
            ) : (
                logs.length > 0 ? (
                    <DataTable
                        columns={columns}
                        customStyles={customStyles}
                        data={logs}
                        pagination
                        responsive
                        highlightOnHover
                    />
                ) : (
                    <p className="text-center text-gray-500">No logs available.</p>
                )
            )}
        </div>
    );
};

export default OtherActivityLog;
