import React from 'react';
import DataTableComponent from '../../../../components/keyword_research/keyword/DataTableComponent';

const LinksTable = ({ linksData }) => {
    const extractDomain = (url) => {
        try {
            const domain = (new URL(url)).hostname;
            const domainName = domain.startsWith('www.') ? domain.slice(4) : domain;
            return domainName.toUpperCase(); // Capitalize all characters
        } catch (error) {
            return 'INVALID URL';
        }
    };
    const linksColumns = [
        { 
            name: 'Rank', 
        selector: row => row.rank, 
        sortable: true ,
        
     
       
    },
        {
            name: 'Title',
            selector: row => row.Title,
            sortable: true,
            cell: row => <a href={row.link} target="_blank" rel="noopener noreferrer" className="underline decoration-[#033dfc] text-[#033dfc] ">{row.Title}</a>,
            
        },
        {
            name: 'Domain',
            selector: row => extractDomain(row.link),
            sortable: true,
           
        }
    ];

    const processLinksData = (linksDataArray) => {
        const processedData = linksDataArray.slice(0, 5);
        while (processedData.length < 5) {
            processedData.push({ rank: 'N/A', Title: 'N/A', link: '#' });
        }
        return processedData;
    };

    const processedLinksDataArray = processLinksData(linksData);
   
    return (
        <div className="h-full rounded-md">
            <DataTableComponent
             title="Filtered Links" 
            columns={linksColumns} data={linksData}
             />
        </div>
    );
};

export default LinksTable;
