import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useDeleteOperations,
  useEditQuery,
  useFetchData,
  useFilters,
  usePagination,
} from "../hook";
import { selectAuthDetails, selectOriginalUserName } from "../redux/selectors";
import { PaginationControls, RankTable } from "../components";
import { Fragment, useEffect, useState } from "react";
import Table from "../components/MainRank/RankTable/Table";
import CheckboxComponent from "../components/MainRank/RankTable/CheckboxComponent";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import TabsProjects from "../components/Tabs/TabsProjects";

const SingleProject = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const { userId, managed, NameUser, verified } =
    useSelector(selectAuthDetails);
  const originalUser = useSelector(selectOriginalUserName);
  const roles = useSelector((state) => state.roleSlice.roles);
  const [favoritesData, setFavoritesData] = useState([]);

  const { loading, tableData, detailData, is_Loading } = useFetchData(
    userId,
    projectId
  );
  const [updatingRows, setUpdatingRows] = useState(new Set());
  const [rowsInBulkUpdate, setRowsInBulkUpdate] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showAddProgressBar, setShowAddProgressBar] = useState(false);

  const [currentRows, setCurrentRows] = useState([]);

  useEffect(() => {
    setCurrentRows(tableData);
  }, [tableData]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);

  //   const {
  //     filteredData,
  //     setFilteredData,
  //     setSelectedLocationFilter,
  //     searchQuery,
  //     handleSearchInputChange,
  //     selectedLocationFilter,
  //     handleLocationChange,
  //     selectedTargetUrl,
  //     handleTargetUrlFilterChange,
  //     rankRange,
  //     handleRangeChange,
  //     hasFiltersApplied,
  //     handleResetFilters,
  //   } = useFilters(tableData, detailData, loading);
  const [checkedRows, setCheckedRows] = useState([]);
  //   const {
  //     currentPage,
  //     currentRows,
  //     totalPages,
  //     paginate,
  //     setRowsPerPage,
  //     setCurrentPage,
  //     setCurrentRows,
  //     rowsPerPage,
  //   } = usePagination(filteredData, 10);
  const {
    isDialogOpen,
    isBulkDeleteDialogOpen,
    handleDeleteButtonClick,
    confirmDeletion,
    cancelDeletion,
    handleBulkDelete,
    handleDeleteConfirmed,
    handleCancelDeletion,
    // checkedRows,
    // setCheckedRows,
    isDeleting,
  } = useDeleteOperations(
    userId,
    projectId,
    setFilteredData
    // setTotalDeletes,
    // setCompleteDeletes,
    // setIsDelete
  );
  const {
    editingQueryId,
    tempQueryName,
    isEditInProgress,
    editProgress,
    handleEditClick,
    handleQueryNameChange,
    submitNewQueryName,
    setEditingQueryId,
    editError,
    setEditProgress,
  } = useEditQuery(userId, projectId);
  const projectDetails = location.state?.projectDetails;
  const handleSingleUpdateButtonClick = () => {};
  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const allRowIds = currentRows.map((row) => row?.query_id);
      setCheckedRows(allRowIds);
      setSelectedRows(currentRows);
    } else {
      setCheckedRows([]);
      setSelectedRows([]);
    }
  };
  const [copiedRows, setCopiedRows] = useState(new Set());

  const isAllSelected = () => {
    console.log(checkedRows.length, currentRows.length, "gngfnfgn");

    return currentRows.length > 0 && checkedRows.length === currentRows.length;
  };
  const handleSelectChange = (e, query_id) => {
    if (e.target.checked) {
      const selectedRow = currentRows.find((row) => row.query_id === query_id);
      setSelectedRows((prev) => [...prev, selectedRow]);
      setCheckedRows((prev) => [...prev, query_id]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row.query_id !== query_id)
      );
      setCheckedRows((prev) => prev.filter((id) => id !== query_id));
    }
  };
  const [expandedRowId, setExpandedRowId] = useState(null);

  const handleToggleRow = (rowId, event) => {
    event.stopPropagation();
    setExpandedRowId((currentExpandedRowId) =>
      currentExpandedRowId !== rowId ? rowId : null
    );
  };

  return (
    <div className="App my-16">
      <table className="divide-y divide-gray-200 dark:divide-gray-500 w-[100%] ">
        <thead className="bg-gray-50 dark:bg-darkbg sticky -top-1 z-10">
          <tr>
            <th
              className="  text-xs font-medium
          leading-4 tracking-wider text-gray-500 dark:text-gray-300 uppercase"
            >
              <input
                type="checkbox"
                onChange={handleSelectAllClick}
                checked={isAllSelected()}
              />
              {/* <CheckHead
                checkedRows={checkedRows}
                handleSelectAllClick={handleSelectAllClick}
                isSelectAll={true}
                isAllSelected={isAllSelected}
              /> */}
            </th>
            <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 dark:text-gray-300 uppercase">
              Details
            </th>
            <th className="w-[20%] text-xs text-left md:text-center md:w-[10%] font-medium text-gray-500 dark:text-gray-300 uppercase cursor-pointer tracking-wider">
              <span className="inline">keywords </span>
            </th>
            <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer">
              <span className="inline">Rank </span>
            </th>
            <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer">
              <span className="inline">Best Rank </span>
            </th>
            <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer">
              <span className="inline">Date</span>
            </th>
            <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer">
              <span className="inline">Origin </span>
            </th>
            <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 bg-white dark:bg-darkbg uppercase sticky-right-head"></th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-500 max-h-[500px] overflow-scroll">
          {currentRows.length > 0 ? (
            currentRows?.map((row, index) => (
              <Fragment key={index}>
                <tr
                //   onClick={(event) => handleRowInteraction(row, event, index)}
                //       className={`${
                //         checkedRows.includes(row.query_id)
                //           ? "bg-[#f3f4f6] dark:bg-gray-950  "
                //           : "bg-white dark:bg-darkbg "
                //       } hover:bg-[#f3f4f6] cursor-pointer
                //      text-gray-950 dark:text-gray-300
                //    `}
                //   onMouseDown={(e) => handleRowClick(e, row.query_id)}
                >
                  <input
                    type="checkbox"
                    id={`checkbox-${row.query_id}`}
                    className=" "
                    checked={checkedRows.includes(row.query_id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleSelectChange(e, row.query_id);
                    }}
                  />
                  {/* <CheckboxComponent
                    row={row}
                    checkedRows={checkedRows}
                    handleSelectChange={handleSelectChange}
                  /> */}
                  <td className="px-6 py-4 whitespace-nowrap">
                    {/* {typeof row.query === "string" &&
                    typeof searchQuery === "string" &&
                    (searchQuery === "" || row.query.includes(searchQuery)) ? (
                      <button
                        onClick={(event) =>
                          handleToggleRow(row.query_id, event)
                        }
                        className={`focus:outline-none ${
                          updatingRows[row.query_id] &&
                          "cursor-not-allowed text-gray-400"
                        }`}
                        disabled={updatingRows[row.query_id]}
                      >
                        {expandedRowId === row.query_id ? (
                          <FaChevronUp
                            className="text-xl text-mainColor"
                            size={16}
                          />
                        ) : (
                          <FaChevronDown
                            className="text-xl text-mainColor"
                            size={16}
                          />
                        )}
                      </button>
                    ) : null} */}
                  </td>
                  {/* <td className="px-6 py-4 whitespace-nowrap">
                    {typeof row.query === "string" &&
                    typeof searchQuery === "string" &&
                    (searchQuery === "" || row.query.includes(searchQuery)) ? (
                      <button
                        // onClick={(event) =>
                        //   handleToggleRow(row.query_id, event)
                        // }
                        className={`focus:outline-none `}
                      ></button>
                    ) : null}
                  </td> */}
                  <td className="">
                    <div className="container mx-auto">
                      <div className="grid grid-cols-6 items-center cursor-pointer tooltipcontainer relative w-[300px]   ">
                        <span className="text-start col-span-4">
                          {row.new_query}
                        </span>
                        {searchQuery === "" ||
                        (row.new_query &&
                          row.new_query.includes(searchQuery)) ? (
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              setCopiedRows(
                                (prev) => new Set(prev.add(row.query_id))
                              );
                            }}
                            className="p-1 ml-3"
                            title="Copy to clipboard"
                          ></button>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td className="relative whitespace-nowrap">
                    {row.rank === 101 ? <p> - </p> : row.rank}
                    {row.rank !== 101 &&
                      row.rank_diff != null &&
                      row.rank_diff != 0 && (
                        <span
                          className={`absolute top-[5px] text-sm font-bold ${
                            row.rank_diff > 0
                              ? "text-green-500"
                              : "text-red-500"
                          }`}
                        >
                          {row.rank_diff > 0
                            ? `+${row.rank_diff}`
                            : row.rank_diff}
                        </span>
                      )}
                  </td>
                  <td className="whitespace-nowrap">
                    <div className="flex items-center justify-center">
                      <span className="flex items-center">
                        {row.best_rank === 101 ? (
                          <p>-</p>
                        ) : row.best_rank === 0 ? (
                          <p>-</p>
                        ) : (
                          <p>{row.best_rank}</p>
                        )}
                      </span>
                    </div>
                  </td>
                  <td className="whitespace-nowrap">
                    {new Date(row.date).toLocaleDateString()}
                  </td>
                  <td className="whitespace-nowrap relative td">
                    {row.temp ? (
                      <div className="text-center">Loading...</div>
                    ) : row.noDataIndicator ||
                      !row.source_url ||
                      typeof row.source_url !== "string" ? null : ( // No data or invalid URL
                      (() => {
                        const regex = /\/([^\/]*)\/?$/;
                        const matches = row.source_url.match(regex);
                        let lastSegment = "";
                        let fullURL = "";

                        try {
                          lastSegment =
                            matches &&
                            matches[1] &&
                            decodeURIComponent(matches[1].split("#")[0]);
                          fullURL = decodeURIComponent(row.source_url);
                        } catch (error) {
                          lastSegment =
                            matches && matches[1] && matches[1].split("#")[0];
                          fullURL = row.source_url;
                        }

                        if (row.source_url.includes("#")) {
                          const urlParts = row.source_url.split("/");
                          const partBeforeHash = urlParts[urlParts.length - 2];
                          lastSegment = decodeURIComponent(partBeforeHash);
                        }

                        return lastSegment ? (
                          <>
                            <a
                              href={row.source_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {lastSegment}
                            </a>
                            <div className="tooltipcontainer absolute top-0 right-0 pointer">
                              <span className="tooltiptext">{fullURL}</span>
                            </div>
                          </>
                        ) : (
                          <div> - </div>
                        );
                      })()
                    )}
                  </td>
                </tr>
                {console.log(expandedRowId === row.query_id, "gngfnfgn")}

                {/* {expandedRowId === row.query_id && (
                  <tr>
                    <td colSpan="100% " className="bg-white dark:bg-darkbg">
                        hvjvhjvj
                      {row && (
                        <TabsProjects
                          projectDetails={projectDetails}
                          rowData={row}
                          queryId={row.query_id}
                          projectId={projectId}
                        />
                      )}
                    </td>
                  </tr>
                )} */}
              </Fragment>
            ))
          ) : (
            <tr>
              <td
                colSpan="100%"
                className="px-6 py-4 text-center text-gray-500 dark:text-gray-300 whitespace-nowrap"
              >
                No data found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <PaginationControls
              showProgressBar={showProgressBar || showAddProgressBar}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              currentPage={currentPage}
              paginate={paginate}
              totalPages={totalPages}
              tableDataLength={tableData.length}
              filteredData={filteredData.length}
            /> */}
    </div>
  );
};
export default SingleProject;
