import React from 'react';

const SkeletonMainKeywordInfo = () => {
  return (
    <div className="animate-pulse col-span-2 flex flex-col md:flex-row space-x-4 items-center w-[100%]">
      <div className="md:h-8 mt-8 bg-[#e7e8ec] rounded w-[90%] h-6 md:w-[30%]"></div> 
      <div className="md:h-8 mt-8 bg-[#e7e8ec] rounded w-[90%] h-6 md:w-[30%]"></div> 
      <div className="md:h-8 mt-8 bg-[#e7e8ec] rounded w-[90%]  h-6 md:w-[30%]"></div> 
    </div>
  );
};

export default SkeletonMainKeywordInfo;
