

import { useEffect, useRef, useState } from 'react';

const useWebSocket = (url, setFilteredData) => {
  const socketRef = useRef(null);
  const [queryUpdated, setQueryUpdated] = useState(null);
  const [complate, setComplate] = useState(0);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    console.log('event');
    socketRef.current = new WebSocket(url);

    socketRef.current.onopen = () => {
      console.log('WebSocket connected');
    };

    socketRef.current.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socketRef.current.onmessage = (event) => {
      console.log(event,'onmessage');
      const data = JSON.parse(event.data);
      setQueryUpdated(data.query);
      setComplate((prevCount) => prevCount + 1);
      setMessages((prevMessages) => [...prevMessages, data]);

      // Example of updating filteredData if setFilteredData is a function
      if (typeof setFilteredData === 'function') {
        setFilteredData((currentData) => {
          const updatedItemIndex = currentData.findIndex((item) => item.query_id === data.query_id);
          if (updatedItemIndex !== -1) {
            const updatedItem = {
              ...currentData[updatedItemIndex],
              ...data.query,
            };
            return [
              ...currentData.slice(0, updatedItemIndex),
              updatedItem,
              ...currentData.slice(updatedItemIndex + 1),
            ];
          }
          return currentData;
        });
      }
    };

    // return () => {
    //   if (socketRef.current) {
    //     socketRef.current.close();
    //   }
    // };
  }, [url, setFilteredData]);

  const sendMessage = (message) => {
    if (socketRef.current) {
      socketRef.current.send(JSON.stringify(message));
    }
  };

  const sendUpdateQueries = (method, payload) => {
    if (socketRef.current) {
      socketRef.current.send(JSON.stringify({ method, payload }));
    }
  };

  return { sendMessage, sendUpdateQueries, queryUpdated, complate, messages };
};

export default useWebSocket;
