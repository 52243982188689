const processDateForExcel = (dateStr) => {
    const date = new Date(dateStr);
    const datePart = date.toISOString().slice(0, 10); // Extracts the date part (YYYY-MM-DD)
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours.toString().padStart(2, '0') : '12'; // the hour '0' should be '12'
    return {
      date: datePart,
      time: `${hours}:${minutes}:${seconds} ${amPm}` // Formats time part (hh:mm:ss AM/PM)
    };
  };
export default processDateForExcel;