import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RankFilter from "./RankFilter";
import LocationFilter from "./LocationFilter";
import ToggleFilter from "./ToggleFilter";
import BookmarkSelector from "./BookmarkSelector";
import customSelectStylesDark from "../../customSelectStylesDark";
import customSelectStylesLight from "../../customSelectStyles";
import { Link } from "react-router-dom";

const FilterComponent = ({
  selectedLocationFilter,
  setSelectedLocationFilter,
  rankRange,
  handleRangeChange,
  handleBookmarkChange,
  selectedBookmark,
  isFiltersVisible,
  toggleFilters,
  bookmarkSelectorRef,
  filteredBookmarks,
  handleAddToBookmark,
  bookmarkNames,
  setBookmarkNames,
  handleRenameBookmark,
  handleDeleteBookmark,
  userId,
  projectId,
  isCreatingBookmark,
  setIsCreatingBookmark,
  newBookmarkName,
  setNewBookmarkName,
  handleCreateBookmark,
  editingBookmarkId,
  setEditingBookmarkId,
  filteredData,
  tableData,
}) => {
  const [showBookmarkSelector, setShowBookmarkSelector] = useState(false);
  const [isBookmarkCreating, setIsBookmarkCreating] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const bookmarks = useSelector(
    (state) => state.displayBookmarkSlice.bookmarks
  );
  const bookmarkOptions = bookmarks.map((bookmark) => ({
    value: bookmark.b_id,
    label: bookmark.name,
  }));

  useEffect(() => {
    const isDarkMode = localStorage.getItem("darkMode") === "true";
    setIsDarkMode(isDarkMode);
  }, []);

  const customSelectStyles = isDarkMode
    ? customSelectStylesDark
    : customSelectStylesLight;

  const toggleBookmarkSelector = () => {
    setShowBookmarkSelector((prevShow) => !prevShow);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setIsBookmarkCreating(true);
      handleCreateBookmark().finally(() => {
        setIsBookmarkCreating(false);
      });
    }
  };

  return (
    <div
      className="flex flex-col w-full px-4 pb-4 xl:flex-row md:items-center xl:px-4 lg:px-4 md:pb-5 my-4
     "
    >
      {isFiltersVisible && (
        <ToggleFilter
          toggleFilters={toggleFilters}
          isFiltersVisible={isFiltersVisible}
        />
      )}
      <div className="w-full flex items-center justify-between gap-3 my-4">
        {tableData.length > 0 && (
          <Link
            to={`/${projectId}/competitors`}
            className=" flex items-center justify-center w-44 text-xs h-[40px] font-bold
          text-white transition duration-150 ease-in-out 
          rounded-lg bg-mainColor hover:bg-greeng lg:text-sm"
          >
            Your Competitors
          </Link>
        )}
        {!isFiltersVisible && (
          <ToggleFilter
            toggleFilters={toggleFilters}
            isFiltersVisible={isFiltersVisible}
          />
        )}

        <div
          className={`grid xl:grid-cols-3  h-fit
         lg:grid-cols-2 md:p-3 w-full gap-3
          ${isFiltersVisible ? "grid" : "hidden  lg:grid"}`}
        >
          <LocationFilter
            selectedLocationFilter={selectedLocationFilter}
            setSelectedLocationFilter={setSelectedLocationFilter}
            customSelectStyles={customSelectStyles}
            tableData={tableData}
            
          />
          <RankFilter
            rankRange={rankRange}
            handleRangeChange={handleRangeChange}
          />
          <BookmarkSelector
            bookmarkOptions={bookmarkOptions}
            selectedBookmark={selectedBookmark}
            handleBookmarkChange={handleBookmarkChange}
            showBookmarkSelector={showBookmarkSelector}
            toggleBookmarkSelector={toggleBookmarkSelector}
            filteredBookmarks={filteredBookmarks}
            handleAddToBookmark={handleAddToBookmark}
            bookmarkNames={bookmarkNames}
            setBookmarkNames={setBookmarkNames}
            handleRenameBookmark={handleRenameBookmark}
            handleDeleteBookmark={handleDeleteBookmark}
            userId={userId}
            projectId={projectId}
            isCreatingBookmark={isCreatingBookmark}
            setIsCreatingBookmark={setIsCreatingBookmark}
            newBookmarkName={newBookmarkName}
            setNewBookmarkName={setNewBookmarkName}
            handleCreateBookmark={handleCreateBookmark}
            editingBookmarkId={editingBookmarkId}
            setEditingBookmarkId={setEditingBookmarkId}
            isBookmarkCreating={isBookmarkCreating}
            bookmarkSelectorRef={bookmarkSelectorRef}
            handleKeyPress={handleKeyPress}
            customSelectStyles={customSelectStyles}
            setIsBookmarkCreating={setIsBookmarkCreating}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
