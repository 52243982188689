import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const addRank = createAsyncThunk(
  "ranks/addRank",
  async ({ keywordData }, { getState, rejectWithValue }) => {
    const state = getState();

    const userId = state.authSlice.id;
    const token = state.authSlice.token;

    // const csrfToken = csrfToken();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/add-single-rank/${userId}/`,
        keywordData,
        {
          headers: {
            // 'X-CSRFToken': csrfToken,
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      // Log the error if the API call fails
      console.error("Error adding keyword:", error.response || error.message);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const addrankSlice = createSlice({
  name: "ranks",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addRank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRank.fulfilled, (state, action) => {
        state.loading = false;
        state.data.push(action.payload);
      })
      .addCase(addRank.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "An error occurred";
      });
  },
});

export const { addKeywordSuccess } = addrankSlice.actions;

export default addrankSlice.reducer;
