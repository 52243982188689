// import { useState, useEffect } from "react";
// import axios from "axios";

// const useFetchChartData = (
//   userId,
//   projectId,
//   filteredData,
//   customDateChart,
//   startDate,
//   endDate
// ) => {
//   const [seriesData, setSeriesData] = useState([]);
//   const formatDate = (date) => {
//     const day = date?.getDate().toString().padStart(2, "0");
//     const month = (date?.getMonth() + 1).toString().padStart(2, "0");
//     const year = date?.getFullYear();
//     return `${year}-${month}-${day}`;
//   };
//   useEffect(() => {
//     const fetchAndProcessChartData = async () => {
//       const basicAPI = `${process.env.REACT_APP_API_URL}/api/AvgLastRankPerDayView/${userId}/${projectId}/`;
//       const customAPI = `${
//         process.env.REACT_APP_API_URL
//       }/api/AvgLastCustomView/${userId}/${projectId}/?start_date=${formatDate(
//         startDate
//       )}&end_date=${formatDate(endDate)}`;
//       const API = customDateChart ? customAPI : basicAPI;
//       if (!userId || !projectId) {
//         console.warn("userId or projectId is undefined");
//         return;
//       }

//       try {
//         const response = await axios.get(API);
//         const data = response.data.daily_averages || [];
//         const endDate = new Date();
//         const startDate = new Date();
//         startDate.setDate(endDate.getDate() - 5);

//         const processedData = data
//           .map((item) => ({
//             x: new Date(item.day),
//             y: Math.round(item.average_rank),
//           }));
//         setSeriesData([
//           {
//             name: `Project ${projectId} Average Rank`,
//             data: processedData,
//           },
//         ]);
//       } catch (error) {
//         console.error("Failed to fetch chart data:", error);
//         setSeriesData([
//           {
//             name: `Project ${projectId} Average Rank`,
//             data: [],
//           },
//         ]);
//       }
//     };

//     fetchAndProcessChartData();
//   }, [userId, projectId, filteredData, customDateChart]);
//   return seriesData;
// };

// export default useFetchChartData;

import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useFetchChartData = (
  userId,
  projectId,
  filteredData,
  customDateChart,
  startDate,
  endDate
) => {
  const [seriesData, setSeriesData] = useState([]);
  const [shouldFetch, setShouldFetch] = useState(false); // Add a flag to control fetching

  const formatDate = (date) => {
    const day = date?.getDate().toString().padStart(2, "0");
    const month = (date?.getMonth() + 1).toString().padStart(2, "0");
    const year = date?.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const fetchAndProcessChartData = useCallback(async () => {
    if (!userId || !projectId) {
      console.warn("userId or projectId is undefined");
      return;
    }

    const basicAPI = `${process.env.REACT_APP_API_URL}/api/AvgLastRankPerDayView/${userId}/${projectId}/`;
    const customAPI = `${
      process.env.REACT_APP_API_URL
    }/api/AvgLastCustomView/${userId}/${projectId}/?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`;
    const API = customDateChart ? customAPI : basicAPI;

    try {
      const response = await axios.get(API);
      const data = response.data.daily_averages || [];
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - 5);
      const processedData = data.map((item) => ({
        x: new Date(item.day),
        y: Math.round(item.average_rank),
      }));

      setSeriesData([
        {
          name: `Project ${projectId} Average Rank`,
          data: processedData,
        },
      ]);
    } catch (error) {
      console.error("Failed to fetch chart data:", error);
      setSeriesData([
        {
          name: `Project ${projectId} Average Rank`,
          data: [],
        },
      ]);
    }
  }, [userId, projectId, customDateChart, startDate, endDate]);

  useEffect(() => {
    // Trigger data fetch when shouldFetch changes to true
    if (shouldFetch) {
      fetchAndProcessChartData();
      setShouldFetch(false); // Reset fetch flag after fetching
    }
  }, [shouldFetch, fetchAndProcessChartData]);

  useEffect(() => {
    // Only set shouldFetch to true when relevant dependencies change
    setShouldFetch(true);
  }, [userId, projectId, filteredData, customDateChart, startDate, endDate]);

  return seriesData;
};

export default useFetchChartData;
