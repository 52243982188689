// selectStyles.js

const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      borderColor: '#e5e7eb', // Tailwind's border-gray-200
      borderWidth: '2px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'rgb(156 163 175)', // Tailwind's border-gray-400 for hover state
      },
      minHeight: '20px',
      display: 'flex',
      justifyContent: 'space-between',
    }),
  };
  
  export default customSelectStyles;
  