import React, { Fragment, memo, useEffect, useState } from "react";
import {
  FaArrowUp,
  FaArrowDown,
  FaChevronUp,
  FaChevronDown,
  FaTrash,
  FaEdit,
  FaCopy,
  FaRegCopy,
} from "react-icons/fa";
import { BiDotsVertical } from "react-icons/bi";
import { ImSpinner11 } from "react-icons/im";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Menu, Transition } from "@headlessui/react";
import TabsProjects from "../../Tabs/TabsProjects";
import { locationOptions } from "../../../location";
import { useCopyToClipboard, useSort } from "../../../hook";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-tailwind/react";
import CheckboxComponent from "./CheckboxComponent";
import CheckHead from "./CheckHead";

const Table = ({
  projectDetails,
  currentRows,
  setCurrentRows,
  setSelectedRows,
  selectedRows,
  checkedRows,
  setCheckedRows,
  updatingRows,
  handleUpdateButtonClick,
  userId,
  projectId,
  handleEditClick,
  tempQueryName,
  handleQueryNameChange,
  submitNewQueryName,
  handleDeleteButtonClick,
  roles,
  managed,
  editingQueryId,
  setEditingQueryId,
  isEditInProgress,
  rowsInBulkUpdate,
  filteredData,
  tableData,
  favoritesData,
  displayedRows,
  is_Updating,
  filteredByRank,
  setTableFilteredDate
}) => {
  const [copiedRows, setCopiedRows] = useState(new Set());
  const [activeDropdown, setActiveDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { sortConfig, requestSort, sortedData } = useSort(filteredData);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const { copyToClipboard } = useCopyToClipboard();
  console.log(projectDetails, "projectDetails");

  const toggleDropdown = (query_id) => {
    setActiveDropdown(activeDropdown === query_id ? null : query_id);
  };

  const handleSelectChange = (e, query_id) => {
    if (e.target.checked) {
      const selectedRow = currentRows.find((row) => row.query_id === query_id);
      setSelectedRows((prev) => [...prev, selectedRow]);
      setCheckedRows((prev) => [...prev, query_id]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((row) => row.query_id !== query_id)
      );
      setCheckedRows((prev) => prev.filter((id) => id !== query_id));
    }
  };

  const isAllSelected = () => {
    const selectAllData =
    filteredByRank.length > 0 && filteredByRank.length !== tableData.length
      ? filteredByRank
      : tableData;
    return (
      currentRows.length > 0 &&
      (checkedRows.length === currentRows.length ||
        (checkedRows.length === selectAllData.length &&
          checkedRows.length !== 0 &&
          selectAllData.length !== 0))
    );
  };


  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      const allRowIds = currentRows.map((row) => row.query_id);
      setCheckedRows(allRowIds);
      setSelectedRows(currentRows);
    } else {
      setCheckedRows([]);
      setSelectedRows([]);
    }
  };

  const handleRowInteraction = (row, event, rowIndex) => {
    const currentId = row.query_id;
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, rowIndex);
      const end = Math.max(lastSelectedIndex, rowIndex);
      const rowsInRange = filteredData.slice(start, end + 1);
      const rowIdsInRange = rowsInRange.map((r) => r.query_id);

      const isSelecting = !checkedRows.includes(currentId);

      let newCheckedRows;
      if (isSelecting) {
        newCheckedRows = [...new Set([...checkedRows, ...rowIdsInRange])];
      } else {
        newCheckedRows = checkedRows.filter(
          (id) => !rowIdsInRange.includes(id)
        );
      }
      setCheckedRows(newCheckedRows);

      let newSelectedRows;
      if (isSelecting) {
        newSelectedRows = [...new Set([...selectedRows, ...rowsInRange])];
      } else {
        newSelectedRows = selectedRows.filter(
          (selectedRow) => !rowIdsInRange.includes(selectedRow.query_id)
        );
      }
      setSelectedRows(newSelectedRows);
    } else if (event.ctrlKey || event.metaKey) {
      const isSelected = selectedRows.some((r) => r.query_id === currentId);
      const isChecked = checkedRows.includes(currentId);

      if (isSelected) {
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((r) => r.query_id !== currentId)
        );
      } else {
        setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
      }

      if (isChecked) {
        setCheckedRows((prevCheckedRows) =>
          prevCheckedRows.filter((id) => id !== currentId)
        );
      } else {
        setCheckedRows((prevCheckedRows) => [...prevCheckedRows, currentId]);
      }
    } else {
      setSelectedRows([row]);
    }
    setLastSelectedIndex(rowIndex);
  };

  const handleRowClick = (query_id) => {
    setExpandedRowId(expandedRowId === query_id ? null : query_id);
  };

  const handleToggleRow = (rowId, event) => {
    event.stopPropagation();
    setExpandedRowId((currentExpandedRowId) =>
      currentExpandedRowId !== rowId ? rowId : null
    );
  };
  useEffect(() => {
    if (filteredByRank.length > 0) {
      setTableFilteredDate(filteredByRank);
    }
  }, [filteredByRank]);
  console.log(filteredByRank,currentRows, "filteredByRankfilteredByRank");
  return (
    <table className="divide-y divide-gray-200 dark:divide-gray-500 w-[100%] ">
      <thead className="bg-gray-50 dark:bg-darkbg sticky -top-1 z-[100]">
        <tr>
          <th
            className="  text-xs font-medium
          leading-4 tracking-wider text-gray-500 dark:text-gray-300 uppercase flex items-center "
          >
            {/* <input
              type="checkbox"
              onChange={handleSelectAllClick}
              checked={isAllSelected()}
            /> */}
            <CheckHead
              checkedRows={checkedRows}
              handleSelectAllClick={handleSelectAllClick}
              isSelectAll={true}
              isAllSelected={isAllSelected}
            />
          </th>
          <th className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-gray-500 dark:text-gray-300 uppercase">
            Details
          </th>
          <th
            className="w-[20%] text-xs text-left md:text-center md:w-[10%] font-medium text-gray-500 dark:text-gray-300 uppercase cursor-pointer tracking-wider"
            onClick={() => {
              requestSort("query");
              setCurrentRows(sortedData);
            }}
          >
            <span className="inline">keywords </span>
            {sortConfig.key === "query" &&
              (sortConfig.direction === "ascending" ? (
                <FaArrowUp className="inline" />
              ) : (
                <FaArrowDown className="inline" />
              ))}
          </th>
          <th
            className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer"
            onClick={() => {
              requestSort("rank");
              setCurrentRows(sortedData);
            }}
          >
            <span className="inline">Rank </span>
            {sortConfig.key === "rank" &&
              (sortConfig.direction === "ascending" ? (
                <FaArrowUp className="inline" />
              ) : (
                <FaArrowDown className="inline" />
              ))}
          </th>
          <th
            className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer"
            onClick={() => {
              requestSort("best_rank");
              setCurrentRows(sortedData);
            }}
          >
            <span className="inline">Best Rank </span>
            {sortConfig.key === "best_rank" &&
              (sortConfig.direction === "ascending" ? (
                <FaArrowUp className="inline" />
              ) : (
                <FaArrowDown className="inline" />
              ))}
          </th>
          <th
            className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer"
            onClick={() => {
              requestSort("date");
              setCurrentRows(sortedData);
            }}
          >
            <span className="inline">Date</span>
            {sortConfig.key === "date" &&
              (sortConfig.direction === "ascending" ? (
                <FaArrowUp className="inline" />
              ) : (
                <FaArrowDown className="inline" />
              ))}
          </th>
          <th
            className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 uppercase cursor-pointer"
            onClick={() => {
              requestSort("source_url");
              setCurrentRows(sortedData);
            }}
          >
            <span className="inline">Origin </span>
            {sortConfig.key === "source_url" &&
              (sortConfig.direction === "ascending" ? (
                <FaArrowUp className="inline" />
              ) : (
                <FaArrowDown className="inline" />
              ))}
          </th>
          <th className="text-xs font-medium tracking-wider text-gray-500 dark:text-gray-300 bg-white dark:bg-darkbg uppercase sticky-right-head"></th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-500 max-h-[500px] overflow-scroll">
        {currentRows.length > 0 ? (
          currentRows.map((row, index) => (
            <Fragment key={row.query_id}>
              <tr
                onClick={(event) => handleRowInteraction(row, event, index)}
                className={`${
                  checkedRows.includes(row.query_id)
                    ? "bg-[#f3f4f6] dark:bg-gray-950  "
                    : "bg-white dark:bg-darkbg "
                } hover:bg-[#f3f4f6] cursor-pointer
                 text-gray-950 dark:text-gray-300
               `}
                onMouseDown={(e) => handleRowClick(e, row.query_id)}
              >
                <CheckboxComponent
                  row={row}
                  checkedRows={checkedRows}
                  handleSelectChange={handleSelectChange}
                />
                <td className="px-6 py-4 whitespace-nowrap xl:w-[3%] ">
                  {typeof row.query === "string" &&
                  typeof searchQuery === "string" &&
                  (searchQuery === "" || row.query.includes(searchQuery)) ? (
                    <button
                      onClick={(event) => handleToggleRow(row.query_id, event)}
                      className={`focus:outline-none ${
                        updatingRows[row.query_id] &&
                        "cursor-not-allowed text-gray-400"
                      }`}
                      disabled={updatingRows[row.query_id]}
                    >
                      {expandedRowId === row.query_id ? (
                        <FaChevronUp
                          className="text-xl text-mainColor"
                          size={16}
                        />
                      ) : (
                        <FaChevronDown
                          className="text-xl text-mainColor"
                          size={16}
                        />
                      )}
                    </button>
                  ) : null}
                </td>
                <td className="xl:w-[20%] ">
                  {editingQueryId === row.query_id ? (
                    <input
                      value={tempQueryName}
                      onChange={handleQueryNameChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") submitNewQueryName(row.query_id);
                        else if (e.key === "Escape") setEditingQueryId(null);
                      }}
                      className={`w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-mainColor-500 focus:border-mainColor-500 ${
                        isEditInProgress
                          ? "bg-gray-100 cursor-not-allowed"
                          : "bg-white"
                      }`}
                      autoFocus
                      disabled={isEditInProgress}
                    />
                  ) : (
                    <>
                      <div className="container mx-auto">
                        <div
                          className="grid grid-cols-6 items-center cursor-pointer tooltipcontainer relative w-[300px]   "
                          onDoubleClick={() =>
                            handleEditClick(row.query_id, row.query)
                          }
                        >
                          {locationOptions.find(
                            (option) => option.value === row.google_domain
                          )?.image && (
                            <img
                              className="rounded-[50%] w-[25px] h-[25px] mr-[10px] align-middle "
                              src={
                                locationOptions.find(
                                  (option) => option.value === row.google_domain
                                ).image
                              }
                              alt={row.google_domain}
                            />
                          )}
                          <span className="text-start col-span-4">
                            {row.new_query}
                          </span>
                          {searchQuery === "" ||
                          (row.new_query &&
                            row.new_query.includes(searchQuery)) ? (
                            <button
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                copyToClipboard(row.new_query);
                                setCopiedRows(
                                  (prev) => new Set(prev.add(row.query_id))
                                );
                              }}
                              className="p-1 ml-3"
                              title="Copy to clipboard"
                            >
                              {copiedRows.has(row.query_id) ? (
                                <FaCopy className="cursor-pointer" />
                              ) : (
                                <FaRegCopy className="cursor-pointer" />
                              )}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                </td>
                <td className="relative whitespace-nowrap xl:w-[10%] ">
                  {row.rank === 101 ? <p> - </p> : row.rank}
                  {row.rank !== 101 &&
                    row.rank_diff != null &&
                    row.rank_diff != 0 && (
                      <span
                        className={`absolute top-[5px] text-sm font-bold ${
                          row.rank_diff > 0 ? "text-green-500" : "text-red-500"
                        }`}
                      >
                        {row.rank_diff > 0
                          ? `+${row.rank_diff}`
                          : row.rank_diff}
                      </span>
                    )}
                </td>
                <td className="whitespace-nowrap xl:w-[10%] ">
                  <div className="flex items-center justify-center">
                    <span className="flex items-center">
                      {row.best_rank === 101 ? (
                        <p>-</p>
                      ) : row.best_rank === 0 ? (
                        <p>-</p>
                      ) : (
                        <p>{row.best_rank}</p>
                      )}
                    </span>
                  </div>
                </td>
                <td className="whitespace-nowrap xl:w-[10%] ">
                  {new Date(row.date).toLocaleDateString()}
                </td>
                <td className="whitespace-nowrap relative td xl:w-[20%] ">
                  {row.temp ? (
                    <div className="text-center">Loading...</div>
                  ) : row.noDataIndicator ||
                    !row.source_url ||
                    typeof row.source_url !== "string" ? null : ( // No data or invalid URL
                    (() => {
                      const regex = /\/([^\/]*)\/?$/;
                      const matches = row.source_url.match(regex);
                      let lastSegment = "";
                      let fullURL = "";

                      try {
                        lastSegment =
                          matches &&
                          matches[1] &&
                          decodeURIComponent(matches[1].split("#")[0]);
                        fullURL = decodeURIComponent(row.source_url);
                      } catch (error) {
                        lastSegment =
                          matches && matches[1] && matches[1].split("#")[0];
                        fullURL = row.source_url;
                      }

                      if (row.source_url.includes("#")) {
                        const urlParts = row.source_url.split("/");
                        const partBeforeHash = urlParts[urlParts.length - 2];
                        lastSegment = decodeURIComponent(partBeforeHash);
                      }

                      return lastSegment ? (
                        <>
                          <a
                            href={row.source_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {lastSegment}
                          </a>
                          <div className="tooltipcontainer absolute top-0 right-0 pointer">
                            <span className="tooltiptext">{fullURL}</span>
                          </div>
                        </>
                      ) : (
                        <div> - </div>
                      );
                    })()
                  )}
                </td>
                <td className="relative whitespace-nowrap sticky-right bg-white dark:bg-darkbg !z-[600] xl:w-[0%]">
                  <div className="flex mx-auto border-l-2 h-[100%]">
                    <Menu
                      as="div"
                      className="relative flex flex-col justify-center p-3 transition-all duration-300 ease-in-out tex"
                      onMouseDown={() =>
                        !row.temp &&
                        !updatingRows[row.query_id] &&
                        toggleDropdown(row.query_id)
                      }
                    >
                      <Menu.Button
                        disabled={
                          rowsInBulkUpdate ||
                          row.temp ||
                          updatingRows[row.query_id] ||
                          is_Updating
                        }
                      >
                        <BiDotsVertical
                          size={25}
                          className={`p-1 m-3 rounded-full cursor-pointer ${
                            row.temp ||
                            updatingRows[row.query_id] ||
                            rowsInBulkUpdate ||
                            is_Updating
                              ? "cursor-not-allowed text-gray-400"
                              : "hover:bg-gray-300"
                          }`}
                        />
                      </Menu.Button>
                      {!row.temp && !updatingRows[row.query_id] && (
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute top-[-55px] overflow-auto h-auto z-[1500] p-2 right-[45px] w-[110px] bg-white rounded-lg shadowmenu">
                            <Menu.Item>
                              {({ active }) => (
                                <div className="py-1">
                                  <button
                                    onClick={(e) =>
                                      handleUpdateButtonClick(
                                        e,
                                        userId,
                                        row.query_id,
                                        row.project
                                      )
                                    }
                                    className={`rounded-lg flex items-center ${
                                      updatingRows[row.query_id] || row.temp
                                        ? "cursor-not-allowed text-gray-400"
                                        : ""
                                    }`}
                                    disabled={
                                      updatingRows[row.query_id] || row.temp
                                    }
                                  >
                                    {updatingRows[row.query_id] ? (
                                      <ClipLoader
                                        size={12}
                                        color={"green"}
                                        loading={true}
                                      />
                                    ) : (
                                      <ImSpinner11 className="mr-2 hover:text-mainColor-600" />
                                    )}
                                    <span className="ml-[5px] text-sm">
                                      Update
                                    </span>
                                  </button>
                                </div>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <div className="py-1">
                                  {(!managed ||
                                    (managed && roles.Editing_queries)) && (
                                    <button
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleEditClick(
                                          row.query_id,
                                          row.query
                                        );
                                      }}
                                      className={`flex items-center justify-around rounded-lg ${
                                        row.temp &&
                                        "cursor-not-allowed text-gray-400"
                                      }`}
                                      disabled={row.temp}
                                    >
                                      <FaEdit className="mr-2 hover:text-mainColor-600" />
                                      <span className="ml-[5px] text-sm">
                                        Edit
                                      </span>
                                    </button>
                                  )}
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <div className="py-1">
                                  {(!managed ||
                                    (managed && roles.Delete_single_query)) && (
                                    <button
                                      onClick={() =>
                                        handleDeleteButtonClick(row.query_id)
                                      }
                                      className={`flex items-center justify-around rounded-lg ${
                                        row.temp &&
                                        "cursor-not-allowed text-gray-400"
                                      }`}
                                      disabled={row.temp}
                                    >
                                      <FaTrash className="mr-2 hover:text-mainColor-600" />
                                      <span className="ml-[5px] text-sm">
                                        Delete
                                      </span>
                                    </button>
                                  )}
                                </div>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      )}
                    </Menu>
                  </div>
                </td>
              </tr>
              {expandedRowId === row.query_id && (
                <tr>
                  <td colSpan="100% " className="bg-white dark:bg-darkbg">
                    {row && (
                      <TabsProjects
                        projectDetails={projectDetails}
                        rowData={row}
                        queryId={row.query_id}
                        projectId={projectId}
                      />
                    )}
                  </td>
                </tr>
              )}
            </Fragment>
          ))
        ) : (
          <tr>
            <td
              colSpan="100%"
              className="px-6 py-4 text-center text-gray-500 dark:text-gray-300 whitespace-nowrap"
            >
              No data found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default memo(Table);
