import { useState } from "react";
import { toast } from "react-toastify";
import { process_Keyword } from "../redux/lib/keywordSlice";
import { fetchUrlData } from "../redux/lib/urlSearchTest";
import { useLocation, useNavigate } from "react-router-dom";
// import { decrementDailyQuota } from "../redux/lib/auth";

const useKeywordAndUrlProcessing = (dispatch, userId) => {
  const [isKeywordLoading, setIsKeywordLoading] = useState(false);
  const [isUrlLoading, setIsUrlLoading] = useState(false);
  const [keywordData, setKeywordData] = useState(null);
  const [urlData, setUrlData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const handleKeywordSubmit = async (query) => {
    setIsKeywordLoading(true);
    try {
      const response = await dispatch(
        process_Keyword({ query, userId })
      ).unwrap();
      setKeywordData(response);
      setUrlData(null);

      if (
        location.pathname.includes("detailskeyword") ||
        location.pathname.includes("keywordresearch") ||
        location.pathname.includes("urlData")
      ) {
        navigate(`/detailskeyword/${response.Query}`, { replace: true });
        // dispatch(decrementDailyQuota(1));
      }
    } catch (error) {
      // toast.error("Please add a valid keyword");
    } finally {
      setIsKeywordLoading(false);
    }
  };

  const handleURLSubmit = async (url) => {
    setIsUrlLoading(true);
    try {
      const response = await dispatch(fetchUrlData({ url, userId })).unwrap();
      console.log(response, "urlData");

      setUrlData(response);

      setKeywordData(null);
      if (
        location.pathname.includes("urlData") ||
        location.pathname.includes("detailskeyword") ||
        location.pathname.includes("keywordresearch")
      ) {
        navigate(`/urlData/${response.object_id}`, {
          state: { data: response, type: "url" },
        });
      }

      toast.success("URL processed successfully");
      // dispatch(decrementDailyQuota(1));
    } catch (error) {
      toast.error("Please add a valid URL");
    } finally {
      setIsUrlLoading(false);
    }
  };

  const handleSubmit = (input) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    console.log(urlPattern, urlPattern.test(input), "urlData");

    const isURL = urlPattern.test(input);

    if (isURL) {
      console.log("dvdv");

      handleURLSubmit(input);
      navigate(`${location.pathname}#details`); // Navigate after successful keyword processing
    } else {
      handleKeywordSubmit(input);
      //   navigate(`${location.pathname}#details`); // Navigate after successful keyword processing
    }
  };
  console.log(urlData, "urlData");

  return {
    handleSubmit,
    isKeywordLoading,
    isUrlLoading,
    keywordData,
    urlData,
  };
};

export default useKeywordAndUrlProcessing;
