// selectStyles.js

const customSelectStylesDark = {

  control: (provided) => ({
    ...provided,
    backgroundColor: "#111417",
    color: "#d1d5db",
    borderColor: "#6b7280",
    borderWidth: "2px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(156 163 175)", // Tailwind's border-gray-400 for hover state
    },
    minHeight: "20px",
    display: "flex",
    justifyContent: "space-between",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#111417",
    color: "#d1d5db",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#d1d5db",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#555" : "#333",
    color: state.isSelected ? "#d1d5db" : "#ddd",
  }),
};

export default customSelectStylesDark;
