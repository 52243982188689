import React from "react";
import Select, { components } from "react-select";
import { locationOptions } from "../../../location";

const LocationFilter = ({
  selectedLocationFilter,
  setSelectedLocationFilter,
  customSelectStyles,
  tableData
}) => {
  const { Option } = components;

  // Create a unique set of google_domain entries
  const uniqueDomains = Array.from(new Set(tableData.map(data => data.google_domain)));

  // Filter locationOptions to include only those with google_domain entries
  const filteredLocationOptions = locationOptions.filter(option =>
    uniqueDomains.includes(option.value)
  );

  const LocationOption = (props) => (
    <Option {...props} className="z-[200]">
      <div className="flex items-center">
        {props.data.image && (
          <img
            src={props.data.image}
            alt=""
            style={{ width: 20, marginRight: 10 }}
          />
        )}
        <span>{props.data.label}</span>
      </div>
    </Option>
  );

  const handleChange = (selectedOptions) => {
    setSelectedLocationFilter(selectedOptions || []);
  };

  return (
    <Select
      className="z-[988]"
      options={filteredLocationOptions}
      getOptionLabel={(option) => (
        <div className="flex items-center">
          {option.image && (
            <img
              src={option.image}
              alt={option.label}
              style={{ width: 20, marginRight: 10 }}
            />
          )}
          {option.label}
        </div>
      )}
      isMulti
      value={selectedLocationFilter}
      onChange={handleChange}
      components={{ Option: LocationOption }}
      styles={customSelectStyles}
    />
  );
};

export default LocationFilter;
