import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FaTimes } from 'react-icons/fa';

const QuotaBar = ({ show }) => {
  const [isVisible, setIsVisible] = useState(true);
  const remainingQuota = useSelector((state) => state.authSlice.dailyQuota);
  const marginLeftClass = show ? " ml-[3%]" : "pl-[10px]";
  if (!isVisible) return null;

  return (
    <div className={`fixed top-0 left-0 flex items-center justify-center 
      w-full bg-greeng h-10 ${marginLeftClass} text-white py-2 z-[1001]`}>
      <div className="flex-1 text-center">
        {`Remaining Quota: ${remainingQuota}  $`}
      </div>
     
    </div>
  );
};

export default QuotaBar;
