import { useEffect, useRef, useState } from "react";
import { BsStars } from "react-icons/bs";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { useSelector } from "react-redux";
import useTypingEffect from "../useTypingEffect";
import { useNavigate } from "react-router-dom";

const GenerateAIBtn = ({ Query }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const userId = useSelector((state) => state.authSlice.id);
  const [isVisible, setIsVisible] = useState(false);
  const [article, setArticle] = useState("...");
  const displayedText = useTypingEffect(article, 10); // Adjust speed as needed
const navigate=useNavigate();
  const contentRef = useRef();
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsVisible(false);
  };
  const handleExportToWord = () => {
    setIsLoadingExport(true);

    const content = contentRef.current.innerHTML;
    const docxContent = htmlDocx.asBlob(content);
    setTimeout(() => {
      saveAs(docxContent, `${Query}.docx`);
      setIsLoadingExport(false);
    }, 1000);
  };
  const handleGenerate = () => {
    // sendArt();
    navigate(`/generated-article/${Query}`); // Navigate to a new page with the Query as a route parameter

    // handleOpenModal();
  };

  useEffect(() => {
    setIsGenerated(false);
  }, [Query]);

  useEffect(() => {
    if (isModalOpen) {
      // Delay setting isVisible to true to allow the modal to render first
      setTimeout(() => setIsVisible(true), 50);
    } else {
      setIsVisible(false);
    }
  }, [isModalOpen]);
  // http://192.168.0.123:8000/keyword/process_text/1/
  const sendArt = async () => {
    setIsLoading(true);

    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/keyword/generate/${userId}/${Query}/`
      )
      .then((res) => {
        setIsLoading(false);
        const convertToHTML = Object.entries(res.data.Generated_paragraph)
          .map(([key, value], index) => {
            return `<div index=${index}><h2 class="text-lg font-bold">${key}</h2><p class="text-gray-800">${value}</p></div>`;
          })
          .join("");
        setIsGenerated(true);

        setArticle(convertToHTML);
      });
  };
  return (
    <>
      <button
        onClick={!isGenerated ? handleGenerate : handleOpenModal}
        type="button"
        className="flex items-center gap-2 ml-auto mb-5
       bg-gradient-to-r from-sky-500 to-sky-300
     text-white px-4 py-2 rounded-full shadow-lg 
      transform transition duration-200
      ease-in-out hover:scale-105"
      >
        {!isGenerated ? (
          <>
            {" "}
            <BsStars />
            Generate 
          </>
        ) : (
          "Open Generated Article"
        )}
      </button>
      {/*  absolute ${isVisible ? 'right-0' : '-right-96'} */}
      {isModalOpen && (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleCloseModal();
            }
          }}
          className={`pointer-events-auto fixed inset-0 z-[1200] grid h-screen w-screen
      items-center  md:p-8 p-3 md:pt-12 bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300 overflow-y-scroll overflow-x-hidden `}
        >
          <div
            className={`w-full max-w-2xl m-auto p-4 bg-white rounded-lg shadow-lg
           transition-transform duration-500 ease-in-out
          `}
            onClick={(event) => event.stopPropagation()}
          >
            {!isLoading && (
              <div
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: displayedText }}
              >
                {/* {article} */}
              </div>
            )}
            {isLoading && (
              <div class="flex gap-2">
                <div class="w-3 h-3 rounded-full animate-pulse bg-sky-600"></div>
                <div class="w-3 h-3 rounded-full animate-pulse bg-sky-600"></div>
                <div class="w-3 h-3 rounded-full animate-pulse bg-sky-600"></div>
              </div>
            )}
            <div className="flex items-center justify-end gap-3">
              {!isLoading && (
                <button
                  onClick={handleExportToWord}
                  className="rounded-lg bg-mainColor text-white px-3 py-2  transform transition duration-200
      ease-in-out hover:scale-105"
                >
                  {isLoadingExport ? (
                    <FaSpinner className="animate-spin" />
                  ) : (
                    "Export to Word"
                  )}
                </button>
              )}

              <button
                onClick={handleCloseModal}
                className="rounded-lg bg-gray-300 px-3 py-2 text-gray-800"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
     
    </>
  );
};
export default GenerateAIBtn;
