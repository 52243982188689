import axios from "axios";
import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { logOut } from "../../redux/lib/auth";
import ConfirmationDialog from "../../components/Modal/ConfirmingDialog";
import DataTable from "react-data-table-component";
import InvitationsTable from "../../components/Invitation/InvitationsTable";
import ManagePermissionsModal from "../../components/Modal/ManagePermissionsModal";
import InviteUserModal from "../../components/Modal/InviteUserModal";
import ChangePasswordModal from "../../components/Modal/ChangePasswordModal";
import customTableStylesLight from "../../components/customTableStyles";
import customTableStylesDark from "../../components/customTableStylesDark";
import {
  selectAuthDetails,
  selectOriginalDetails,
} from "../../redux/selectors";
import ChangeInviteType from "../../components/Modal/ChangeInviteType";

const AccountDetails = () => {
  // Memoized selectors
  const NameUser = useSelector((state) => state.authSlice.user?.name);
  const User = useSelector((state) => state.authSlice.user);

  const EmailUser = useSelector((state) => state.authSlice.user.email);

  // states
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmDeletePending, setShowConfirmDeletePending] =
    useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);
  const [invitationError, setInvitationError] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [permissions, setPermissions] = useState({});
  const [isHovered, setIsHovered] = useState(true);
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password: "",
  });
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showInviteChange, setShowInviteChange] = useState(false);
  const [inviteUserID, setInviteUserID] = useState();

  const [invitationDetails, setInvitationDetails] = useState({
    mail: "",
    role_id: "2",
  });
  const [changeUserRole, setChangeUserRole] = useState();
  const [invitations, setInvitations] = useState([]);
  const [fetchError, setFetchError] = useState("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteConfirmationPending, setShowDeleteConfirmationPending] =
    useState(false);

  const [showManagePopup, setShowManagePopup] = useState(false);
  const [currentManageId, setCurrentManageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  // hooks
  const dispatch = useDispatch();
  const { userId, managed, token, verified } = useSelector(selectAuthDetails);
  console.log(managed, "managed");
  const originalDetails = useSelector(selectOriginalDetails);
  const notManage = verified && !managed;

  // functions
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handlePasswordChange = useCallback(async () => {
    setLoadingChangePassword(true);

    const updateData = {
      old_password: passwords.old_password,
      new_password: passwords.new_password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/change-password/${userId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updateData),
        }
      );

      if (response.ok) {
        toast.success("Password changed successfully");
        setShowChangePassword(false);
        setPasswords({ old_password: "", new_password: "" });
      } else {
        const errorData = await response.json();
        toast.error(`Failed to change password: ${errorData.message}`);
      }
    } catch (error) {
      toast.error(`Failed to change password: ${error.toString()}`);
    } finally {
      setLoadingChangePassword(false);
    }
  }, [passwords, token, userId]);

  const handleAccountDeletion = useCallback(() => {
    setShowConfirmDelete(true);
  }, []);

  const confirmDeleteAccount = useCallback(async () => {
    setLoadingDeleteAccount(true);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete_user/${userId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 204) {
        toast.success("Account deleted successfully");
        dispatch(logOut());
        window.location.href = "/login";
      }
    } catch (error) {
      toast.error(`Failed to delete account: ${error.toString()}`);
    } finally {
      setLoadingDeleteAccount(false);
      setShowConfirmDelete(false);
    }
  }, [dispatch, token, userId]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setPasswords((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleInviteInputChange = useCallback((event) => {
    const { name, value } = event.target;
    if (name === "mail") {
      const isValid = validateEmail(value);
      setIsEmailValid(isValid);
    }

    setInvitationDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  function validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const openInviteUserPopup = useCallback(() => {
    setShowInviteUser(true);
  }, []);

  const sendInvitation = async () => {
    setInvitationLoading(true);
    setInvitationError("");

    // if (!inviteUserID) {

    try {
      const payload = {
        mail: invitationDetails.mail,
        role_id: invitationDetails.role_id,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/invite/${userId}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      toast.success("Invitation sent successfully");
      setInvitationDetails({ mail: "", role_id: "" });
      fetchInvitationsPending();

      setShowInviteUser(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.mail &&
        error.response.data.mail.includes(
          "invitation with this mail already exists."
        )
      ) {
        toast.info(error.response.data.mail);
        setShowInviteUser(false);
      } else {
        setInvitationError("Failed to send invitation");
        toast.error("Failed to send invitation");
      }
    } finally {
      setInvitationLoading(false);
    }
    // }
    //  else if (inviteUserID) {
    //

    //   try {
    //     const payload = {
    //       new_role_id: changeUserRole,
    //     };
    //

    //     await axios.put(
    //       `${process.env.REACT_APP_API_URL}/api/UpdateUserRole/${userId}/${inviteUserID}/`,
    //       payload,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //           "Content-Type": "application/json",
    //         },
    //         withCredentials: true,
    //       }
    //     );

    //     toast.success("Invitation changes successfully");
    //     setShowInviteChange(false);
    //     // setInvitationLoading(false);
    //   } catch (error) {
    //
    //   } finally {
    //     setInvitationLoading(false);
    //   }
    // }
  };
  const sendInvitadingEditing = async () => {
    try {
      const payload = {
        new_role_id: changeUserRole,
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/UpdateUserRole/${userId}/${inviteUserID}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      toast.success("Invitation changes successfully");
      setShowInviteChange(false);

      // setInvitationLoading(false);
    } catch (error) {
    } finally {
      setInvitationLoading(false);
      fetchInvitationsPending();
    }
  };

  const handleRoleChange = (selectedOption) => {
    // if (!inviteUserID) {
    //

    setInvitationDetails((prevState) => ({
      ...prevState,
      role_id: selectedOption.value,
    }));
    // } else if (inviteUserID) {
    //

    //   setChangeUserRole(selectedOption.value);
    // }
  };
  const handleRoleChangePending = (selectedOption) => {
    setChangeUserRole(selectedOption.value);
  };

  const handleDeleteClick = (id) => {
    setCurrentManageId(id);
    setShowDeleteConfirmation(true);
  };
  const handleDeleteClickPending = (id) => {
    setShowDeleteConfirmationPending(true);
  };
  const fetchInvitations = useCallback(async () => {
    setInvitations([]);
    try {
      const currentUserId = managed ? originalDetails.id : userId;
      const api = `${process.env.REACT_APP_API_URL}/api/usemange/${currentUserId}/`;
      const response = await axios.get(api);
      if (response.status === 200 && response.data.length > 0) {
        const currentUserPermissions = response.data.find(
          (invite) => invite?.invitee === userId || invite?.creator === userId
        );

        if (currentUserPermissions) {
          const relevantPermissions = {
            Add_new_project: currentUserPermissions?.Add_new_project,
            Delete_project: currentUserPermissions?.Delete_project,
            Add_bulk_query: currentUserPermissions?.Add_bulk_query,
            Editing_queries: currentUserPermissions?.Editing_queries,
            Delete_single_query: currentUserPermissions?.Delete_single_query,
            Audit_log: currentUserPermissions?.Audit_log,
            KWRT: currentUserPermissions?.KWRT,
            Add_single_query: currentUserPermissions?.Add_single_query,
          };
          setPermissions(relevantPermissions);
        } else {
          throw new Error("No matching user data found");
        }
        setInvitations(response.data);
        // fetchInvitationsPending();
      } else {
        throw new Error("No data or failed to fetch permissions");
      }
    } catch (error) {
      setFetchError("Failed to load invitations");
    }
  }, [userId, managed, originalDetails.id]);

  const fetchInvitationsPending = useCallback(async () => {
    try {
      const currentUserId = managed ? originalDetails.id : userId;
      const pending = `${process.env.REACT_APP_API_URL}/api/Userinvi/${currentUserId}/`;

      const pendingResponse = await axios.get(pending);
      if (pendingResponse.status === 200) {
        setPending(pendingResponse.data.filter((data) => data.used !== true));
      } else {
        throw new Error("Failed to fetch pending invitations");
      }
    } catch (error) {
      console.error("Failed to fetch invitations:", error);
      setFetchError("Failed to load invitations");
    }
  }, [userId, managed, originalDetails.id]);

  const handleDeleteInvitation = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/delete-invitation/${userId}/${currentManageId}/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setInvitations((prevInvitations) =>
        prevInvitations.filter((invite) => invite.id !== currentManageId)
      );
      toast.success("Invitation deleted successfully");
      setShowDeleteConfirmation(false);
      setShowManagePopup(false);
    } catch (error) {
      console.error("Delete invitation error:", error);
      toast.error("Failed to delete invitation");
    }
    // }
    // else if (inviteUserID) {
    //   try {
    //     await axios
    //       .delete(
    //         `${process.env.REACT_APP_API_URL}/api/deleteinvi/${userId}/${inviteUserID}/`,
    //         {
    //           headers: { Authorization: `Bearer ${token}` },
    //         }
    //       )
    //       .then((res) => {
    //         setPending(pending.filter((invite) => invite.id !== inviteUserID));
    //         fetchInvitations();
    //         toast.success("Invitation deleted successfully");
    //         setShowDeleteConfirmation(false);
    //       })
    //       .catch((err) => {
    //         toast.error("Failed to delete invitation");
    //       });
    //   } catch (error) {
    //     console.error("Delete invitation error:", error);
    //     toast.error("Failed to delete invitation");
    //   }
    // }
  };
  const handleDeleteInvitationPending = async () => {
    try {
      await axios
        .delete(
          `${process.env.REACT_APP_API_URL}/api/deleteinvi/${userId}/${inviteUserID}/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          setPending(pending.filter((invite) => invite.id !== inviteUserID));
          fetchInvitations();
          toast.success("Invitation deleted successfully");
          setShowDeleteConfirmationPending(false);
          setShowInviteChange(false);
        })
        .catch((err) => {
          toast.error("Failed to delete invitation");
        });
    } catch (error) {
      console.error("Delete invitation error:", error);
      toast.error("Failed to delete invitation");
    }
  };

  const handleManageClick = (invitation) => {
    if (invitation.used === false && invitation.mail) {
      setShowInviteChange(true);
      setInviteUserID(invitation.id);
      setCurrentManageId(null);
      setChangeUserRole(invitation.role_id);
    } else {
      setCurrentManageId(invitation.id);
      // setInviteUserID(null);
      setPermissions({
        Add_new_project: invitation.Add_new_project,
        Delete_project: invitation.Delete_project,
        Add_bulk_query: invitation.Add_bulk_query,
        Editing_queries: invitation.Editing_queries,
        Delete_single_query: invitation.Delete_single_query,
        // user_invitaion: invitation.user_invitaion,
        Audit_log: invitation.Audit_log,
        KWRT: invitation.KWRT,
        Add_single_query: invitation.Add_single_query,
      });
      // setCurrentInviteeId(invitation.invitee_info.id);
      setShowManagePopup(true);
    }
  };

  const handlePermissionChange = useCallback((permissionName, value) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [permissionName]: value,
    }));
  }, []);

  const updatePermissions = async () => {
    const permissionsPayload = permissions;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/update-permissions/${userId}/${currentManageId}/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(permissionsPayload),
        }
      );

      const data = await response.json(); // Parse JSON response into Javascript object
      if (response.ok) {
        fetchInvitations();
        toast.success("Permissions updated successfully");
        setShowManagePopup(false);
      } else {
        throw new Error(
          `Failed to update permissions: ${data.message || "Server error"}`
        );
      }
    } catch (error) {
      console.error("Error updating permissions:", error);
      toast.error(`Failed to update permissions: ${error.message}`);
    }
  }

  const reSendVerified = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/verify_button/${userId}/`
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error("An error occurred during the email verification process.");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    setCsrfToken(token);
  }, []);

  useEffect(() => {
    fetchInvitations();
    fetchInvitationsPending();
  }, [fetchInvitations, fetchInvitationsPending]);

  const roleOptions = [
    { value: "2", label: "User" },
    { value: "3", label: "Guest" },
  ];

  const columns = useMemo(
    () => [
      {
        name: "Invitee Username",
        selector: (row) => row.invitee_info?.username || row.mail,
        sortable: true,
      },
      {
        name: "Role",
        selector: (row) => row.role_acc || row.used,
        sortable: true,
        format: (row) =>
          row.role_acc
            ? roleOptions.find((role) => role.value === row.role_acc)?.label ||
              "Unknown"
            : row.used === false && "pending",
      },
      {
        name: "Action",
        button: "true",
        cell: (row) => (
          <button
            className={`px-4 py-2 text-white rounded-lg 
               bg-mainColor hover:bg-mainColor-600
               focus:outline-none `}
            onClick={() => handleManageClick(row)}
          >
            Modify
          </button>
        ),
      },
    ],
    [handleManageClick]
  );

  const accountDetails = useMemo(
    () => [
      { title: "Full Name:", details: NameUser },
      { title: "Email:", details: EmailUser },
    ],
    [NameUser, EmailUser]
  );

  const Spinner = () => (
    <div className="w-4 h-4 mx-2 border-b-2 border-white rounded-full animate-spin"></div>
  );

  const isDarkMode = localStorage.getItem("darkMode") === "true";
  const customTableStyles = useMemo(
    () => (isDarkMode ? customTableStylesDark : customTableStylesLight),
    [isDarkMode]
  );

  return (
    <div className="pt-[6rem] rounded-lg relative flex justify-center p-4 ">
     <div className="w-[100%]">
     <h1 className="text-greeng  text-center  dark:text-gray-300  font-bold mt-3   text-lg 2xl:text-xl xl:text-lg md:text-sm">
     Account Details
        </h1>
        <div className="flex flex-col gap-4 mb-4 justify-start items-start ">
          {accountDetails.map((detail, index) => (
            <div
              key={index}
              className="grid grid-cols-2 items-start md:w-[30%] w-full "
            >
              <label
                htmlFor={detail.title}
                className="text-gray-600 dark:text-gray-300 font-bold text-sm md:text-base "
              >
                {detail.title}
              </label>
              <p
                id={detail.title}
                className="text-gray-800 dark:text-gray-400 text-sm md:text-base "
              >
                {detail.details}
              </p>
            </div>
          ))}
        </div>

        {!managed && (
          <>
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
              <button
                className="px-4 py-2 font-bold text-sm md:text-base text-white rounded-lg bg-mainColor hover:bg-mainColor-700"
                onClick={() => setShowChangePassword(true)}
              >
                Change Password
              </button>

              <button
                className="px-4 py-2 font-bold text-sm md:text-base text-white bg-red-600 rounded-lg hover:bg-red-700"
                onClick={handleAccountDeletion}
                disabled={loadingDeleteAccount}
              >
                {loadingDeleteAccount ? "Deleting..." : "Delete Account"}
              </button>

              <div className="relative tooltipcontainer">
                <button
                  className={`w-full text-sm md:text-base px-4 py-2 font-bold text-white rounded-lg ${
                    verified
                      ? "bg-gray-600 hover:bg-gray-700"
                      : "bg-gray-400 cursor-not-allowed"
                  }`}
                  onClick={verified ? openInviteUserPopup : null}
                >
                  Invite User
                </button>
                {!verified && (
                  <div className="absolute -top-12 left-0 transition-all duration-1000 ease-in-out w-max">
                    <span className="hidden md:block tooltiptext bg-gray-700 text-white p-2 rounded-lg shadow-lg">
                      Please verify your account through email
                      <span className="underline"> {EmailUser}</span>
                    </span>
                  </div>
                )}
              </div>
              {!verified && (
                <button
                  className="px-4 py-2 font-bold text-sm md:text-base text-white bg-mainColor rounded-lg hover:bg-mainColor-700"
                  onClick={reSendVerified}
                >
                  {loading ? (
                    <div className="flex items-center justify-center gap-1">
                      <Spinner />
                      Sending...{" "}
                    </div>
                  ) : (
                    "Verify Account"
                  )}
                </button>
              )}
            </div>
          </>
        )}

        <ChangePasswordModal
          showChangePassword={showChangePassword}
          setShowChangePassword={setShowChangePassword}
          passwords={passwords}
          handleInputChange={handleInputChange}
          toggleOldPasswordVisibility={toggleOldPasswordVisibility}
          toggleNewPasswordVisibility={toggleNewPasswordVisibility}
          showOldPassword={showOldPassword}
          showNewPassword={showNewPassword}
          handlePasswordChange={handlePasswordChange}
          loadingChangePassword={loadingChangePassword}
        />

        {showConfirmDelete && (
          <ConfirmationDialog
            isOpen={showConfirmDelete}
            onClose={() => setShowConfirmDelete(false)}
            onConfirm={confirmDeleteAccount}
            message="Are you sure you want to delete your account?"
            isDeleting={loadingDeleteAccount}
          />
        )}

        <InviteUserModal
          fetchInvitations={fetchInvitations}
          showInviteUser={showInviteUser}
          setShowInviteUser={setShowInviteUser}
          invitationDetails={invitationDetails}
          setInvitationDetails={setInvitationDetails}
          handleInviteInputChange={handleInviteInputChange}
          handleRoleChange={handleRoleChange}
          roleOptions={roleOptions}
          sendInvitation={sendInvitation}
          isEmailValid={isEmailValid}
          invitationError={invitationError}
          invitationLoading={invitationLoading}
        />
        <ChangeInviteType
          fetchInvitations={fetchInvitationsPending}
          showInviteUser={showInviteChange}
          setShowInviteUser={setShowInviteChange}
          inviteUserID={inviteUserID}
          changeUserRole={changeUserRole}
          setChangeUserRole={setChangeUserRole}
          handleRoleChange={handleRoleChangePending}
          roleOptions={roleOptions}
          sendInvitation={sendInvitadingEditing}
          handleDeleteClick={handleDeleteClickPending}
          invitationLoading={invitationLoading}
        />

        <ManagePermissionsModal
          showManagePopup={showManagePopup}
          setShowManagePopup={setShowManagePopup}
          permissions={permissions}
          handlePermissionChange={handlePermissionChange}
          updatePermissions={updatePermissions}
          handleDeleteClick={handleDeleteClick}
          currentManageId={currentManageId}
        />

        {showDeleteConfirmation && (
          <ConfirmationDialog
            isOpen={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            onConfirm={handleDeleteInvitation}
            message="Are you sure you want to delete this account ?"
            isDeleting={loadingDeleteAccount}
          />
        )}
        {showDeleteConfirmationPending && (
          <ConfirmationDialog
            isOpen={showDeleteConfirmationPending}
            onClose={() => setShowDeleteConfirmationPending(false)}
            onConfirm={handleDeleteInvitationPending}
            message="Are you sure you want to delete this account ?"
            isDeleting={loadingDeleteAccount}
          />
        )}
        <div className="p-0 text-left md:p-4 ">
          <div className="p-0 md:p-4">
            {!managed && (
              <div className="mt-6">
                <InvitationsTable />
                {(invitations.length > 0 || pending.length > 0) && (
                  <div>
                    <h2 className="mt-4 mb-3   text-lg  font-bold dark:text-gray-300">
                      Manage User Permissions
                    </h2>
                    <div className="overflow-x-auto">
                      <DataTable
                        columns={columns}
                        data={[...invitations, ...pending]}
                        defaultSortField="invitee_name"
                        pagination
                        highlightOnHover
                        responsive
                        customStyles={customTableStyles}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AccountDetails);
