import SkeletonLoader from "./SkeletonLoader";

const SkeletonRow = () => (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-3/4" /></td>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-5/6" /></td>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-full" /></td>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-3/4" /></td>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-5/6" /></td>
      <td className="px-6 py-4 whitespace-nowrap"><SkeletonLoader className="h-4 w-full" /></td>
    </tr>
  );

  export default SkeletonRow ;