import React from "react";

const SkeletonArticleContent = () => {
  return (
    <div className="animate-pulse w-full h-[500px] border-2 border-gray-200 rounded-2xl p-4">
      <div className="w-full h-8 bg-gray-200 rounded mb-4"></div>
      <div className="space-y-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <div key={index} className="w-full h-6 bg-gray-200 rounded"></div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonArticleContent;
