import React, { useState, useEffect } from 'react';
import DataTableComponent from '../../../../components/keyword_research/keyword/DataTableComponent';
import LinksTable from '../KeywordInfo/LinksTable';
import DataTables from '../../../../components/keyword_research/keyword/DataTables';
import SkeletonDataTable from '../../../../components/Skeleton/SkeletonDataTable';
import SkeletonInput from '../../../../components/Skeleton/SkeletonInput';

const Tables = ({
    linksData,
    aggregatedResults,
    aggregatedResultsColumns,
    combinedH2H3DataArray,
    combinedH2H3Columns,
    isKeywordLoading
}) => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [filteredResults, setFilteredResults] = useState(aggregatedResults);
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortedColumn, setSortedColumn] = useState(null); // Track sorted column

    // Update filtered results when search keyword changes
    useEffect(() => {
        if (searchKeyword) {
            const filtered = aggregatedResults.filter(item =>
                item.keyword.toLowerCase().includes(searchKeyword.toLowerCase())
            );
            setFilteredResults(filtered);
        } else {
            setFilteredResults(aggregatedResults); // Revert to original data if search is cleared
        }
    }, [searchKeyword, aggregatedResults]);

    // Handle sorting by count in the column
   // Handle sorting by count in the column with null checks
const handleSortByCountColumn = () => {
    const sorted = [...filteredResults].sort((a, b) => {
        const countA = a.count ?? 0; // Use 0 if count is null or undefined
        const countB = b.count ?? 0; // Use 0 if count is null or undefined

        if (sortDirection === 'asc') {
            return countA - countB;
        } else {
            return countB - countA;
        }
    });

    setFilteredResults(sorted);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
};


    // Columns with sortable Count column
    const updatedAggregatedResultsColumns = aggregatedResultsColumns.map((col) => {
        if (col.name === 'Count') {
            return {
                ...col,
                sortable: true,
                sortFunction: () => handleSortByCountColumn('count'),
            };
        }
        return col;
    });

    return (
        <>
            <div className="flex flex-col h-full mb-6 lg:flex-row">
                <div className="w-full h-full p-4">
                    {isKeywordLoading ? (
                        <SkeletonDataTable />
                    ) : (
                        <LinksTable linksData={linksData} />
                    )}
                </div>
            </div>

            <div className="flex flex-col lg:flex-row">
                <div className="w-full p-4 lg:w-1/2">
                    {isKeywordLoading ? (
                        <SkeletonDataTable />
                    ) : (
                        <>
                            {/* Flex container to align title and input field */}
                            {/* <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                                <div className="flex flex-row items-center w-full">
                                    <h2 className="text-xl font-semibold mb-2 sm:mb-0 mr-4">Keyword Data</h2>
                                    <div className="relative w-full sm:w-1/2 lg:w-2/5 flex items-start">
                                        <input
                                            type="text"
                                            value={searchKeyword}
                                            onChange={(e) => setSearchKeyword(e.target.value)}
                                            placeholder="Search for a keyword..."
                                            className="w-[100%] px-4 py-2 bg-white border-2
                                                border-[#e7e8ec] rounded-md text-sm focus:outline-none
                                                transition duration-300 ease-in-out"
                                        />
                                    </div>
                                </div>
                            </div> */}
<div className="flex sm:flex-row flex-col justify-between items-center mb-4">
    <h2 className="text-xl font-semibold mb-2 w-[40%] flex sm:items-center 
    mr-auto   justify-center ">Keyword Data</h2>
    <div className="relative w-full ml-4">
        <input
            type="text"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            placeholder="Search for a keyword..."
            className="w-full px-4 py-2 bg-white border-2
                border-[#e7e8ec] rounded-md text-sm focus:outline-none
                transition duration-300 ease-in-out"
        />
    </div>
</div>


                            {/* DataTables component or message when no matches are found */}
                            {filteredResults.length > 0 ? (
                                <DataTables
                                    columns={updatedAggregatedResultsColumns}
                                    data={filteredResults}
                                    defaultSortAsc={sortDirection === 'asc'}
                                    onSort={(column) => handleSortByCountColumn(column.selector)}
                                />
                            ) : (
                                <div className="text-center py-4 text-gray-600">
                                    No matching keywords found.
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="w-full p-4 lg:w-1/2">
                    {isKeywordLoading ? (
                        <SkeletonDataTable />
                    ) : (
                        combinedH2H3DataArray.length > 0 && (
                            <DataTableComponent
                                title="Sub Headings"
                                columns={combinedH2H3Columns}
                                data={combinedH2H3DataArray}
                                defaultSortField="count"
                                defaultSortAsc={sortDirection === 'asc'}
                            />
                        )
                    )}
                </div>
            </div>
        </>
    );
};

export default Tables;