const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '0.25rem',
      border: '1px solid #d1d5db', // This is the border color you have in your focus:border-gray-300 class
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#2563eb' : 'white',
      color: state.isSelected ? 'white' : 'black',
      padding: 10,
    }),
  };

  export default customStyles;
