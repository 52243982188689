import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projectId: null,
  projectName: null,
  links: [],
  projectUrl: null,
};

const projectCompetitors = createSlice({
  name: "projectCompetitors",
  initialState,
  reducers: {
    setProjectCompetitors: (state, action) => {
      const { projectId, projectName, links, projectUrl } = action.payload;
      state.projectId = projectId;
      state.projectName = projectName;
      state.links = links;
      state.projectUrl = projectUrl;
    },
    clearProjectCompetitors: (state) => {
      state.projectId = null;
      state.projectName = null;
      state.links = [];
      state.projectUrl = null;
    },
  },
});

export const { setProjectCompetitors, clearProjectCompetitors } =
  projectCompetitors.actions;
export default projectCompetitors.reducer;
