import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

const ArticleContent = ({ selectedKeyword, initialData }) => {
  const [textContent, setTextContent] = useState("");
  const [highlightedContent, setHighlightedContent] = useState("");
  const [textDirection, setTextDirection] = useState("ltr");
  // const initialData = useSelector((state) => state.UrlSlice.data);

  useEffect(() => {
    if (initialData && Array.isArray(initialData.content)) {
      const combinedText = initialData.content.join("\n");
      setTextContent(combinedText);
    } else {
      setTextContent(initialData?.content || "");
    }
  }, [initialData]);

  useEffect(() => {
    const arabicWordCount = (textContent.match(/[\u0600-\u06FF]+/g) || [])
      .length;
    const englishWordCount = (textContent.match(/[a-zA-Z]+/g) || []).length;
    setTextDirection(arabicWordCount > englishWordCount ? "rtl" : "ltr");
  }, [textContent]);

  const highlightKeywords = (text, keyword) => {
    if (!keyword) return text;
    let occurrence = 0;
    const regex = new RegExp(`(${keyword})`, "gi");
    return text.replace(regex, (match) => {
      occurrence += 1;
      return `<span className="highlight">${match}</span><span className="occurrence-number">(${occurrence})</span>`;
    });
  };

  useEffect(() => {
    if (!textContent) {
      return;
    }
    const highlightedText = highlightKeywords(textContent, selectedKeyword);
    const safeText = sanitizeHtml(highlightedText, {
      allowedTags: ["span"],
      allowedAttributes: { span: ["class"] },
    });
    setHighlightedContent(safeText);
  }, [textContent, selectedKeyword]);

  // Function to extract domain name from URL using regex
  const extractDomainName = (url) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
    const match = url.match(regex);
    const domain = match ? match[1] : null;
    return domain ? domain.replace(".com", "") : null;
  };
  const domainName = extractDomainName(initialData?.url);

  return (
    <div
      className={` col-span-3
    mx-auto mt-[1rem] lg:mt-[0rem] border-2 border-gray-200 rounded-2xl overflow-hidden`}
    >
      <div className={`sticky top-0 bg-mainColor-400  w-full`}>
        <h1
          className={`bg-white text-black border-black border-b-2  py-[13.5px] px-2 font-semibold mb-[10px] text-center mx-auto`}
        >
          <a
            href={initialData?.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg font-semibold capitalize"
          >
            {domainName}
          </a>{" "}
          - {initialData?.count} words
        </h1>
      </div>
      <div
        className={`overflow-auto h-[500px]`}
        style={{ direction: textDirection }}
      >
        <div
          className={`px-[1rem] mt-[10px]`}
          dangerouslySetInnerHTML={{ __html: highlightedContent }}
        />
      </div>
    </div>
  );
};

export default ArticleContent;
