import { useState, useEffect } from 'react';

const useFetchDetails = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const fetchedData = await response.json();
        setData(fetchedData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [url]);

  return { data, error };
};

export default useFetchDetails;
