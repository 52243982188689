const extractDomainName = (url) => {
  try {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
          // If the URL does not start with http:// or https://, assume it is missing the protocol
          url = 'http://' + url;
      }
      const parsedUrl = new URL(url);
      let hostname = parsedUrl.hostname;
      hostname = hostname.replace(/^www\./, '');
      return hostname;
  } catch (error) {
      console.error('Invalid URL', error);
      return '';
  }
};

export default extractDomainName;
