import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import customSelectStyles from '../../components/CustomStyling/customSelectStyles';
import { useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';

const FeedbackForm = () => {
    const { register, handleSubmit, setValue, control, formState: { errors }, reset } = useForm();
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userId = useSelector((state) => state.authSlice.user.id);

    const acceptedFileTypes = {
        'image/png': 'PNG',
        'image/jpeg': 'JPG',
    };

    const onDrop = acceptedFiles => {
        const file = acceptedFiles[0];
        setFile(Object.assign(file, {
            preview: URL.createObjectURL(file),
            data: file
        }));
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: Object.keys(acceptedFileTypes).join(', '), // Specify the accepted file types
    });

    const acceptedFileTypesString = Object.values(acceptedFileTypes).join(', ');

    const relatedToOptions = [
        { value: 'Rank Tracking', label: 'Rank Tracking' },
        { value: 'Keyword Research', label: 'Keyword Research' },
        { value: 'Log', label: 'Log' }
    ];

    const onSubmit = async data => {
        setIsLoading(true);
        let base64Image = '';
        if (file) {
            base64Image = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.data);
                reader.onloadend = () => {
                    resolve(reader.result); // This includes the base64 prefix
                };
                reader.onerror = error => reject(error);
            });
        }

        const formData = {
            ...data,
            image: base64Image
        };

        const emailData = {
            message: formData.message,
            subject: formData.subject,
            related_to: formData.relatedto,
            email: formData.email,
            issue: formData.issue,
            image: formData.image // The base64 string
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/feedback/${userId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            toast.success('Feedback sent successfully!');

            // Reset the form
            reset();
            setFile(null);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            toast.error('Failed to send feedback. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const customSelectStylesWithError = (hasError) => ({
        ...customSelectStyles,
        control: (provided, state) => ({
            ...provided,
            borderColor: hasError ? 'red' : provided.borderColor,
            '&:hover': {
                borderColor: hasError ? 'red' : provided['&:hover'].borderColor,
            },
            boxShadow: state.isFocused ? 'none' : provided.boxShadow,
            outline: state.isFocused ? 'none' : provided.outline
        }),
        indicatorSeparator: () => ({ display: 'none' }),
        dropdownIndicator: (provided) => ({
            ...provided,
            '&:focus': {
                outline: 'none',
                boxShadow: 'none'
            }
        })
    });

    return (
        <div className="flex justify-center mt-8 pt-12 px-6 md:px-12 mb-4">
            <div className="w-full max-w-5xl bg-white rounded-lg p-6 border-2">
                <h1 className="text-2xl font-bold mb-4 text-center">Feedback Form</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex flex-col md:flex-row md:space-x-3 space-x-0'>
                        <div className="mb-4 md:w-[30%] w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Name:
                            </label>
                            <input
                                type="text"
                                id="name"
                                {...register('name', { required: 'Name is required' })}
                                className={`appearance-none border-2 border-gray-200 rounded w-full py-2 px-3
                                     hover:border-[#9ca3af]
                                     text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.name ? 'border-red-500' : ''}`}
                                placeholder="Enter Name"
                            />
                            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
                        </div>
                        <div className="mb-4 md:w-[30%] w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                                Email:
                            </label>
                            <input
                                type="email"
                                id="email"
                                {...register('email', {
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: 'Email address is invalid',
                                    }
                                })}
                                className={`appearance-none border-2
                                     border-gray-200 hover:border-[#9ca3af] rounded 
                                     w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none
                                      focus:shadow-outline ${errors.email ? 'border-red-500' : ''}`}
                                placeholder="Enter email"
                            />
                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email.message}</p>}
                        </div>

                        <div className="mb-4 md:w-[30%] w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
                                Subject:
                            </label>
                            <input
                                type="text"
                                id="subject"
                                {...register('subject', { required: 'Subject is required' })}
                                className={`appearance-none border-2 hover:border-[#9ca3af]
                                     border-gray-200 rounded w-full py-2 px-3 text-gray-700
                                      leading-tight focus:outline-none focus:shadow-outline 
                                      ${errors.subject ? 'border-red-500' : ''}`}
                                placeholder="Enter subject"
                            />
                            {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject.message}</p>}
                        </div>
                        <div className="md:w-[30%] w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2 md:ml-3 ml:0" htmlFor="relatedto">
                                RelatedTo:
                            </label>
                            <Controller
                                name="relatedto"
                                control={control}
                                rules={{ required: 'Related to is required' }}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={relatedToOptions}
                                        className={`appearance-none border-[#9ca3af] 
                                            mt-[-8px] rounded w-full py-2 px-3 md:ml-0 ml-[-11px] text-gray-700 
                                            leading-tight focus:outline-none 
                                            focus:shadow-outline ${errors.relatedto ? 'border-red-500' : ''}`}
                                        styles={customSelectStylesWithError(errors.relatedto)}
                                    />
                                )}
                            />
                            {errors.relatedto && <p className="text-red-500 text-xs mt-1">{errors.relatedto.message}</p>}
                        </div>
                    </div>

                    {/* <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
                            Message:
                        </label>
                        <textarea
                            id="message"
                            {...register('message', { required: 'Message is required' })}
                            rows="3"
                            className={`appearance-none border-2 hover:border-[#9ca3af]   rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.message ? 'border-red-500' : ''}`}
                            placeholder="Enter message"
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-xs ">{errors.message.message}</p>}
                    </div> */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="issue">
                            Please briefly describe the issue:
                        </label>
                        <textarea
                            id="issue"
                            // {...register('issue', { required: 'Issue description is required' })}
                            rows="3"
                            className={`appearance-none border-2 hover:border-[#9ca3af] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.issue ? 'border-red-500' : ''}`}
                            placeholder="Please briefly describe the issue "
                        ></textarea>
                        {/* {errors.issue && <p className="text-red-500 text-xs ">{errors.issue.message}</p>} */}
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Upload screenshot (optional):
                        </label>
                        <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300
                        hover:border-[#9ca3af]
                         rounded p-4 text-center">
                            <input {...getInputProps()} />
                            <p className="text-sm text-gray-500">Accepted file types: {acceptedFileTypesString}</p>
                        </div>
                        <div className="mt-4 flex flex-wrap">
                            {file && (
                                <div className="w-32 h-32 m-1 border rounded">
                                    <img src={file.preview} alt={file.name} className="w-full h-full object-contain bg-gray-200 p-3" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            type="submit"
                            className="bg-mainColor text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <div className="flex items-center">
                                    Loading...
                                    {"  "}
                                    <FaSpinner className="animate-spin mr-2" />
                                </div>
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default FeedbackForm;
