const CompletedQueries = ({ showQueries, type }) => {
  let rank =
    type === "added"
      ? JSON.parse(sessionStorage.getItem("rankAdd"))
      : JSON.parse(sessionStorage.getItem("rank"));
  let newRank = Array.isArray(rank) > 0 ? [...new Set(rank)] : rank;
  return (
    <>
      <ul
        className={`${
          showQueries &&
          "listClick !max-h-60 !overflow-y-scroll border-t border-t-gray-300 mt-2 pt-2 "
        } list  `}
      >
        {showQueries && rank && (
          <>
            {Array.isArray(rank) ? (
              <>
                {newRank.map((query, index) => {
                  return (
                    <li key={index} className="mb-2">
                      {query} {type} successfully
                    </li>
                  );
                })}
              </>
            ) : (
              newRank + "" + type + "" + "successfully"
            )}
          </>
        )}
      </ul>
    </>
  );
};
export default CompletedQueries;
