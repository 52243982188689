import GenerateAIBtn from "../../../../components/keyword_research/keyword/GenerateAIBtn";
import InputForm from "../../../../components/keyword_research/keyword/InputForm";
import MainKeywordInfo from "../KeywordInfo/MainKeywordInfo";
import ScoreCard from "../KeywordInfo/ScoreCard";
import Title from "./Title";

const MainHeader = ({
  handleSubmit,
  isKeywordLoading,
  isUrlLoading,
  keywordData,
  Keyword_Query,
  keyword_Count,
  urlData,
}) => (
  <>
    {/* {keywordData && <GenerateAIBtn keywordData={keywordData} />} */}

    {/* <GenerateAIBtn keywordData={keywordData||urlData}/> */}

      {keywordData && keywordData.Weights && !urlData && (
        <div
          className={`items-center w-full 
            grid grid-cols-1 md:grid-cols-5 gap-6
          `}
        >
          <div className="col-span-3">
            <Title title="Main Keyword Research" />
            <InputForm
              onSubmit={handleSubmit}
              isLoading={isKeywordLoading || isUrlLoading}
            />
          </div>
          <MainKeywordInfo
            keyword={Keyword_Query}
            wordCount={Number(keyword_Count)}
            optimizationScore={75}
          />
        </div>
      )}
      {urlData && (
        <>
          {/* <GenerateAIBtn keywordData={keywordData} /> */}

          <div
            className={`items-center w-full 
        grid grid-cols-1 md:grid-cols-5 gap-6
      `}
          >
            <div className="col-span-3">
              <Title title="Main Keyword Research" />
              <InputForm
                onSubmit={handleSubmit}
                isLoading={isKeywordLoading || isUrlLoading}
              />
            </div>
            <MainKeywordInfo
              url
              keyword={urlData.Title}
              wordCount={Number(urlData.count).toFixed()}
              optimizationScore={75}
            />
          </div>
        </>
      )}

  </>
);

export default MainHeader;
