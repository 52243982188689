import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ResultSample from "./ResultSample";
import useFetchDetails from "../../../hook/useFetchDetails";

const DetailsKeyword = () => {
  const location = useLocation();
  const queryName = decodeURIComponent(location.pathname.split("/")[2]);
  const userId = useSelector((state) => state?.authSlice?.id);
  const [apiUrl, setAPIURL] = useState(
    `${
      process.env.REACT_APP_API_URL
    }/keyword/Query-data/${userId}/${encodeURIComponent(queryName)}/`
  );
  const { data, error } = useFetchDetails(apiUrl);
  useEffect(()=>{

},[queryName])
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
    <div className="container p-4 mx-auto">
      {data && <ResultSample data={data} />}
    </div>
  );
};

export default DetailsKeyword;
