// bookmarksSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { createSelector } from 'reselect';

const selectBookmarks = (state) => state.bookmarksSlice.bookmarks;
const selectBookmarksStatus = (state) => state.bookmarksSlice.status;

export const selectMemoizedBookmarks = createSelector(
  [selectBookmarks],
  (bookmarks) => bookmarks
);

export const selectMemoizedBookmarksStatus = createSelector(
  [selectBookmarksStatus],
  (status) => status
);


const initialState = {
  bookmarks: [],
  status: 'idle',
  error: null,
};

export const fetchBookmarks = createAsyncThunk(
    'bookmarks/fetchBookmarks',
    async ({ projectId }, { getState, rejectWithValue }) => {
      const state = getState();
      const id = state.authSlice.id;
      const token = state.authSlice.token;
      
  
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/Displaybookmarks/${id}/${projectId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        

        return response.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );




const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookmarks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookmarks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookmarks = action.payload;
      })
      .addCase(fetchBookmarks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default bookmarksSlice.reducer;
