import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// Define the async thunk for processing the keyword
export const process_Keyword = createAsyncThunk(
    'keyword/processKeyword',
    async ({ query, userId }, { getState, rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/keyword/new_scrape/${userId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query })
            });
            if (!response.ok) {
                const errorData = await response.json();
                
                throw new Error(errorData.query ? errorData.query[0] : 'Network response was not ok');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const keywordSlice = createSlice({
    name: 'keyword',
    initialState: {
        data: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(process_Keyword.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(process_Keyword.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload;
                toast.success("Keyword processed successfully");
            })
            .addCase(process_Keyword.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
                toast.error(action.payload );
            });
    }
});

export default keywordSlice.reducer;
