import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import CompletedQueries from "./CompletedQueries";

const AddProgressBar = ({
  completedAdds,
  addErrors,
  totalAdds,
  fadeOut,
  showAddProgressBar,
}) => {
  const [showQueries, setShowQueries] = useState(false);

  if (!showAddProgressBar) return null;

  const progressPercent = totalAdds > 0 ? (completedAdds / totalAdds) * 100 : 0;
  const hasErrors = addErrors > 0;
  const progressBarColor = hasErrors ? "bg-red-500" : "bg-green-500";
  const textColor = hasErrors ? "text-red-500" : "text-green-500";
  if (progressPercent === 100) {
    setTimeout(() => sessionStorage.clear(), 3000);
  }
  return (
    <div className="my-2">
      <div className="progress-bar-striped h-2 bg-gray-200 rounded-full  overflow-hidden">
        <div
          className={`h-full rounded-full ${progressBarColor} transition-all duration-500`}
          style={{ width: `${progressPercent}%` }}
        ></div>
      </div>
      <span className={`${textColor} text-sm mt-[10px]`}>
        Adding: {completedAdds}/{totalAdds}
        {hasErrors && ` with ${addErrors} error(s)`}
      </span>
      {hasErrors && (
        <svg
          className="w-4 h-[20px] text-white"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      )}
      {!showQueries && (
        <ArrowUpCircleIcon
          onClick={() => {
            setShowQueries(true);
          }}
          width={22}
          className=" text-mainColor cursor-pointer inline-block mx-2"
        />
      )}
      {showQueries && (
        <ArrowDownCircleIcon
          onClick={() => {
            setShowQueries(false);
          }}
          width={22}
          className=" text-mainColor cursor-pointer inline-block mx-2"
        />
      )}
      <CompletedQueries
        type={"added"}
        setShowQueries={setShowQueries}
        showQueries={showQueries}
      />
    
    </div>
  );
};
export default AddProgressBar;
