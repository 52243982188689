import React from "react";
import Form from "../../components/Login/Form";

const Login = () => {

  return (
    <div className="flex items-center justify-center ">
      <div className=" w-full h-screen p-[20px] bg-[#f9f9f9] ">
        <Form/>
      </div>
    </div>
  );
};

export default Login;
