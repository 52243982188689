import React, { useEffect, useState } from "react";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessing";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../pages/MainKeyword/keyword/Layout/Title";
import MainKeywordInfo from "../../../pages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";
import Tables from "../../../pages/MainKeyword/keyword/Layout/Tables";
import GenerateAIBtn from "./GenerateAIBtn";
import InputForm from "./InputForm";
import CompanyTabs from "../../keywordresearch/components/url/CompanyTabs";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import DataTableComponent from "./DataTableComponent";
import { useNavigate } from "react-router-dom";
import { jsonrepair } from "jsonrepair";
import SkeletonDataTable from "../../Skeleton/SkeletonDataTable";

const ResultSample = ({ data }) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.authSlice.id);
  const { handleSubmit, isKeywordLoading, isUrlLoading, keywordData, urlData } =
    useKeywordAndUrlProcessing(dispatch, userId);
  const [sortDirection, setSortDirection] = useState(null);

  
  const navigate = useNavigate();
  let jsonString;
  let parsedResultsSample;
  let Query;
  let aggregated_results;
  let aggregated_h2_counts;
  let aggregated_h3_counts;
  let groupedAndSortedKeywords;
  let sortedData;
  if (!keywordData && data) {
    const repaired = jsonrepair(data[0].results_sample);
    jsonString = repaired;
    parsedResultsSample = JSON.parse(jsonString);
    aggregated_results = JSON.parse(jsonrepair(data[0]?.rank_avg));
    aggregated_h2_counts = JSON.parse(jsonrepair(data[0]?.h2_count));
    aggregated_h3_counts = JSON.parse(jsonrepair(data[0]?.h3_count));
    // groupedAndSortedKeywords =
    //   groupAndSortKeywordsByPhrases(aggregated_results);
    // sortedData = groupedAndSortedKeywords;
    sortedData = aggregated_results;

    Query = data[0].query;
  } else if (keywordData) {
    parsedResultsSample = keywordData?.Weights;
    aggregated_results = parsedResultsSample?.aggregated_results;
    aggregated_h2_counts = keywordData.Weights.aggregated_h2_counts;
    aggregated_h3_counts = keywordData.Weights.aggregated_h3_counts;
    // groupedAndSortedKeywords =
    //   groupAndSortKeywordsByPhrases(aggregated_results);
    sortedData = aggregated_results;
    Query = keywordData.Query;
  }
  const filteredLinks = parsedResultsSample?.filtered_links;
  const weighted_word_count = parsedResultsSample?.weighted_word_count;
  function findCommonPhrase(keyword, groups) {
    for (let phrase in groups) {
      const wordsInGroup = phrase.split(" ");
      const wordsInKeyword = keyword.split(" ");

      // Ensure at least 2 words match, and prioritize more than 2-word phrases
      const matchCount = wordsInGroup.filter((word) =>
        wordsInKeyword.includes(word)
      ).length;

      if (matchCount >= 2) {
        return phrase;
      }
    }
    return null;
  }
  function groupAndSortKeywordsByPhrases(keywords) {
    const groups = {}; // Store groups with the original object structure

    keywords.forEach((item) => {
      const foundGroup = findCommonPhrase(item.keyword, groups);

      if (foundGroup) {
        groups[foundGroup].push(item);
      } else {
        // Create a new group based on the first two words (or more) as a phrase
        const phrase = item.keyword.split(" ").slice(0, 3).join(" "); // Adjust this to capture 2-3 word phrases
        if (!groups[phrase]) {
          groups[phrase] = [];
        }
        groups[phrase].push(item);
      }
    });

    // Convert groups to an array of objects and sort them by total count in the group
    const sortedGroups = Object.entries(groups).map(([group, items]) => ({
      group,
      totalCount: items.reduce((acc, curr) => acc + curr.count, 0), // Sum counts
      // Sort the items within each group by count in descending order
      items: items.sort((a, b) => b.count - a.count),
    }));

    const flattenedKeywords = sortedGroups.flatMap((group) => group.items);

    // Sort the final array so that items with only one keyword go to the end
    return flattenedKeywords.sort((a, b) => {
      const aWords = a.keyword.split(" ").length;
      const bWords = b.keyword.split(" ").length;

      // Push items with only one word to the end
      if (aWords === 1 && bWords > 1) return 1;
      if (bWords === 1 && aWords > 1) return -1;

      // Otherwise, maintain original order
      return 0;
    });
  }
  const combinedH2H3DataArray = [
    ...(aggregated_h2_counts
      ? Object.keys(aggregated_h2_counts).map((keyword) => ({
          keyword: keyword,
          count: aggregated_h2_counts[keyword],
          type: "H2",
        }))
      : []),
    ...(aggregated_h3_counts
      ? Object.keys(aggregated_h3_counts).map((keyword) => ({
          keyword: keyword,
          count: aggregated_h3_counts[keyword],
          type: "H3",
        }))
      : []),
  ];
  const handleSort = () => {
    let sorted;
    let newSortDirection;

    if (sortDirection === "asc") {
      sorted = [...aggregated_results].sort((a, b) => b.count - a.count);
      newSortDirection = "desc";
    } else if (sortDirection === "desc") {
      sorted = [...aggregated_results].sort((a, b) => a.count - b.count);

      newSortDirection = null;
    } else {
      sorted = [...aggregated_results];
      newSortDirection = "asc";
    }
    sortedData = sorted;
    setSortDirection(newSortDirection);
  };
  const aggregatedResultsColumns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "85%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
  ];
  const combinedH2H3Columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
    { name: "Type", selector: (row) => row.type, sortable: true, width: "15%" },
  ];
  return (
    <div className="relative w-full mx-auto mt-[5rem] rounded-lg
     bg-baseColor dark:bg-darkbg dark:text-gray-300 md:mt-14 md:p-5">
     
     {!isKeywordLoading && !isUrlLoading && (

      <button
        onClick={() => {
          // Extract only the serializable parts of the columns
          const serializableAggregatedColumns = aggregatedResultsColumns.map(
            (column) => ({
              name: column.name,
              width: column.width,
            })
          );

          const serializableSubHeadingsColumns = combinedH2H3Columns.map(
            (column) => ({
              name: column.name,
              width: column.width,
            })
          );

          navigate(`/generated-article/${Query}`, {
            state: {
              Query,
              weighted_word_count,
              aggregatedResults: sortedData,
              aggregatedResultsColumns: serializableAggregatedColumns, // Pass the serializable version
              combinedH2H3Columns: serializableSubHeadingsColumns, // Pass the serializable version of subheadings columns
              combinedH2H3DataArray, // Pass the data array as it is
            },
          });
        }}
        className="flex items-center gap-2 ml-auto mb-5 bg-gradient-to-r from-sky-500 to-sky-300 text-white px-4 py-2 rounded-full shadow-lg transform transition duration-200 ease-in-out hover:scale-105"
      >
        Generate and View Article
      </button>
     )}
      <div
        className={`items-center w-full ${(parsedResultsSample || urlData) &&
          "grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-3"
          }`}
      >
        <div className="lg:col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>

        {isKeywordLoading  ? (
          <SkeletonMainKeywordInfo />
        ) : (
          <MainKeywordInfo
            url={urlData ? true : false}
            keyword={Query}
            wordCount={Number(weighted_word_count).toFixed()}
            optimizationScore={75}
          />
        )}
      </div>

      {data || keywordData ? (
        <Tables
          linksData={filteredLinks}
          aggregatedResults={sortedData}
          aggregatedResultsColumns={aggregatedResultsColumns}
          combinedH2H3DataArray={combinedH2H3DataArray}
          combinedH2H3Columns={combinedH2H3Columns}
          onSort={handleSort}
          isKeywordLoading={isKeywordLoading}
        />
      ) : (
        <div className=" lg:w-[80%] flex items-center justify-center py-8 border border-gray-300 rounded m-auto my-8">
          <p className="font-medium">No data available</p>
        </div>
      )}
    </div>
  );
};

export default ResultSample;
