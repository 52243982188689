import { useState, useEffect } from "react";
import { normalizeUrl } from "../utils/Functions";

const DEFAULT_VALUES = {
  searchQuery: "",
  selectedLocationFilter: [],
  selectedTargetUrl: "All Sources",
  selectedBookmark: null,
  rankRange: { min: 1, max: 101 },
  startDate: null,
  endDate: null,
};

const useFilters = (
  tableData,
  detailData,
  selectedBookmark,
  setSelectedBookmark,
  loading,
  setSelectedBookmarkId // Add this parameter
) => {
  const [searchQuery, setSearchQuery] = useState(DEFAULT_VALUES.searchQuery);
  const [selectedLocationFilter, setSelectedLocationFilter] = useState(
    DEFAULT_VALUES.selectedLocationFilter
  );
  const [selectedTargetUrl, setSelectedTargetUrl] = useState(
    DEFAULT_VALUES.selectedTargetUrl
  );
  const [rankRange, setRankRange] = useState(DEFAULT_VALUES.rankRange);
  const [filteredData, setFilteredData] = useState(tableData);
  const normalizeString = (str) => str.replace(/\s+/g, " ").trim();

  useEffect(() => {
    let result = [];
    if (tableData) {
      let filteredDataWithoutSearch = selectedBookmark
        ? detailData.map((item) => item.rank)
        : [...tableData];
      result = selectedBookmark
        ? detailData.map((item) => item.rank)
        : [...tableData];

      // Normalize function to handle spaces

      // Apply search filter
      if (searchQuery) {
        const normalizedQuery = normalizeString(searchQuery);
        result = filteredDataWithoutSearch
          ? filteredDataWithoutSearch.filter((item) =>
              normalizeString(item.query).includes(normalizedQuery)
            )
          : tableData.filter((item) =>
              normalizeString(item.query).includes(normalizedQuery)
            );
      }

      if (selectedLocationFilter.length > 0) {
        result = result.filter((item) =>
          selectedLocationFilter.some(
            (filter) => filter.value === item.google_domain
          )
        );

        filteredDataWithoutSearch = result.filter((item) =>
          selectedLocationFilter.some(
            (filter) => filter.value === item.google_domain
          )
        );
      }

      // Apply target URL filter
      if (selectedTargetUrl !== "All Sources") {
        const normalizedSelectedUrl = normalizeUrl(selectedTargetUrl);
        result = result.filter(
          (item) => normalizeUrl(item.target_url) === normalizedSelectedUrl
        );
        filteredDataWithoutSearch = result.filter(
          (item) => normalizeUrl(item.target_url) === normalizedSelectedUrl
        );
      }

      // Apply rank range filter
      result = result.filter((item) => {
        const rank = item.rank;
        return (
          (rank >= rankRange.min && rank <= rankRange.max) ||
          (rankRange.max === 101 && rank === 101)
        );
      });
    }

    setFilteredData(result);
  }, [
    searchQuery,
    selectedLocationFilter,
    selectedTargetUrl,
    rankRange,
    selectedBookmark,
    loading,
    // tableData,
    // filteredData,
    setFilteredData,
  ]);

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const handleTargetUrlFilterChange = (selectedOption) => {
    setSelectedTargetUrl(selectedOption ? selectedOption.value : "All Sources");
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocationFilter(selectedOption || []);
  };

  const handleRangeChange = (range) => {
    setRankRange(range);
  };

  const hasFiltersApplied = () => {
    return (
      searchQuery !== DEFAULT_VALUES.searchQuery ||
      selectedLocationFilter.length > 0 ||
      selectedTargetUrl !== DEFAULT_VALUES.selectedTargetUrl ||
      rankRange.min !== DEFAULT_VALUES.rankRange.min ||
      rankRange.max !== DEFAULT_VALUES.rankRange.max ||
      selectedBookmark !== DEFAULT_VALUES.selectedBookmark
    );
  };

  const handleResetFilters = () => {
    setFilteredData(tableData);
    setRankRange(DEFAULT_VALUES.rankRange);

    setSearchQuery(DEFAULT_VALUES.searchQuery);
    setSelectedLocationFilter(DEFAULT_VALUES.selectedLocationFilter);
    setSelectedTargetUrl(DEFAULT_VALUES.selectedTargetUrl);
    setSelectedBookmark(DEFAULT_VALUES.selectedBookmark);
    setSelectedBookmarkId(null); // Reset the selectedBookmarkId
  };

  return {
    filteredData,
    setFilteredData,
    searchQuery,
    handleSearchInputChange,
    selectedLocationFilter,
    handleLocationChange,
    selectedTargetUrl,
    handleTargetUrlFilterChange,
    rankRange,
    handleRangeChange,
    hasFiltersApplied,
    handleResetFilters,
    setSelectedLocationFilter,
    setSelectedTargetUrl,
    setSearchQuery,
  };
};

export default useFilters;
