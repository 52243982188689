import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Import useHistory
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { FaRegFileWord } from "react-icons/fa"; // Import the Word icon
import { FaSave } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6, false] }],
    ["bold", "italic", "underline",  "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
 
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "color",
  "background",
  "list",
  "bullet",
  "script",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "align",
  "indent",

];

const SkeletonLoader = () => {
  return (
    <div className="animate-pulse space-y-4">
      {/* Skeleton for a heading */}
      <div className="h-6 bg-gray-300 rounded w-3/4"></div>
      
      {/* Skeleton for a paragraph */}
      <div className="space-y-2">
        <div className="h-4 bg-gray-300 rounded w-full"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        <div className="h-4 bg-gray-300 rounded w-2/3"></div>
      </div>

      {/* Skeleton for an image */}
      {/* <div className="bg-gray-300 h-48 w-full rounded"></div> */}
    </div>
  );
};

const isArabicText = (text) => {
  const arabicPattern = /[\u0600-\u06FF]/;
  return arabicPattern.test(text);
};
const debounce = (func, delay) => {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};// Use the debounce function

const GeneratedURLPage = ({ show}) => {
  const { userid, object_id, url } = useParams();
  const decodedUrl = decodeURIComponent(url);
  const location = useLocation();
  const { content: initialContent, headings, url_title, word_count, fromPost } = location.state || {};


  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  console.log(url_title,decodedUrl,url,'decodedUrl');
  
  const [title, setTitle] = useState(url_title || decodedUrl || "");
  const [contentDirection, setContentDirection] = useState("ltr");
  const token = useSelector((state) => state.authSlice?.user.token);
  const [isSaving, setIsSaving] = useState(false);
  const quillRef = useRef(null);
  const [isTyping, setIsTyping] = useState(true); // Set true for new posts, false for others

  const [userScrolled, setUserScrolled] = useState(false); 
  const [content, setContent] = useState("");
  const [headingsInContent, setHeadingsInContent] = useState([]); // New state to store headings
  const [loading, setLoading] = useState(true); // Define the loading state
  const [currentWordCount, setCurrentWordCount] = useState(word_count);

  const exportArticleAsWord = (content, title) => {
    const converted = htmlDocx.asBlob(content);
    saveAs(converted, `${title}.docx`);
    toast.success("Article exported as Word");
  };

  const simulateTypingEffect = (fullContent, delay = 6) => {
    let index = 0;
    const length = fullContent.length;

    const typeInterval = setInterval(() => {
      if (index <= length) {
        setContent(fullContent.substring(0, index));
        index += 1;

        //   // Auto-scroll the window only if the user hasn't manually scrolled
        if (!userScrolled) {
          // debouncedScroll(); // Use the debounced scroll function
        }
      } else {
        clearInterval(typeInterval);
        setIsTyping(false); // Typing has finished, enable buttons

        setLoading(false); // Set loading to false after typing effect 

      }
    }, delay);
  };
  const processArticleContent = (headings, content) => {
    const sections = content.split("\n").map((section) => section.trim());
    const combinedContent = headings.map((heading, index) => {
      let paragraph = sections[index] || "";
      
      // Ensure that the heading is not repeated
      if (paragraph.startsWith(heading)) {
        paragraph = paragraph.replace(heading, "").trim();
      }
  
      // Check if there's a leading colon and remove it
      if (paragraph.startsWith(":")) {
        paragraph = paragraph.substring(1).trim();
      }
  
      // Only include heading if the paragraph has content
      return paragraph ? `<h2>${heading}</h2><p>${paragraph}</p>` : "";
    }).filter(Boolean).join("");
  
    return combinedContent;
  };
  useEffect(() => {
    console.log(initialContent,'initialContent');
    
    if (initialContent) {
      setContentDirection(isArabicText(initialContent) ? "rtl" : "ltr");
  
      if (headings && Array.isArray(headings)) {
        const combinedContent = processArticleContent(headings, initialContent);
        
        if (isTyping && !location.state?.fromHistory) {
          simulateTypingEffect(combinedContent);
        } else {
          console.log(combinedContent,'headingCom');
          
          setContent(combinedContent);
          setLoading(false); // Stop loading once content is set

        }
      } else {
        if (isTyping) {
          simulateTypingEffect(initialContent);
        } else {
          console.log(initialContent,"headingIni");
          
          setContent(initialContent);
          setLoading(false); // Stop loading once content is set

        }
      }
      navigate(".", { replace: true, state: undefined });
    }
  }, [fromPost, initialContent, headings, isTyping, navigate]);
  useEffect(() => {
    if (initialContent && (!content || content.trim().length === 0)) {
      console.log(initialContent,"headingIni");

      setContent(initialContent);

    }
  }, [initialContent, content]);
  


  const handleChange = (newContent) => {
    const newDirection = isArabicText(newContent) ? "rtl" : "ltr";
    setContentDirection(newDirection);
    setContent(newContent);

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();

      setTimeout(() => {
        if (!userScrolled) {
       
          editor.root.scrollTo({
            top: editor.root.scrollHeight,
            behavior: "smooth",
          });

          // Smooth scroll to the right (horizontal)
          editor.root.scrollTo({
            left: editor.root.scrollWidth,
            behavior: "smooth",
          });
        }
      }, 400);
    }
  };

  
  const handleSave = async () => {
    setIsSaving(true);

    try {
      // Save the edited article
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${object_id}/`,
        {
          title: title,
          headings:headingsInContent,
          Generated_article: content,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {

        navigate(".", { replace: true, state: undefined });
        setUserScrolled(true);
        // fetchUpdatedArticle(); // Fetch updated article content after saving

      } else {
        toast.error("An error occurred while saving the article.");
      }
    } catch (error) {
 if(isTyping.fromPost){
  console.error("Error saving article:", error);
  toast.error("An error occurred while saving the article.");
 }
    } finally {
      setIsSaving(false); // Stop loading spinner
    }
  };
  
  
  useEffect(() => {
    const handleScroll = () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        // Check if user has scrolled horizontally or vertically
        const isUserScrolledVertically =
          editor.root.scrollTop + editor.root.clientHeight <
          editor.root.scrollHeight;
        const isUserScrolledHorizontally =
          editor.root.scrollLeft + editor.root.clientWidth <
          editor.root.scrollWidth;

        if (isUserScrolledVertically || isUserScrolledHorizontally) {
          setUserScrolled(true); // User manually scrolled
        } else {
          setUserScrolled(false); // Reset if the scroll is at the bottom and far right
        }
      }
    };

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.root.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        editor.root.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const fetchUpdatedArticle = async () => {
    setIsTyping(true); // Disable buttons during fetch
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${object_id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        const { title: fetchedTitle, Generated_article: fetchedContent,headings:articleHeadings } =
          response.data;
        setTitle(fetchedTitle);
        const Allheadings=fromPost?headings:articleHeadings
        console.log(Allheadings,headings,articleHeadings,'headings');

        if (Allheadings && Array.isArray(Allheadings)) {
          const articleSections = fetchedContent
            .split("\n")
            .map((section) => section.trim());

          const combinedContent = Allheadings
            .map((heading, index) => {
              let paragraphContent = articleSections[index] || "";

              // Remove heading if it's repeated in the paragraphContent
              if (paragraphContent.startsWith(heading)) {
                paragraphContent = paragraphContent.replace(heading, "").trim();
              }
              // Remove leading colon if it exists
              if (paragraphContent.startsWith(":")) {
                paragraphContent = paragraphContent.substring(1).trim();
              }

              if (!paragraphContent) {
                return ""; // Skip this heading if no content is available
              }

              // Determine the text direction for the heading and paragraph
              const isHeadingArabic = isArabicText(heading);
              const isParagraphArabic = isArabicText(paragraphContent);

              const headingDirection = isHeadingArabic ? "rtl" : "ltr";
              const paragraphDirection = isParagraphArabic ? "rtl" : "ltr";
console.log(heading,paragraphContent,'headdddd');

{/* <h2 style="text-align: ${headingDirection}; direction: ${headingDirection}; width: 80%; margin: 0 auto;">
 ${heading}
</h2>  */}
return `
              <p style="text-align: ${paragraphDirection}; direction: ${paragraphDirection}; width: 80%; margin: 0 auto;">
                ${paragraphContent}
              </p>`;
              // }).join('');
            })
            .filter(Boolean)
            .join(""); // Join all elements into a single string
          
          setContent(combinedContent);

          // Set the overall direction for the editor based on the combined content
          setContentDirection(isArabicText(combinedContent) ? "rtl" : "ltr");
        } else {
          // If no headings exist, handle content direction based on the entire fetched content
          console.log(fetchedContent,'headings');
          
          setContent(fetchedContent);
          setContentDirection(isArabicText(fetchedContent) ? "rtl" : "ltr");
        }
        setIsTyping(false)

      }
    } catch (error) {
      console.error("Error fetching article data:", error);
      toast.error("Failed to fetch article data.");
      setIsTyping(false); // Enable buttons even if there is an error


    }
  };

 useEffect(()=>{
 
if(!fromPost){
  fetchUpdatedArticle();
  console.log('omniaahmed')
}
 },[])

  useEffect(() => {
    const isPageReloaded = performance?.navigation?.type === 1; // Check if the page was reloaded
  console.log(isTyping,"dodo")

    if (!isTyping && fromPost) {
      console.log(isTyping,"dodo")

      // handleSave();
      fetchUpdatedArticle();
      handleSave();
    }
  // if (!isTyping && fromPost) {
    
  //   window.location.reload(); // Reload the page once typing finishes
  // } 
  }, [isTyping, object_id, location.state, fromPost]);

 
  useEffect(() => {
    updateHeadings(content);
  }, [content]);


  const handleScrollState = () => {
    // Check if the user has scrolled more than 100px from the top
    if (window.scrollY > 100 || document.documentElement.scrollTop > 100) {
      setIsScrolled(true); // User has scrolled past the threshold
    } else {
      setIsScrolled(false); 
    }
  };
  const isAtBottom = () => {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const totalHeight = document.documentElement.scrollHeight;
  
    return windowHeight + scrollPosition + 5 >= totalHeight; // Adjusted for minor differences
  };
  const checkScrollIconVisibility = () => {
    const contentHeight = document.documentElement.scrollHeight;
    const viewportHeight = window.innerHeight;
  
    // Check if content is taller than the viewport and if the user isn't at the bottom
    console.log(contentHeight,viewportHeight,"viewportHeight");
    
    if (contentHeight > viewportHeight && !isAtBottom()) {
      setShowScrollIcon(true);
    } else {
      setShowScrollIcon(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      handleScrollState();  // Track the scroll state
      checkScrollIconVisibility(); // Handle scroll icon visibility
    };
  
    window.addEventListener("scroll", handleScroll);
  
    // Initial check when the component mounts
    handleScrollState();
    checkScrollIconVisibility();
  
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAtBottom()]);

  useEffect(() => {
    const handleScroll = () => {
      if (isAtBottom()) {
        // console.log("You are at the bottom of the screen!");
        // // Perform any actions when reaching the bottom
      } else {
        // console.log("You are not at the bottom yet.");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [document.documentElement.scrollHeight]);
  const [fromHistory, setFromHistory] = useState(
    location.state?.fromHistory || false
  ); // Initialize with the value from location.state

  useEffect(() => {
    if (location.state?.fromHistory) {
      setFromHistory(true);
      setIsTyping(false);
    }
  }, [location.state]);


  const updateHeadings = (content) => {
    console.log(content,'heading');
    
    // Extract headings (h2 elements in this case) from the content
    const headingRegex = /<h2[^>]*>(.*?)<\/h2>/g;
    const extractedHeadings = [];
    let match;
    while ((match = headingRegex.exec(content)) !== null) {
      extractedHeadings.push(match[1]);
    }
    console.log(extractedHeadings,'extractedHeadings');
    
    setHeadingsInContent(extractedHeadings);
  };

  // Call updateHeadings whenever the content updates
  useEffect(() => {
    updateHeadings(content);
  }, [content]);



useEffect(() => {
  if (word_count) {
    setCurrentWordCount(word_count);
  }
}, [word_count]);


useEffect(() => {
  const handleScroll = () => {
    if (isAtBottom()) {
      setShowScrollIcon(false);
    } else {
      checkScrollIconVisibility();
    }
  };

  // Listen for scroll events
  window.addEventListener("scroll", handleScroll);

  // Call checkScrollIconVisibility when the component mounts
  checkScrollIconVisibility();

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);


 




  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
const [showScrollIcon, setShowScrollIcon] = useState(true);


// Function to check if the content height exceeds the viewport height


useEffect(() => {
  // Monitor scrolling to show or hide the scroll icon
  const handleScroll = () => {
    if (isAtBottom()) {
      setShowScrollIcon(false);
    } else {
      checkScrollIconVisibility();
    }
  };

  // Listen for scroll events
  window.addEventListener("scroll", handleScroll);

  // Call checkScrollIconVisibility when the component mounts
  checkScrollIconVisibility();

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

return (
<div className="container mx-auto p-4 mt-[3.5rem] flex flex-col relative">
<div className="flex items-center justify-between mb-4 w-full gap-4  py-3">
 

  
  <div className=" flex-1 items-center  ">
    <input
      type="text"
      value={title}
      onChange={(e) => setTitle(e.target.value)}
      className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-400"
      placeholder="Enter article title"
      style={{ direction: contentDirection }}
    />
  </div>

  {/* Export and Save buttons on the right */}
  <div className="flex items-center gap-4 ">
    <button
      onClick={() => exportArticleAsWord(content, title)}
      className={`flex items-center gap-2  bg-gradient-to-r
              from-sky-500 to-sky-300  text-white px-4 py-2 rounded-full shadow-lg 
         transform transition duration-200 ease-in-out hover:scale-105 
         ${
           isTyping ? "opacity-50 cursor-not-allowed" : ""
         }`}
       disabled={isTyping } 


    >
      <FaRegFileWord className="text-xl" />
      Export
    </button>
 

   
    <button
      onClick={handleSave}
      className={`bg-mainColor text-white px-6 py-2 shadow-lg rounded-full transform 
        transition-transform duration-200 ease-in-out hover:scale-105 active:scale-95 ${
        isSaving || isTyping ? "cursor-not-allowed opacity-50" : ""
      }`}
  
      disabled={isSaving || isTyping }
    >
      {isSaving ? (
        <div className="flex items-center gap-2">
          <FaSpinner className="animate-spin" />
          Saving...
        </div>
      ) : (
        <div className="flex items-center gap-2">
          <FaSave />
          Save 
        </div>
      )}
    </button>

    
  </div>
</div>



  <div className="flex w-full">
    {/* ReactQuill Editor Section */}
    <div className="w-3/4 pr-4">
      <div
        className={`bg-white p-4 rounded-lg border border-gray-300 quill-editor-${contentDirection}`}
        style={{ direction: contentDirection }}
      >
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={content}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          className="min-h-[150px] border-none focus:ring-0"
          style={{ direction: contentDirection }}
          
        />
      </div>
    </div>

 
<div
  className={` bg-gray-100 p-4 border rounded-lg ${show ?'w-[20%]':'w-[24%]'}
     border-gray-300 h-[80vh] overflow-y-auto fixed right-[20px] transition-all duration-300 ${
    isScrolled ? "mt-[4.5rem] top-0 " : "mt-[0rem] "
  }`}
>

    <ul className="space-y-2">
      
      {headingsInContent.length > 0 ? (
        headingsInContent.map((heading, index) => (
        <>
        
          <li
            key={index}
            className="flex flex-col justify-start items-start p-2 bg-white rounded border border-gray-200 shadow-sm hover:shadow-md transition duration-200"
          >
            
            <div className="flex items-center space-x-2">
              <div className="text-sm text-gray-800">{heading}</div>
              <span className="bg-green-100 px-2 py-0.5 rounded-full text-[10px]">Heading</span>
            </div>
            {/* Display "Typical" in a new line right after the heading */}
            <span className="text-xs text-gray-500 mt-1">
              Typical: {Math.floor(Math.random() * 10) + 1}-{Math.floor(Math.random() * 40) + 10}
            </span>
          </li>
        </>
        ))
      ) : (
        <li className="text-sm text-gray-500">No headings found</li>
      )}
    </ul>
 
</div>

{ showScrollIcon && !fromHistory && (
        <div
          className={`fixed bottom-8 ${show ? 'left-[-200px' : 'left-8'} 
           bg-mainColor  text-white
            flex items-center justify-center m-auto
                  font-bold rounded-full shadow-lg cursor-pointer
                  focus:outline-none focus:shadow-outline transform 
                  transition-transform duration-300 ease-in-out 
                   z-[10000] w-12 h-12  `}
          onClick={scrollToBottom} // Trigger scroll to bottom on click
        >
          <FaArrowDown  className='' size={22} />
        </div>
      )}

  </div>



</div>

);


};
export default GeneratedURLPage;