import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { loginSchema } from "../../utils/validation";
import { login } from "../../redux/lib/auth";
import { setOriginalUser } from "../../redux/lib/originalUserSlice";

import { useDispatch, useSelector } from 'react-redux';
import { FaSpinner } from 'react-icons/fa';
import createLog from '../../pages/SidebarPages/Log/createLog';


const LoginForm = ({ NameUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const verified = useSelector(state => state.authSlice.verified);
  const [csrfToken, setCsrfToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const originalUser = useSelector((state) => state.originalUserSlice.name);
  const userId = useSelector(state => state?.authSlice?.id);

  // Initialize react-hook-form with validation schema
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(loginSchema),
    mode: "onChange"

  });

  // Display verification message on initial render if present in local storage
  useEffect(() => {
    const showVerifyMessage = localStorage.getItem('showVerifyMessage');
    if (showVerifyMessage) {
      toast.info("Please verify your account through email to access all features");
      localStorage.removeItem('showVerifyMessage');
    }
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);

  // Toggle the visibility of the password input field
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Fetch the CSRF token from the meta tag on initial render
  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    setCsrfToken(token);
  }, []);

  // Handle form submission and login logic
  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const loginData = {
        identifier: data.identifier,
        password: data.password,
      };

      // Send login request to the API
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login/`,
        loginData,
        {
          headers: {
            "Content-Type": "application/json",
            // 'CSRF-Token': csrfToken, // Uncomment if CSRF token is required
          },
        }
      ).then((res) => {

        const { token, id, Name, Email, "Email Verified": verified, Roles, daily_quota, ACCOUNT_TYPE }
          = res?.data;

      

        toast.success("Login successfully");
        dispatch(setOriginalUser({
          token,
          id,
          verified,
          Roles,
          name:Name, 
          email:Email
        }));
        // Dispatch login action to update Redux store
        dispatch(login({
          token,
          id,
          verified,
          Roles,
          name:Name, 
          email:Email,
          daily_quota, // Include daily_quota in the login action payload
          ACCOUNT_TYPE,
        }));
   // Ensure userId is defined before creating the log
   if (id) {
    createLog(`Login by ${Name}`, id);
  } else {
    console.error('User ID is undefined or null. Log entry cannot be created.');
  }

        // Navigate to the home page after successful login
        navigate("/");

      }).catch((err) => {

        toast.error(err?.response?.data?.error);
      })
      console.log(response,"response")

    } catch (error) {
      // Handle login failure
      // toast.error(error?.response?.data?.message);
      console.error("Login failed", error);
    }
    finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full max-w-md p-6 mx-auto mt-20 rounded-lg shadow-custom sm:mt-10"
    >
      <h2 className="mb-3 text-2xl font-bold text-center text-mainColor">
        RankTracker
      </h2>
      <h3 className="mt-3 text-lg font-bold text-mainColor lg:text-xl md:text-2xl">
        Sign-in
      </h3>
      <p className="mt-4 mb-3 text-md">
        Access Ranktracker using your name or email and password.
      </p>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Username or Email:
        </label>
        <input
          type="text"
          placeholder="Enter your username or email"
          {...register("identifier")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.identifier && (
          <p className="mt-1 text-xs text-red-500">{errors.identifier.message}</p>
        )}
      </div>

      <div className="relative mb-6">
        <label className="font-bold text-gray-600 transition-all cursor-text peer-focus:text-xs peer-focus:-top-4 peer-focus:text-purple-600">
          Password:
        </label>
        <input
          placeholder="Enter your password"
          type={passwordVisible ? "text" : "password"}
          {...register("password")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 right-0 flex items-center px-2 mt-6 focus:outline-none"
        >
          {!passwordVisible ? (
            <HiEyeOff className="text-gray-600" />
          ) : (
            <HiEye className="text-gray-600" />
          )}
        </button>
        {errors.password && (
          <p className="mt-1 text-xs text-red-500">{errors.password.message}</p>
        )}
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className={`px-4 py-2 mt-5 font-bold text-white rounded transition-colors ${isLoading
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-mainColor hover:bg-mainColor-700'
          }`}

      >
        {isLoading ? (
          <>
            <FaSpinner className="inline-block animate-spin mr-2" />
            Signing In...
          </>
        ) : (
          'Sign In'
        )}
      </button>

      <div className="mb-4 text-center">
        <p className="mt-4 text-md">
          Don't have an account?{" "}
          <span className="cursor-pointer text-mainColor">
            <Link to="/register">Create Account</Link>
          </span>
        </p>
      </div>
      <div className=" text-center">
        <a href="/forgot-password" className="text-red-500 underline">
          Forgot your password?
        </a>
      </div>
    </form>
  );
};

export default LoginForm;
