import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { FaArrowTrendUp, FaArrowTrendDown, FaArrowRightLong } from "react-icons/fa6";

const ActivityPieComponent = ({ tableData }) => {
  const rankStatistics = useMemo(() => {
    let increased = 0;
    let decreased = 0;
    let same = 0;

    tableData.forEach(({ rank_diff }) => {
      if (rank_diff > 0) {
        increased++;
      } else if (rank_diff < 0) {
        decreased++;
      } else {
        same++;
      }
    });

    return { increased, decreased, same };
  }, [tableData]);

  const chartData = {
    series: [rankStatistics.increased, rankStatistics.decreased, rankStatistics.same],
    options: {
      chart: {
        type: 'pie',
      },
      labels: ['Improved', 'Declined', 'Maintained'],
      colors: ['#16a34a', '#dc2626', '#c4c2c2'],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
            offset: -20,
            minAngleToShowLabel: 0,
          },
        },
      },
      stroke: {
        width: 2,
        colors: ['#ededed'], 


      },
    },
  };

  if (rankStatistics.increased === 0 && rankStatistics.decreased === 0 && rankStatistics.same === 0) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full border-2 border-gray-200 bg-white dark:bg-slate rounded-lg">
        <p className="text-lg font-semibold text-gray-700 mb-2">Activity Ranks</p>
        <p className="text-gray-500 text-center">No data available</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between w-full items-center p-4 transition duration-500 transform bg-white dark:bg-slate border-2
     border-gray-200 dark:border-gray-500 rounded-lg">
      <p className="text-gray-700 dark:text-gray-300 text-lg font-semibold mb-2 mr-auto">Activity Pie</p>
      <ReactApexChart options={chartData.options} series={chartData.series} type="pie" width={200} height={200} />
      <div className='w-full flex items-center justify-center gap-2'>
        <p className="text-green-500 text-sm flex items-center justify-center gap-1">
          <FaArrowTrendUp />
          <span>{rankStatistics.increased}</span>
        </p>
        <p className="text-red-500 text-sm flex items-center justify-center gap-1">
          <FaArrowTrendDown />
          <span>{rankStatistics.decreased}</span>
        </p>
        <p className="text-gray-500 dark:text-gray-400 text-sm flex items-center justify-center gap-1">
          <FaArrowRightLong />
          <span>{rankStatistics.same}</span>
        </p>
      </div>
    </div>
  );
};

export default ActivityPieComponent;
