import React from 'react';
import ContentBrief from './ContentBrief';
import ScoreCard from './ScoreCard';


const MainKeywordInfo = ({url, keyword, wordCount, optimizationScore }) => (
    <div className='flex flex-col  ml-[0.75rem] items-center  w-[100%] border-2
     mt-3 md:mt-0
     border-gray-200 rounded-md md:col-span-2 md:flex-row  '>
      
        <div className="w-full p-4  ">
            <p><strong> {url?"URL":"Keyword"} : </strong></p>
            <p>{keyword}</p>
        </div>
        <div className="w-full p-4 lg:w-4/10">
            <ContentBrief wordCount={Math.round(wordCount)} />

        </div>
        <div className="w-full p-4">
            <ScoreCard score={optimizationScore} />
        </div>

    </div>
);

export default MainKeywordInfo;