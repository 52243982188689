import React, { useEffect, useState } from "react";
import InputForm from "../keyword/InputForm";
import Title from "../../../pages/MainKeyword/keyword/Layout/Title";
import MainKeywordInfo from "../../../pages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";
import CompanyTabs from "../../keywordresearch/components/url/CompanyTabs";
import DataTableComponent from "../keyword/DataTableComponent";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import SkeletonDataTable from "../../Skeleton/SkeletonDataTable";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaEye, FaSpinner, FaTrash, FaRegFileWord } from "react-icons/fa"; // Import the Word icon
import { toast } from "react-toastify";
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import ConfirmationModal from "../../Modal/ConfirmationModal";
const InitialDataDisplay = ({
  handleSubmit,
  isKeywordLoading,
  isUrlLoading, // Loading flag
  initialData,
  h2Columns,
  h3Columns,

}) => {
  // const url_title = initialData[0].title;
  const navigate = useNavigate();
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const token = useSelector((state) => state.authSlice?.user.token);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const location = useLocation(); // Get current location
  console.log(token, "token");
  // State to track loading status of the API call
  const [isGenerating, setIsGenerating] = useState(false);
  console.log(historyData, "historyData");
  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchHistoryData(); // Call the fetch function on component mount and when navigating back
  }, [location, token]);
  const handleHistory = async () => {
    try {
      setIsLoadingHistory(true);
      setIsHistoryModalOpen(true); // Show modal
      // Fetch data whenever the modal is opened
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Filter history data where kwd_flag is false
      const filteredHistoryData = response.data.filter(
        (item) => item.kwd_flag === false
      );
      console.log(filteredHistoryData, "history");
      setHistoryData(filteredHistoryData);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoadingHistory(false);

    }
    
  };
  const handleGenerateArticle = async () => {
    if (initialData && initialData.length > 0) {

      const { object_id: ut, title: url_title } = initialData[0];
      
      if (userId && ut) {
        try {
          setIsGenerating(true);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
            { ut_id: ut, title: url_title, kwd_flag: false },
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 201) {
            const { Generated_article, headings ,word_count } = response.data;
            if (Generated_article && headings && word_count) {
              navigate(
                `/generatedarticle/${userId}/${response.data.object_id}/`,
                {
                  state: {
                    content: Generated_article,
                    headings,
                    url_title,
                    fromPost: true,
                    word_count
                  },
                }
              );
            }
          }
        } catch (error) {
          console.error("Error generating article:", error);
        } finally {
          setIsGenerating(false);
        }
      }
    }
  };
  const closeModal = () => {
    setIsHistoryModalOpen(false);
  };
  const handleViewDetails = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${item.object_id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const { Generated_article, headings, user ,word_count} = response.data;
      console.log(word_count,"word_count")

      if (Generated_article && headings && word_count) {
        console.log("Hello");
        navigate(`/generatedarticle/${user}/${item.object_id}/`, {
          state: {
            content: Generated_article,
            headings,
            url_title: item.title,
            fromHistory: true, // Pass the fromHistory flag here
            word_count
          },
        });
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    }
  };
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Track item to delete

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsConfirmationModalOpen(true); // Open confirmation modal
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${itemToDelete.object_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData((prevData) =>
          prevData.filter((item) => item.object_id !== itemToDelete.object_id)
        );
        toast.success("Article deleted successfully");
      } catch (error) {
        console.error("Error deleting article:", error);
      } finally {
        setIsConfirmationModalOpen(false); // Close confirmation modal
        setItemToDelete(null); // Clear the item to delete
      }
    }
  };


  const handleExport = (item) => {
    const content = item.Generated_article;
    const title = item.title;

    if (content) {
      const converted = htmlDocx.asBlob(content);
      saveAs(converted, `${title}.docx`);
      toast.success("Article exported as Word");
    } else {
      toast.error("No content available to export.");
    }
  };
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait, your article is being generated. Do not refresh or close this page."
  );
  useEffect(() => {
    if (isGenerating) {
      const firstTimeout = setTimeout(() => {
        setLoadingMessage("Still loading... Please hold on for a moment.");
      }, 30000); // 30 seconds
      const secondTimeout = setTimeout(() => {
        setLoadingMessage(
          "Please be patient, the process is taking a bit longer than expected."
        );
      }, 60000); // 60 seconds (30 more seconds from the previous message)
      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isGenerating]);
  return (
    <>
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1003] backdrop-blur-sm">
          <div className="flex flex-col items-center gap-4">
            <FaSpinner className="animate-spin text-white text-5xl" />
            <p className="text-white text-lg">{loadingMessage}</p>
          </div>
        </div>
      )}

<ConfirmationModal
        show={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleConfirmDelete}
        title={itemToDelete?.title}
      />
      <div className="flex justify-end gap-4 mb-5">
        <button
          onClick={handleGenerateArticle}
          className="flex items-center gap-2 bg-gradient-to-r
              from-sky-500 to-sky-300 text-white px-4 py-2 rounded-full shadow-lg
              transform transition duration-200 ease-in-out hover:scale-105"
          disabled={isGenerating}
        >
          Generate and View Article
        </button>
        <button
          onClick={handleHistory}
          className="flex items-center gap-2 bg-gradient-to-r
      from-gray-500 to-gray-300 text-white px-4 py-2 rounded-full
      shadow-lg transform transition duration-200 ease-in-out hover:scale-105"
        >
          Show History
        </button>
      </div>
      {isHistoryModalOpen && (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              closeModal();
            }
          }}
          className="pointer-events-auto fixed inset-0 z-[1002]
    grid h-screen w-screen place-items-center bg-opacity-30 backdrop-blur-sm transition-opacity duration-300"
        >
          <div className="bg-white rounded-lg p-6 w-[50%] max-h-[60vh] overflow-auto shadow-lg border border-gray-300">
            <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-300">
              Article History URL
            </h2>
            {isLoadingHistory ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-2xl" />
              </div>
            ) : historyData.length > 0 ? (
              <ul className="space-y-4">
                {historyData
                  .sort(
                    (a, b) =>
                      Date.parse(b.created_at) - Date.parse(a.created_at)
                  ) // Ensuring proper date parsing
                  .filter((item) => item.kwd_flag === false) // Filter to show only items with kwd_flag === false
                  .map((item) => (
                    <li
                      key={item.object_id}
                      className="border-b pb-2 cursor-pointer flex justify-between items-center"
                    >
                      <div>
                        <h3
                          onClick={() => handleViewDetails(item)}
                          className="font-semibold"
                        >
                          {item.title}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Created At:{" "}
                          {new Date(item.created_at).toLocaleString()}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleViewDetails(item)}
                          className="text-mainColor  px-2 py-2 rounded-md  transition duration-200"
                          title="View Details"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item)} // Use new delete handler
                          className="text-red-500  px-2 py-2 rounded-md  transition duration-200"
                          title="Delete Article"
                        >
                          <FaTrash />
                        </button>
                           {/* Export Button */}
                           <button
                          onClick={() => handleExport(item)}
                          className="text-blue-500 px-2 py-2 rounded-md transition duration-200"
                          title="Export Article as Word"
                        >
                          <FaRegFileWord />
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No history found.</p>
            )}
            <button
              onClick={closeModal}
              className="flex mt-3 ml-auto px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="items-center w-full grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-3">
        <div className="col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>
        {isUrlLoading
          ? Array.from({ length: 1 }).map((_, index) => (
              <SkeletonMainKeywordInfo key={index} />
            ))
          : initialData &&
            initialData.map((item) => (
              <MainKeywordInfo
                key={item.object_id}
                url={true}
                keyword={item.title}
                wordCount={Number(item.word_count).toFixed()}
                optimizationScore={75}
              />
            ))}
      </div>
      <div className="items-center w-full md:p-5">
        <div className="flex flex-col space-y-8">
          {isUrlLoading ? (
            <SkeletonDataTable />
          ) : (
            <CompanyTabs initialData={initialData} />
          )}
          {isUrlLoading
            ? Array.from({ length: 1 }).map((_, index) => (
                <div
                  key={index}
                  className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
                >
                  <SkeletonDataTable />
                  <SkeletonDataTable />
                </div>
              ))
            : initialData &&
              initialData.map((item) => {
                let h2Data = [];
                let h3Data = [];
                try {
                  h2Data = Object.entries(
                    JSON.parse(
                      item.h2_count.replace(/\\/g, "").replace(/'/g, '"')
                    )
                  ).map(([keyword, count]) => ({ keyword, count }));
                } catch (error) {
                  console.error("Error parsing h2_count:", error);
                }
                try {
                  h3Data = Object.entries(
                    JSON.parse(
                      item.h3_count.replace(/\\/g, "").replace(/'/g, '"')
                    )
                  ).map(([keyword, count]) => ({ keyword, count }));
                } catch (error) {
                  console.error("Error parsing h3_count:", error);
                }
                return (
                  <div
                    key={item.object_id}
                    className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
                  >
                    <DataTableComponent
                      title="H2 Keywords"
                      columns={h2Columns}
                      data={h2Data}
                    />
                    <DataTableComponent
                      title="H3 Keywords"
                      columns={h3Columns}
                      data={h3Data}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};
export default InitialDataDisplay;
