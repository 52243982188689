import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchData } from '../redux/lib/fetchData';

const useEditQuery = (userId, projectId) => {
  const [editingQueryId, setEditingQueryId] = useState(null);
  const [tempQueryName, setTempQueryName] = useState("");
  const [queryNameError, setQueryNameError] = useState("");
  const [isEditInProgress, setIsEditInProgress] = useState(false);
  const [editProgress, setEditProgress] = useState(0);
  const [editError, setEditError] = useState(null);
  const dispatch = useDispatch();

  const handleEditClick = (queryId, currentQueryName) => {
    setEditingQueryId(queryId);
    setTempQueryName(currentQueryName);
  };

  const handleQueryNameChange = (e) => {
    setTempQueryName(e.target.value);
  };

  const handleUpdateQuery = async (userId, queryId, projectId, updatedData) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/UpdateNewQuery/${userId}/${queryId}/${projectId}/`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error updating query:', error);
      toast.error('An error occurred while updating the query.');
    }
  };

  const submitNewQueryName = async (queryId) => {
    if (!tempQueryName.trim()) {
      setQueryNameError("Query name cannot be empty");
      return;
    }
    setIsEditInProgress(true);
    setEditProgress(0);

    const updatedData = { query_name: tempQueryName };

    try {
      await handleUpdateQuery(userId, queryId, projectId, updatedData);
      setEditProgress(50);
      setEditProgress(100);
      dispatch(fetchData({ userId, projectId }));
      toast.success('Query updated successfully');
    } catch (error) {
      console.error("Failed to update query:", error);
      toast.error("Failed to update query");
    } finally {
      setIsEditInProgress(false);
    }

    setEditingQueryId(null);
    setTempQueryName("");
  };

  return {
    editingQueryId,
    tempQueryName,
    queryNameError,
    isEditInProgress,
    editProgress,
    handleEditClick,
    handleQueryNameChange,
    submitNewQueryName,
    setEditingQueryId,
    setEditProgress,
    editError,
  };
};

export default useEditQuery;
