import React from "react";
import Select from "react-select";
import customStyles from "../CustomStyling/customStyles";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ChangeInviteType = ({
  showInviteUser,
  setShowInviteUser,
  changeUserRole,
  setChangeUserRole,
  handleRoleChange,
  roleOptions,
  sendInvitation,
  invitationLoading,
  inviteUserID,
  handleDeleteClick,
}) => {
  const isRoleSelected = changeUserRole !== "";
  const canSubmit = isRoleSelected;

  const handleSubmit = (event) => {
    event.preventDefault();

    sendInvitation();
  };

  const handleCloseModal = () => {
    setShowInviteUser(false);
    setChangeUserRole(roleOptions[0]?.value); // Resetting the invitation details to default
  };
  console.log(roleOptions, changeUserRole, "changeUserRole");

  return (
    <>
      {showInviteUser ? (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              handleCloseModal();
            }
          }}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
        >
          <div className="w-full max-w-lg p-4 bg-white rounded-lg shadow">
            <h2 className="m-2 text-lg font-bold text-center">
              Change User Roles
            </h2>

            <form onSubmit={handleSubmit} className="space-y-4">
              <Select
                options={roleOptions}
                value={roleOptions.find(
                  (option) => option.value === changeUserRole.toString()
                )}
                onChange={handleRoleChange}
                styles={customStyles}
                isDisabled={invitationLoading}
                placeholder="Select Role"
              />

              <div className="flex justify-end space-x-4">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="px-4 py-2 text-white bg-gray-600 rounded-lg hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`px-4 py-2 text-white rounded-lg 
              bg-red-600 hover:bg-red-800
             focus:outline-none `}
                  onClick={() => handleDeleteClick(inviteUserID)}
                >
                  Delete
                </button>
                <button
                  type="submit"
                  disabled={invitationLoading || !canSubmit}
                  style={{
                    cursor:
                      invitationLoading || !canSubmit
                        ? "not-allowed"
                        : "pointer",
                    opacity: invitationLoading || !canSubmit ? 0.5 : 1,
                  }}
                  className="px-4 py-2 text-white bg-mainColor rounded-lg hover:bg-mainColor"
                >
                  {invitationLoading ? "Sending..." : "Save Changes"}
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ChangeInviteType;
