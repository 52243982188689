import { useState } from 'react';
import { toast } from 'react-toastify';

const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const fallbackCopyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        toast.info(`Copied "${text}" to clipboard successfully`, {
          position: 'bottom-center',
          autoClose: 5000
        });
        setIsCopied(true);
      } else {
        throw new Error('Copy command was unsuccessful');
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      toast.error('Failed to copy to clipboard.', {
        position: 'bottom-center',
        autoClose: 5000
      });
      setIsCopied(false);
    }

    document.body.removeChild(textArea);
  };

  const copyToClipboard = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        toast.info(`Copied "${text}" to clipboard successfully`, {
          position: 'bottom-center',
          autoClose: 5000
        });
        setIsCopied(true);
      }, (err) => {
        console.error('Error copying text to clipboard: ', err);
        toast.error('Error copying text to clipboard', {
          position: 'bottom-center',
          autoClose: 5000
        });
        setIsCopied(false);
      });
    } 
    // else {
    //   fallbackCopyTextToClipboard(text);
    // }
  };

  return { isCopied, copyToClipboard };
};

export default useCopyToClipboard;
