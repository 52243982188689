
import React from 'react';

const SkeletonDataTable = () => {
  return (
    <div className="p-4 animate-pulse  ">
      {Array.from({ length: 10 }).map((_, index) => (
        <div key={index} className="w-full h-6 bg-gray-200 rounded mb-2"></div>
      ))}
    </div>
  );
};

export default SkeletonDataTable;
