import React, { useState, useEffect, useRef } from "react";
import { BiUser } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/lib/auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import createLog from '../pages/SidebarPages/Log/createLog';
import { selectOriginalUserName } from '../redux/selectors';
import ConfirmationDialogg from "./Modal/ConfirmationDialogg";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null); // Ref for the menu container
  const userName = useSelector((state) => state.authSlice.user.name);
  const userId = useSelector(state => state?.authSlice?.user.id);
  const [openSignConfirm, setOpenSignConfirm] = useState(false);

  console.log(userId,"userId")
  const originalUser = useSelector(selectOriginalUserName);
  console.log('Logging out:', originalUser, userId);
  const OpenSignConfirm = () => {
    setOpenSignConfirm(true);
  };
  const handleLogOut = () => {
    dispatch(logOut());

    // Create a log entry for the logout action
    if (userId) {
      createLog(`Logout by ${originalUser}`, userId);
  } else {
      console.error('User ID is undefined or null. Log entry cannot be created.');
  }

    // createLog(`Logout by ${originalUser.user} `, userId);

    navigate("/login");
  };
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Function to check if clicked outside of menu
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className="relative" ref={ref}>
      <button
        className="relative flex items-center max-w-xs p-3 text-sm rounded-full bg-mainColor focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
        onClick={handleToggle}
      >
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Open user menu</span>
        <BiUser size={18} className="w-6 h-6 text-white rounded-full" />
      </button>
      {isOpen && (
        <div
          className="absolute right-0 top-[40px]  
                w-48 py-1 mt-2 origin-top-right  rounded-md bg-white  z-[1000]  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <a
            href="/account"
            className="block px-4 py-2 text-sm font-bold text-gray-700"
          >
            {userName}
          </a>
          <a
            href="#"
            className="block px-4 py-2 text-sm text-gray-700"
            onClick={(e) => {
              e.preventDefault();
              OpenSignConfirm();
            }}
          >
            Sign out
            
          </a>
        </div>
      )}
      <ConfirmationDialogg
        btnText={"Sign out"}
        message={"Are you sure you want to sign out?"}
        isOpen={openSignConfirm}
        onConfirm={handleLogOut}
        onClose={() => {
          setOpenSignConfirm(false);
        }}
      />
    </div>
  );
};

export default Profile;
