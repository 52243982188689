import React, { useEffect, useState, useRef, useCallback } from "react";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { locationOptions } from "../../../location";
import { useSelector, useDispatch } from "react-redux";
import { FaPlus, FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";
import { createBookmark } from "../../../redux/lib/createGroup";
import { fetchBookmarks } from "../../../redux/lib/displayBookmarks";

const RankModal = ({
  tableData,
  filteredData,
  isModalOpen,
  closeModal,
  onSubmit,
  isDataLoading,
  projectDetails,
  // selectedLocation,
  // setSelectedLocation,
  // handleLocationChange,
  roles,
}) => {
  const dispatch = useDispatch();
  const bookmarks = useSelector(
    (state) => state.displayBookmarkSlice.bookmarks
  );
  const managed = useSelector((state) => state.authSlice.managed);
  const userId = useSelector((state) => state.authSlice.userId);
  const [bookmarkOptions, setBookmarkOptions] = useState([]);
  const [keywordCount, setKeywordCount] = useState(0);
  const [keywordsText, setKeywordsText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [bookmarkError, setBookmarkError] = useState("");
  const textareaRef = useRef(null);
  const lineNumbersRef = useRef(null);
  const [url, setUrl] = useState(projectDetails?.url || "");
  const [errors, setErrors] = useState({});
  const [confirmRepeat, setConfirmRepeat] = useState(false);
  const [repeatKW, setRepeatKW] = useState([]);
  const [repeatKWUrl, setRepeatKWUrl] = useState([]);
  const [beSure, setBeSure] = useState(false);
  const [selectedBookmark, setSelectedBookmark] = useState(null);
  const [isCreatingBookmark, setIsCreatingBookmark] = useState(false);
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const [isBookmarkCreating, setIsBookmarkCreating] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const selectRef = useRef(null);
  const handleLocationChange = (e) => {
    setSelectedLocation(e);
    console.log(e,'dscdvcsdvsvav');
    
  };

  const fetchAllBookmarks = useCallback(async () => {
    if (projectDetails?.project_id) {
      await dispatch(fetchBookmarks({ projectId: projectDetails.project_id }));
    }
  }, [dispatch, projectDetails?.project_id]);

  useEffect(() => {
    fetchAllBookmarks();
  }, [fetchAllBookmarks]);

  useEffect(() => {
    const updatedBookmarkOptions = bookmarks.map((bookmark) => ({
      value: bookmark.b_id,
      label: bookmark.name,
    }));
    setBookmarkOptions(updatedBookmarkOptions);
  }, [bookmarks]);

  const handleCreateBookmark = async () => {
    if (newBookmarkName.trim() === "") {
      return { bookmark: "Bookmark name cannot be empty." };
    }

    const nameExists = bookmarks.some(
      (bookmark) =>
        bookmark.name.toLowerCase() === newBookmarkName.toLowerCase()
    );

    if (nameExists) {
      return { bookmark: "Bookmark name already exists." };
    }

    const newBookmarkData = {
      name: newBookmarkName,
      projectId: projectDetails?.project_id,
    };

    try {
      const createdBookmark = await dispatch(
        createBookmark(newBookmarkData)
      ).unwrap();

      if (!createdBookmark.b_id) {
        throw new Error("Bookmark ID (b_id) is missing from the response");
      }

      const newBookmarkOption = {
        value: createdBookmark.b_id,
        label: createdBookmark.name,
      };
      setBookmarkOptions((prevOptions) => [...prevOptions, newBookmarkOption]);
      setSelectedBookmark(newBookmarkOption.value);

      return newBookmarkOption;
    } catch (error) {
      console.error("Failed to create bookmark:", error);
      toast.error(`Failed to create bookmark: ${error.message}`);
      return { bookmark: `Failed to create bookmark: ${error.message}` };
    }
  };

  const updateKeywordCount = (text) => {
    const keywords = text
      .split("\n")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword.length > 0);
    setKeywordCount(keywords.length);
  };

  const handleKeywordsChange = (event) => {
    setBeSure(false);
    const text = event.target.value;
    setKeywordsText(text);
    updateKeywordCount(text);
    setErrorMessage("");

    const lines = text.split("\n");
    const hasInvalidKeyword = lines.some((line) => {
      const trimmedLine = line.trim();
      return (
        trimmedLine === "" ||
        trimmedLine === '""' ||
        trimmedLine === "''" ||
        /^\s*$/.test(trimmedLine)
      );
    });

    if (hasInvalidKeyword) {
      setErrorMessage(
        "Keywords cannot be empty, contain only spaces, or contain only quotes."
      );
      return;
    }

    let errorFound = false;
    lines.forEach((line) => {
      const words = line.split(/\s+/);
      if (words.length > 6) {
        errorFound = true;
      }
    });

    if (errorFound) {
      setErrorMessage("Each line must contain no more than six words.");
    }

    setErrors({});
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const lines = pastedText.split(/\r?\n/);

    const filteredLines = lines.filter((line) => line.trim().length > 0);
    const formattedText = filteredLines.join("\n");

    setKeywordsText((prev) => {
      let newText =
        prev.endsWith("\n") || prev === ""
          ? prev + formattedText
          : prev + "\n" + formattedText;

      const uniqueLines = Array.from(new Set(newText.split("\n")));
      newText = uniqueLines.join("\n");
      updateKeywordCount(newText);

      return newText;
    });

    setErrorMessage("");
    setErrors({});
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const uniqueLines = Array.from(new Set(keywordsText.split("\n")));
      const newText = uniqueLines.join("\n");

      setKeywordsText(newText);
      updateKeywordCount(newText);

      const lines = keywordsText.split("\n");
      const currentLine = lines[lines.length - 1].trim();

      if (currentLine.length === 0) {
        event.preventDefault();
        setErrorMessage("Each line must contain a keyword.");
      } else {
        setErrorMessage("");
      }
      setErrors({});
    }
  };

  const validateForm = () => {
    const validationErrors = {};
    if (!url) validationErrors.url = "URL is required";
    if (!keywordsText) validationErrors.keywords = "Keywords are required";
    if (!selectedLocation) validationErrors.location = "Location is required";
    return validationErrors;
  };

  const checkKW = () => {
    console.log("data");

    const hasMatchingKeyword = filteredData.some((data) =>
      repeatKW.includes(data.query)
    );
    console.log(hasMatchingKeyword, "data");

    if (hasMatchingKeyword) {
      const matchingUrls = filteredData
        .filter((data) => repeatKW.includes(data.query))
        .map((data) => data.target_url);

      setRepeatKWUrl((prevUrls) => [...prevUrls, ...matchingUrls]);
      setConfirmRepeat(true);
    } else {
      handleFormSubmit();
    }
  };

  const handleFormSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    let bookmarkId = selectedBookmark?.value || 0;

    if (isCreatingBookmark) {
      const response = await handleCreateBookmark();
      if (response.bookmark) {
        setErrors(response.bookmark);
        return;
      }
      bookmarkId = response?.value || "";
    }

    const keywords = keywordsText
      .split("\n")
      // .map((keyword) => keyword.trim())
      .map((keyword) =>
        keyword.trim().replace(/['"]/g, "") // Remove both single and double quotes
      )
      .filter(
        (keyword) =>
          keyword.length > 0 &&
          keyword !== '""' &&
          keyword !== "''" &&
          !/^\s*$/.test(keyword)
      );

    if (keywords.length === 0) {
      setErrors({ keywords: "No valid keywords provided" });
      return;
    }

    if (keywords.length > 1 && managed && !roles.Add_bulk_query) {
      setErrorMessage("You do not have permission to add bulk queries.");
      return;
    }

    const formData = {
      url,
      keywords: keywords.join("\n"),
      bookmark: bookmarkId,
      location: selectedLocation?.value || "",
    };
    onSubmit(formData);
    closeModal();
    resetForm();
    setConfirmRepeat(false);
  };

  const handleScroll = () => {
    if (lineNumbersRef.current && textareaRef.current) {
      lineNumbersRef.current.scrollTop = textareaRef.current.scrollTop;
    }
  };

  const resetForm = () => {
    setUrl(projectDetails?.url || "");
    setKeywordCount(0);
    setKeywordsText("");
    setErrors({});
    setErrorMessage("");
    setSelectedBookmark(null);
    setIsCreatingBookmark(false);
    setBookmarkError("");
  };

  const closeRepeat = () => {
    if (repeatKW.length === 1 && repeatKWUrl.length === 1) {
      setConfirmRepeat(false);
      closeModal();
    } else {
      const updatedRepeatKW = repeatKW.filter((keyword) =>
        filteredData.some((data) => data.query.includes(keyword))
      );
      const newText = keywordsText
        .split("\n")
        .filter(
          (word) => !updatedRepeatKW.some((kw) => word.trim() === kw.trim())
        )
        .join("\n");
      setKeywordsText(newText);
      updateKeywordCount(newText);
    }
    setBeSure(true);
    setConfirmRepeat(false);
    setRepeatKWUrl([]);
    setRepeatKW([]);
    setErrorMessage("");
  };

  useEffect(() => {
    if (isModalOpen) resetForm();
  }, [isModalOpen, projectDetails]);

  useEffect(() => {
    const lines = keywordsText
      .split("\n")
      .filter((line) => line.trim().length > 0);
    setRepeatKW(lines);
  }, [keywordsText]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) textarea.addEventListener("paste", handlePaste);
    return () => {
      if (textarea) textarea.removeEventListener("paste", handlePaste);
    };
  }, [isModalOpen]);

  const renderLineNumbers = () => {
    const lines = keywordsText.split("\n").length;
    return Array.from({ length: Math.max(lines, 1) }, (_, i) => i + 1).join(
      "\n"
    );
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps, selectRef } = props;
    if (data.__isNew__) {
      return (
        <button
          type="button"
          ref={innerRef}
          {...innerProps}
          className="cursor-pointer w-full p-2 text-center flex items-center justify-center"
          onClick={() => {
            props.selectProps.setIsCreatingBookmark(true);
            selectRef.current.blur();
          }}
        >
          <FaPlus className="mr-2" /> Create Bookmark
        </button>
      );
    }

    return <components.Option {...props}>{data.label}</components.Option>;
  };

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <div>{props.children}</div>
      </components.MenuList>
    );
  };
  return (
    <>
      {isModalOpen && (
        <div
          className="pointer-events-auto fixed inset-0 z-[1002] grid h-screen w-screen place-items-center 
          bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              closeModal();
              resetForm();
            }
          }}
        >
          <div
            className="w-full max-w-lg p-4 bg-white rounded-lg shadow-lg"
            onClick={(event) => event.stopPropagation()}
          >
            <div className="bg-mainColor/20 border-b rounded-none border-mainColor shadow-none z-[10] p-2 text-center">
              <h1 className="font-semibold">Single or Bulk keyword</h1>
            </div>
            <div className="px-[10px] py-[30px]">
              <form>
                <div className="relative w-full">
                  <div className="absolute top-0 left-0 h-full overflow-hidden pointer-events-none py-1">
                    <pre
                      ref={lineNumbersRef}
                      className="h-full overflow-hidden text-right text-gray-400 px-3 py-2 whitespace-pre-wrap"
                    >
                      {renderLineNumbers()}
                    </pre>
                  </div>
                  <textarea
                    name="keywords"
                    placeholder="Enter Single or Bulk keywords"
                    className={`w-full h-full py-1 mt-1 border-b ${
                      errors.keywords ? "border-red-500" : "border-gray-300"
                    } pl-10 overflow-auto focus:outline-none`}
                    onChange={handleKeywordsChange}
                    value={keywordsText}
                    ref={textareaRef}
                    onKeyDown={handleKeyDown}
                    onScroll={handleScroll}
                    style={{ whiteSpace: "pre-wrap" }}
                    rows={3}
                  />
                </div>
                {errors.keywords && (
                  <p className="text-red-500">{errors.keywords}</p>
                )}
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                <p className="mt-3">{`Selected Keywords: ${keywordCount}`}</p>

                <div className="grid grid-cols-2 gap-2 items-start justify-between">
                  <div className="flex flex-col w-full space-y-1">
                    <Select
                      components={makeAnimated()}
                      options={locationOptions}
                      value={selectedLocation}
                      onChange={(e) => {
                        handleLocationChange(e);

                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          location: "",
                        }));
                      }}
                      className={`w-[100%] mt-3 focus:outline-none ${
                        errors.location ? "border-red-500" : ""
                      }`}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 8px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                          padding: "1px",
                          marginLeft: "4px",
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "150px",
                          overflowY: "auto",
                        }),
                      }}
                      isSearchable={false}
                      getOptionLabel={(option) => (
                        <>
                          {option.image && (
                            <span className="w-6 mr-2">
                              <img src={option.image} alt={option.label} />
                            </span>
                          )}
                          {option.label}
                        </>
                      )}
                      getOptionValue={(option) => option.value}
                    />
                    {errors.location && (
                      <p className="text-red-500">{errors.location}</p>
                    )}
                  </div>

                  <div className="flex flex-col w-full space-y-1">
                    <Select
                      options={[
                        ...bookmarkOptions,
                        {
                          value: "create-new",
                          label: "Create New Bookmark",
                          __isNew__: true,
                        },
                      ]}
                      value={selectedBookmark}
                      onChange={(e) => {
                        if (e.value === "create-new") {
                          setIsCreatingBookmark(true);
                          setSelectedBookmark(null);
                        } else {
                          setSelectedBookmark(e);
                        }
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          bookmark: "",
                        }));
                        setBookmarkError("");
                      }}
                      onMenuClose={() => {
                        if (isCreatingBookmark) {
                          document.querySelector(".react-select__menu")?.blur();
                        }
                      }}
                      className={`w-[100%] mt-3 focus:outline-none ${
                        errors.bookmark ? "border-red-500" : ""
                      }`}
                      styles={{
                        option: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                          padding: "4px 8px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                          padding: "1px",
                          marginLeft: "4px",
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "150px",
                          overflowY: "auto",
                        }),
                      }}
                      components={{
                        Option: (props) => (
                          <CustomOption
                            {...props}
                            selectRef={selectRef}
                            selectProps={{ setIsCreatingBookmark }}
                          />
                        ),
                        MenuList: CustomMenuList,
                      }}
                      isSearchable={false}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      ref={selectRef}
                    />
                    {errors.bookmark && (
                      <p className="text-red-500">{errors.bookmark}</p>
                    )}
                    {bookmarkError && (
                      <p className="text-red-500">{bookmarkError}</p>
                    )}
                  </div>
                </div>

                {isCreatingBookmark && (
                  <div className="relative mt-3">
                    <input
                      type="text"
                      placeholder="Bookmark name"
                      className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none"
                      value={newBookmarkName}
                      onChange={(e) => {
                        setNewBookmarkName(e.target.value);
                        if (errors.bookmark) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            bookmark: "",
                          }));
                        }
                      }}
                    />
                    {isBookmarkCreating && (
                      <span className="absolute right-4 top-1/2 transform -translate-y-1/2 animate-spin text-mainColor">
                        <FaSpinner />
                      </span>
                    )}
                    {errors.bookmark && (
                      <p className="text-red-500">{errors.bookmark}</p>
                    )}
                  </div>
                )}

<button
  onClick={checkKW}
  type="button"
  className={`px-4 py-2 rounded w-full mt-4 font-semibold bg-mainColor text-white flex justify-center items-center ${
    isDataLoading ? "cursor-not-allowed opacity-50" : "cursor-pointer"
  }`}
  disabled={isDataLoading} // Disable button while data is loading
>
  {isDataLoading ? (
       <>
       <FaSpinner className="animate-spin mr-2" />
       Submitting...
     </>
  ) : (
    "Submit Keywords"
  )}
</button>




                {confirmRepeat && (
                  <div
                    className="pointer-events-auto fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
                    onClick={(event) => {
                      if (event.target === event.currentTarget) {
                        closeRepeat();
                      }
                    }}
                  >
                    <div
                      className="w-full max-w-lg p-4 bg-white rounded-lg shadow-lg"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <div className="bg-mainColor/20 border-b rounded-none border-mainColor shadow-none z-[10] p-2 text-center">
                        <h1 className="font-semibold">Note</h1>
                      </div>
                      <div className="px-[10px] py-[30px]">
                        {repeatKW.length !== 0 && (
                          <>
                            {repeatKW.length === 1 ? (
                              <p>
                                You have entered a keyword "{repeatKW}" that has
                                been added before with url "{repeatKWUrl}", so
                                it will be updated and not added
                              </p>
                            ) : (
                              <p>
                                You have entered keywords that have been added,
                                so they will be updated and not added. If you
                                need to remove these keywords, click on the{" "}
                                <span className="text-red-600">cancel</span>{" "}
                                button.
                              </p>
                            )}
                          </>
                        )}
                        <div className="flex items-centqer justify-center gap-3 pt-8 ">
                          <button
                            type="button"
                            className="px-4 py-2 text-white bg-red-700 rounded-md"
                            onClick={() => {
                              closeRepeat();
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="px-4 py-2 text-white rounded-md bg-mainColor"
                            onClick={handleFormSubmit}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RankModal;
