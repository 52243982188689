import React from 'react';

const FilterSkeleton = () => {
  return (
    <div className='flex flex-col w-full xl:flex-row animate-pulse space-x-4'>
      <div className='flex flex-1 items-center space-x-4 p-4'>
        <div className="w-full">
          <div className="h-6 bg-gray-200 rounded"></div>
          <div className="h-10 mt-3 bg-gray-200 rounded"></div>
        </div>
        <div className="w-full">
          <div className="h-6 bg-gray-200 rounded"></div>
          <div className="h-10 mt-3 bg-gray-200 rounded"></div>
        </div>
      </div>
      <div className='flex flex-1 items-center space-x-4 p-4'>
        <div className="w-full">
          <div className="h-10 bg-gray-200 rounded mt-10"></div>
        </div>
        <div className="w-full">
          <div className="h-10 bg-gray-200 rounded mt-10"></div>
        </div>
      </div>
    </div>
  );
};

export default FilterSkeleton;
