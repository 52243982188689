// features/favorites/favoritesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const fetchFavorites = createAsyncThunk(
  'bookmarks/fetchFavorites',
  async ({ userId, bookmarkId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/DisplayFavos/${userId}/${bookmarkId}/`);
      return response.data; // This data will be automatically handled by the extraReducers in your slice
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add the extraReducers to handle the thunk in your bookmarks slice

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    favorites: [],
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {
   
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavorites.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.favorites = action.payload;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default favoritesSlice.reducer;


export const { resetFavorites } = favoritesSlice.actions; // Add this line

