import React, { useEffect } from "react";
import RankRangeComponent from "./RankRangeComponent";
import ChartComponent from "./ChartComponent";
import ActivityPieComponent from "./ActivityPieComponent";
import ProjectDetailsComponent from "./ProjectDetailsComponent";

const ProjectHeader = ({
  projectDetails,
  seriesData,
  filteredData,
  openDateFilter,
  startDate,
  endDate,
  customDateChart,
  setCustomDateChart
}) => {

  const firstDate =customDateChart?startDate.getTime(): new Date(new Date().setDate(new Date().getDate() - 5)).getTime();
  const lastDate = customDateChart?endDate.getTime():new Date().getTime();
  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      zoom: {
        enabled: true,
        type: 'x',  // Allow zooming on the x-axis only
      },
      toolbar: {
        show: true, // Show the toolbar to enable zoom controls
        tools: {
          zoom: true,
          zoomin: true,
          zoomout: true,
          reset: true, // Add a reset button to zoom out
          pan: false, // Disable panning
          selection: false, // Disable selection zoom
          zoomselection: false, // Explicitly disable selection zoom
        },
      },
    },
    colors: ["#2BA950"],
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd MMM",
      },
      min: firstDate,
      max: lastDate,
    },
    yaxis: {
      reversed: true,
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },
      },
      title: {
        text: "Average Rank",
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
      y: {
        formatter: function (value) {
          return `Rank: ${Math.round(value)}`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return Math.round(value);
      },
    },
  };
  
  
  
  return (
    <div
      className="w-full p-4 m-auto mt-4 animate-fade-in-down items-stretch grid
     xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3"
    >
      <RankRangeComponent tableData={filteredData} />
      <ProjectDetailsComponent projectDetails={projectDetails} />
      <ActivityPieComponent tableData={filteredData} />
      <ChartComponent
        chartOptions={chartOptions}
        seriesData={seriesData}
        openDateFilter={openDateFilter}
        setCustomDateChart={setCustomDateChart}
      />
    </div>
  );
};

export default ProjectHeader;
