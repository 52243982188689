import React, { useState } from "react";
import Select, { components } from "react-select";
import { Menu, Transition } from "@headlessui/react";
import { FaTrash, FaEllipsisV, FaEdit, FaSpinner } from "react-icons/fa";

const BookmarkSelector = ({
  bookmarkOptions,
  selectedBookmark,
  handleBookmarkChange,
  showBookmarkSelector,
  toggleBookmarkSelector,
  filteredBookmarks,
  handleAddToBookmark,
  bookmarkNames,
  setBookmarkNames,
  handleRenameBookmark,
  handleDeleteBookmark,
  userId,
  projectId,
  isCreatingBookmark,
  setIsCreatingBookmark,
  newBookmarkName,
  setNewBookmarkName,
  handleCreateBookmark,
  editingBookmarkId,
  setEditingBookmarkId,
  isBookmarkCreating,
  bookmarkSelectorRef,
  handleKeyPress,
  customSelectStyles,
  setIsBookmarkCreating,

}) => {
  const CustomOption = (props) => {
    const { innerRef, innerProps, data } = props;
    return (
      <button
        ref={innerRef}
        {...innerProps}
        className="w-full text-left px-3 py-1 mb-2 hover:bg-sky-100"
      >
        {data.label}
      </button>
    );
  };

  const CustomMenuList = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <button
          onClick={toggleBookmarkSelector}
          className="font-bold px-4 py-1
           w-full text-sm bg-gray-100"
        >
          {props.options.length > 0
            ? "create or edit favourite"
            : "create favourite"}
        </button>
      </components.MenuList>
    );
  };

  const NoOptionsMessage = (props) => {
    return (
      <div className="w-full flex flex-col items-center justify-center gap-2">
        <span>No options found.</span>
      </div>
    );
  };

  return (
    <>
      <Select
        className="z-[900] w-full  
        inline-block

        "
        value={selectedBookmark}
        onChange={handleBookmarkChange}
        options={bookmarkOptions}
        placeholder="Select a favourite to filter"
        isClearable={true}
        isSearchable={true}
        styles={customSelectStyles}
        components={{
          Option: CustomOption,
          MenuList: CustomMenuList,
          NoOptionsMessage,
        }}
      />

      {showBookmarkSelector && (
        <div className="relative" ref={bookmarkSelectorRef}>
          <div
            onClick={(event) => {
              if (event.target === event.currentTarget) {
                toggleBookmarkSelector();
              }
            }}
            className="pointer-events-auto fixed inset-0 z-[999] grid h-screen 
            w-screen place-items-center bg-opacity-10 opacity-100 bg-white 
            backdrop-blur-sm transition-opacity duration-300"
          >
            <div className="w-full max-w-lg p-2 bg-white rounded-lg boxing">
              <div className="overflow-x-auto h-[300px]">
                {filteredBookmarks.length > 0 ? (
                  filteredBookmarks.map((bookmark) => (
                    <div
                      key={bookmark.b_id}
                      className="flex justify-between p-2 cursor-pointer hover:bg-gray-200"
                    >
                      <div
                        className="text-sm text-left 
                        w-full"
                        onClick={() => {
                          handleAddToBookmark(bookmark.b_id);
                          toggleBookmarkSelector();
                        }}
                      >
                        {editingBookmarkId === bookmark.b_id ? (
                          <input
                            type="text"
                            value={
                              bookmarkNames[bookmark.b_id] ?? bookmark.name
                            }
                            onChange={(e) =>
                              setBookmarkNames({
                                ...bookmarkNames,
                                [bookmark.b_id]: e.target.value,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleRenameBookmark(
                                  bookmark.b_id,
                                  bookmarkNames[bookmark.b_id],
                                  projectId
                                );
                                setEditingBookmarkId(null);
                              }
                            }}
                            onBlur={() => setEditingBookmarkId(null)}
                            autoFocus
                            className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none"
                          />
                        ) : (
                          <span className="text-sm text-left">
                            {bookmark.name}
                          </span>
                        )}
                      </div>
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <Menu.Button as="button" className="p-1">
                          <FaEllipsisV className="text-lg" />
                        </Menu.Button>
                        <Transition>
                          <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md w-36 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      setEditingBookmarkId(bookmark.b_id)
                                    }
                                    className={`flex items-center w-full px-2 py-2 text-sm text-gray-900 rounded-md`}
                                  >
                                    <FaEdit
                                      className="mr-2"
                                      aria-hidden="true"
                                    />
                                    Rename
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() =>
                                      handleDeleteBookmark(
                                        userId,
                                        bookmark.b_id,
                                        projectId
                                      )
                                    }
                                    className="flex items-center w-full px-2 py-2 text-sm text-gray-900 rounded-md"
                                  >
                                    <FaTrash
                                      className="mr-2"
                                      aria-hidden="true"
                                    />
                                    Delete
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ))
                ) : (
                  <div className="p-2 text-sm text-center text-gray-500">
                    No bookmarks found.
                  </div>
                )}
              </div>
              <div className="w-full max-w-lg p-4 bg-white rounded-lg">
                {isCreatingBookmark ? (
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Bookmark name"
                      className="w-full p-2 text-sm text-gray-700 border border-gray-300 rounded-md focus:outline-none"
                      value={newBookmarkName}
                      onChange={(e) => setNewBookmarkName(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    {isBookmarkCreating ? (
                      <span className="absolute right-4 top-1/2 transform -translate-y-1/2 animate-spin text-mainColor">
                        <FaSpinner />
                      </span>
                    ) : (
                      <button
                        onClick={() => {
                          setIsBookmarkCreating(true);
                          handleCreateBookmark().finally(() => {
                            setIsBookmarkCreating(false);
                          });
                        }}
                        className="absolute top-0 right-0 h-full px-4 text-white bg-mainColor rounded-r-md"
                      >
                        Enter
                      </button>
                    )}
                  </div>
                ) : (
                  <div
                    className="p-2 text-sm font-bold text-center text-black transition-colors duration-200 ease-in-out bg-white cursor-pointer"
                    onClick={() => setIsCreatingBookmark(true)}
                  >
                    + create favourite
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookmarkSelector;
