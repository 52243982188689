import React from 'react';
import { FiEye, FiEyeOff } from "react-icons/fi";

const PasswordInput = ({ showPassword, togglePasswordVisibility, name, placeholder, value, onChange }) => (
  <div className="relative flex items-center w-full mb-3">
    <input
      type={showPassword ? "text" : "password"}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full p-3 pr-10 border rounded focus:outline-none focus:border-basegray  focus:border-2"

      required
    />
    <button
      type="button"
      onClick={togglePasswordVisibility}
      className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-600"
    >
      {showPassword ? <FiEye /> : <FiEyeOff />}
    </button>
  </div>
);

export default PasswordInput;