import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const ExportButton = ({ onClick, isLoading }) => {

  const handleClick = async () => {
    await onClick();
  };

  return (
    <div className="export-to-excel">
      <button
        onClick={handleClick}
        className={`w-full mr-2 bg-mainColor px-4 py-2 h-[40px] hover:bg-greeng  ${isLoading ? "cursor-not-allowed":''}
         text-white rounded-lg transition duration-150 ease-in-out lg:text-sm text-xs font-bold hidden lg:flex items-center justify-center`}
      >
        {isLoading ? (
          <FaSpinner className="animate-spin" />
        ) : (
          "Export to Excel"
        )}
      </button>
      <button
        onClick={handleClick}
        className={`w-full mr-2 bg-mainColor p-2 h-[40px] ${isLoading ? "cursor-not-allowed":''}
         hover:bg-greeng text-white rounded-lg transition duration-150 ease-in-out lg:text-sm text-xs font-bold  lg:hidden flex items-center justify-center`}
      >
        {isLoading ? (
          <FaSpinner className="animate-spin" />
        ) : (
          "Export"
        )}
      </button>
    </div>
  );
};

export default ExportButton;
