import React from "react";

// Component to display project details
const ProjectDetails = ({ projectDetails }) => {
  const projectData = [
    {
      title: "Project",
      data: projectDetails?.name,
    },
    {
      title: "Url",
      data: projectDetails?.url,
    },
    {
      title: "Created at",
      data: projectDetails?.created_at
        ? new Date(projectDetails.created_at).toISOString().split("T")[0]
        : "N/A",
    },
  ];
  return (
    <div
      className="flex flex-col  w-full p-4  transition duration-500 transform bg-white dark:bg-slate border-2
     border-gray-200 dark:border-gray-500 rounded-lg  "
    >
      {/* Header */}
      <h1 className="mb-2 text-lg font-semibold text-gray-700 dark:text-gray-300">
        Project Details
      </h1>
      {/* data */}
      <div className="mt-[20px] w-full flex flex-col gap-3 m-auto ">
        {projectData.map((project, index) => (
          <div
            className="flex flex-col w-full transition duration-500 transform  "
            key={index}
          >
            <div className="w-full">
              <span className="text-sm font-semibold text-gray-500 dark:text-gray-100 capitalize">
                {project.title}
              </span>
              <p className="font-bold capitalize text-md text-greeng dark:text-gray-500">
                {project.data}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectDetails;
