import React from 'react';

const SkeletonCard = () => {
  return (
    <div className="w-full sm:w-[48%] lg:w-[32%] m-1 p-4 shadow-none border-2 border-gray-200 rounded-2xl mt-6 animate-pulse">
      <div className="h-6 bg-gray-300 rounded w-3/4 mb-4"></div>
      <div className="flex justify-between border-b-2 border-gray-200 pb-4 mb-4">
        <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
        <div className="w-6 h-6 bg-gray-300 rounded-full"></div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-1/2 h-48 bg-gray-300 rounded mb-4 md:mb-0"></div>
        <div className="flex flex-col items-center justify-center w-full md:w-1/2 space-y-2">
          <div className="w-1/2 h-4 bg-gray-300 rounded"></div>
          <div className="w-1/3 h-4 bg-gray-300 rounded"></div>
          <div className="w-20 h-8 bg-gray-300 rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
