// utils.js
export const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options) + " at ".split(" ")[0];
  };
  
  export const extractDomainName = (url) => {
    try {
      const parsedUrl = new URL(
        `${url.startsWith("http") ? url : `https://${url}`}`
      );
      let domainName = parsedUrl.hostname;
      if (domainName.length > 15) {
        return (
          <span className="pointer">
            {`${domainName.slice(0, 15)}...`}
            <div className="tooltipcontainer hover:w-auto w-full absolute top-0 right-0">
              <span className="tooltiptext">{domainName}</span>
            </div>
          </span>
        );
      }
      return domainName;
    } catch (error) {
      console.error("Invalid URL:", url, error);
      return url.length > 20 ? `${url.slice(0, 20)}...` : url;
    }
  };
  
  export const sortCards = (cards) => {
    console.log(cards.sort((a, b) => {
      if (a.isPinned === b.isPinned) {
        return 0; // If both are either pinned or unpinned, no change in order
      }
      return a.isPinned ? -1 : 1; // Pinned projects should come first
    }),'sortCards(fetchedProjects)');
    
    return cards.sort((a, b) => {
      if (a.isPinned === b.isPinned) {
        return 0; // If both are either pinned or unpinned, no change in order
      }
      return a.isPinned ? -1 : 1; // Pinned projects should come first
    });
  };
  