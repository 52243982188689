import React from "react";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon

const AddQueryButton = ({ onClick, onMouseEnter, onMouseLeave, roles, isDataLoading }) => {
  return (
    <div>
      <button
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`flex items-center justify-center w-full mr-2 text-xs h-[40px] font-bold
          text-white transition duration-150 ease-in-out 
          rounded-lg bg-mainColor hover:bg-greeng lg:text-sm ${
            isDataLoading ? "cursor-not-allowed opacity-50" : ""
          }`}
        disabled={isDataLoading} // Disable the button if data is loading
      >
        {isDataLoading ? (
          <div className="px-3 flex items-center">
            <FaSpinner className="animate-spin mr-2" />
            <span className="hidden lg:block"> Adding...</span>
          </div>
        ) : (
          <>
            <span className="hidden h-auto px-4 py-4 text-center text-white lg:block">
              Add New Query
            </span>
            <span
              className="w-full bg-mainColor p-2 
              hover:bg-greeng text-white rounded-lg transition duration-150 ease-in-out lg:text-sm text-xs font-bold block lg:hidden"
            >
              Add
            </span>
          </>
        )}
      </button>
    </div>
  );
};

export default AddQueryButton;
