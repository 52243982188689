import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { FaSpinner } from 'react-icons/fa';
import { resetPasswordSchema } from '../utils/validation';
import PasswordRequirements from '../components/password/PasswordRequirements';
const ResetPassword = () => {
  const navigate = useNavigate();
  const { uid, token } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [initialView, setInitialView] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(resetPasswordSchema),
  });
  const password = watch("password");

  useEffect(() => {
    checkPasswordStrength(password);
    setInitialView(password?.length === 0);
  }, [password]);

  const checkPasswordStrength = (password) => {
    setPasswordStrength({
      length: password?.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[^A-Za-z0-9]/.test(password),
    });
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password/${uid}/${token}/`, {
        new_password: data.password
      });
      const { message } = response.data;

      toast.success(message);
      navigate("/login");
    } catch (error) {
      toast.error("Password reset failed. Please try again.");
      console.error("Password reset error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full max-w-md p-6 mx-auto mt-20 rounded-lg shadow-custom sm:mt-10">
      <h2 className="mb-3 text-2xl font-bold text-center text-mainColor">Reset Password</h2>
      <p className="mt-4 mb-3 text-md">Enter your new password below.</p>

      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">New Password:</label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            {...register("password")}
            className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
          >
            {showPassword ? <HiEyeOff /> : <HiEye />}
          </button>
        </div>
        {errors.password && <p className="mt-1 text-xs text-red-500">{errors.password.message}</p>}
      </div>

      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">Confirm New Password:</label>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            {...register("confirmPassword")}
            className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
          >
            {showConfirmPassword ? <HiEyeOff /> : <HiEye />}
          </button>
        </div>
        {errors.confirmPassword && <p className="mt-1 text-xs text-red-500">{errors.confirmPassword.message}</p>}
      </div>

      <PasswordRequirements
        passwordStrength={passwordStrength}
        initialView={initialView}
      />
      <button
        type="submit"
        disabled={isLoading}
        className={`px-4 py-2 mt-5 font-bold text-white rounded transition-colors ${isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-mainColor hover:bg-mainColor-700'
          }`}
      >
        {isLoading ? (
          <>
            <FaSpinner className="inline-block animate-spin mr-2" />
            Resetting Password...
          </>
        ) : (
          'Reset Password'
        )}
      </button>
    </form>
  );
};

export default ResetPassword;
