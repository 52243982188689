import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import LinksTable from "../KeywordInfo/LinksTable";
import CompanyTabs from "../../../../components/keywordresearch/components/url/CompanyTabs";
import DataTableComponent from "../../../../components/keyword_research/keyword/DataTableComponent";
import {
  FaSync,
  FaChevronDown,
  FaExternalLinkAlt,
  FaChevronUp,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import SkeletonTabs from "../../../../components/Skeleton/SkeletonTabs";
import SkeletonDataTable from "../../../../components/Skeleton/SkeletonDataTable";

const MainContent = ({
  isLoading,
  isKeywordDataLoading,
  isUrlDataLoading,
  handleRefresh,
  columns,
  data,
  keywordData,
  urlData,
  combinedH2H3DataArray,
  combinedH2H3Columns,
  keywordData_Array,
  columns_,
  openIndex,
  handleAccordionToggle,
  createMarkup,
  extractDomain,
  handleQueryClick,
  url_Data,
  columns_URL,
  RequestSuccess,
}) => {
  console.log(RequestSuccess, "RequestSuccess");

  const [activeTab, setActiveTab] = useState("tab1");

  const h2Columns = [
    { name: "Keyword", selector: (row) => row.keyword, sortable: true },
    { name: "Count", selector: (row) => row.count, sortable: true },
  ];
  const h3Columns = [
    { name: "Keyword", selector: (row) => row.keyword, sortable: true },
    { name: "Count", selector: (row) => row.count, sortable: true },
  ];

  // Extract H2 and H3 data arrays from urlData
  const h2DataArray = urlData?.H2
    ? Object.entries(urlData.H2).map(([keyword, count]) => ({ keyword, count }))
    : [];
  const h3DataArray = urlData?.H3
    ? Object.entries(urlData.H3).map(([keyword, count]) => ({ keyword, count }))
    : [];

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleClearFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setFilteredData(data);
    setSearchTerm("");
  };
  const customTableStylesDark = {
    table: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
        borderColor: "#6b7280",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
      },
    },
    headCells: {
      style: {
        color: "#fff",
        backgroundColor: "#111417",
      },
    },
    rows: {
      style: {
        backgroundColor: "#111417",
        color: "#fff",
        "&:nth-of-type(odd)": {
          backgroundColor: "#000",
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "#333",
        color: "#fff",
      },
    },
  };

  const customTableStylesLight = {
    table: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#fff",

        color: "#000",
      },
    },
    rows: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
        "&:nth-of-type(odd)": {
          backgroundColor: "#f0f0f0",
        },
      },
    },
    pagination: {
      style: {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
  };
  const isDarkMode = localStorage.getItem("darkMode") === "true";
  const customTableStyles = isDarkMode
    ? customTableStylesDark
    : customTableStylesLight;

  return (
    <>
      {!keywordData && !urlData && (
        <>
          {isLoading && !RequestSuccess ? (
            <SkeletonTabs />
          ) : (
            <Tabs
              value={activeTab}
              className="max-w-screen-3xl mx-auto py-4 md:mt-10 mt-16 "
            >
              <TabsHeader
                className="rounded-none border-b border-mainColor-gray-50 font-semibold bg-transparent p-0 dark:text-gray-300"
                indicatorProps={{
                  className:
                    "bg-transparent border-b-2 border-mainColor shadow-none rounded-none",
                }}
              >
                <Tab
                  key="tab1"
                  value="tab1"
                  onClick={() => setActiveTab("tab1")}
                >
                  Keyword
                </Tab>
                <Tab
                  key="tab2"
                  value="tab2"
                  onClick={() => setActiveTab("tab2")}
                >
                  URL
                </Tab>
              </TabsHeader>
              <TabsBody>
                <TabPanel key="tab1" value="tab1">
                  <div className="mt-4 overflow-x-auto border-2 border-gray-200 dark:border-gray-500 rounded-md">
                    {isKeywordDataLoading && !RequestSuccess ? (
                      <SkeletonDataTable />
                    ) : (
                      <DataTable
                        columns={columns}
                        data={data}
                        progressPending={isKeywordDataLoading}
                        pagination
                        customStyles={customTableStyles}
                      />
                    )}
                  </div>
                </TabPanel>
                <TabPanel key="tab2" value="tab2">
                  <div className="mt-4 overflow-x-auto border-2 border-gray-200 dark:border-gray-500 rounded-md">
                    {isUrlDataLoading && !RequestSuccess ? (
                      <SkeletonDataTable />
                    ) : (
                      <DataTable
                        columns={columns_URL}
                        data={url_Data}
                        progressPending={isUrlDataLoading}
                        pagination
                        customStyles={customTableStyles}
                      />
                    )}
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>
          )}
        </>
      )}

      <div className="relative p-4">
        {keywordData && keywordData.Weights && !urlData && (
          <>
            <div className="flex flex-col h-full mb-6 lg:flex-row">
              <div className="w-full h-full p-4">
                <LinksTable
                  linksData={keywordData?.Weights?.filtered_links || []}
                />
              </div>
            </div>
            <div className="flex flex-col justify-between mb-6 space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
              <div className="w-full p-4 lg:w-1/2">
                <DataTableComponent
                  title="Keyword Data"
                  columns={columns_}
                  data={keywordData_Array}
                />
              </div>
              <div className="w-full p-4 lg:w-1/2">
                {combinedH2H3DataArray.length > 0 && (
                  <DataTableComponent
                    title="Sub Heading"
                    columns={combinedH2H3Columns}
                    data={combinedH2H3DataArray}
                  />
                )}
              </div>
            </div>
            <div className="hamobozo">
              <div className="mt-4 accordian-body border-2 border-gray-200 dark:border-gray-500 rounded-md">
                {keywordData?.Weights?.filtered_links.map((link, index) => (
                  <Accordion
                    open={openIndex === index}
                    key={index}
                    className="relative"
                  >
                    <AccordionHeader
                      onClick={() => handleAccordionToggle(index)}
                      className={`flex items-center justify-between w-full ${
                        keywordData?.Weights?.filtered_links.length - 1 ===
                          index && "border-none"
                      }`}
                    >
                      <span className=" text-sm ">
                        {extractDomain(link.link)}
                      </span>
                      <div className="flex items-center space-x-2 absolute right-0">
                        {openIndex === index ? (
                          <FaChevronUp size={16} />
                        ) : (
                          <FaChevronDown size={16} />
                        )}
                        <a
                          href={link.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-red-500"
                        >
                          <FaExternalLinkAlt size={16} />
                        </a>
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="hamobozo">
                      <div dangerouslySetInnerHTML={createMarkup(link.soup)} />
                    </AccordionBody>
                  </Accordion>
                ))}
              </div>
            </div>
          </>
        )}
        {urlData && (
          <>
            <CompanyTabs urlData={urlData} />
            <div
              className="flex flex-col justify-between p-4 
                        pt-4 mx-auto space-y-4 mt-[20px]
                        lg:flex-row lg:space-y-0 lg:p-10 lg:pt-0 lg:space-x-8 max-w-screen-2xl "
            >
              <div className="w-full lg:w-1/2">
                <DataTableComponent
                  title="H2 Keywords"
                  columns={h2Columns}
                  data={h2DataArray}
                />
              </div>
              <div className="w-full lg:w-1/2">
                <DataTableComponent
                  title="H3 Keywords"
                  columns={h3Columns}
                  data={h3DataArray}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default MainContent;
