import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
// In your errorSlice.js or wherever your error handling Redux logic is

// Add a new action for network errors

  // Remember to import and use checkNetworkStatus where appropriate
  
export const checkApiHealth = createAsyncThunk(
    'error/checkApiHealth',
    async (_, { dispatch }) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/`);
        // console.log(response,'response');
        if (response.status === 200) {
          dispatch(clearError());
        } else {
          dispatch(setError('An unexpected error occurred.'));
        }
      } catch (error) {
        // Checking specifically for a network error
        if (!error.response) { // This indicates a network error
          dispatch(setError('Network error. Please refersh the page.'));
        } else if (error.response.status === 404 || error.response.status === 500) {
          dispatch(setError('The backend is currently down. Please try again later.'));
        } else {
          dispatch(setError('An unexpected error occurred.'));
        }
      }
    }
  );
export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    message: '',
  },
  reducers: {
    setError: (state, action) => {
      state.message = action.payload;
    },
    clearError: (state) => {
      state.message = '';
    },
  },
});

export const { setError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
