import React from 'react';

const SkeletonInputForm = () => {
  return (
    <div className="space-y-4 animate-pulse">
      <div className="w-full h-10 bg-gray-200 rounded"></div>
      {/* <div className="w-full h-10 bg-gray-300 rounded"></div>
      <div className="w-full h-10 bg-gray-300 rounded"></div>
      <div className="w-32 h-10 bg-gray-300 rounded mt-4"></div> */}
    </div>
  );
};

export default SkeletonInputForm;
