import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";

const FeedbackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      className={`fixed  bottom-4  bg-mainColor right-5
       hover:bg-white text-white hover:text-mainColor
       font-bold rounded-full shadow-lg focus:outline-none focus:shadow-outline transform 
      transition-transform duration-300 ease-in-out hover:scale-110 
      z-[10000] w-12 h-12 flex items-center justify-center pt-[2px]`}
      onClick={() => navigate("/feedback")}
    >
      <MdOutlineFeedback size={22} className="" />
    </button>
  );
};

export default FeedbackButton;
