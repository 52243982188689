import React, { useState, useEffect } from 'react';

const useIsSmallScreen = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
  
    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      const handleMediaChange = (e) => setIsSmallScreen(e.matches);
  
      mediaQuery.addListener(handleMediaChange);
      setIsSmallScreen(mediaQuery.matches); // Set initial value
  
      return () => mediaQuery.removeListener(handleMediaChange);
    }, []);
  
    return isSmallScreen;
  };
export default useIsSmallScreen;
