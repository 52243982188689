import { useState, useEffect } from 'react';

/**
 * Custom hook to handle sorting of data.
 * @param {Array} initialData - The initial data to be sorted.
 * @returns {Object} - An object containing the sorted data, sort configuration, and a function to request sorting.
 */
const useSort = (initialData) => {
  // State to store the sorting configuration: key and direction
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // State to store the sorted data
  const [sortedData, setSortedData] = useState(initialData);

  // useEffect to sort the data whenever initialData or sortConfig changes
  useEffect(() => {
    const sortData = (data) => {
      // If no sort key is provided, return the data as is
      if (!sortConfig.key) return data; 

      // Sort the data based on the sortConfig key and direction
      return [...data].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    };

    // Update the sortedData state with the sorted data
    setSortedData(sortData(initialData));
  }, [initialData, sortConfig]);

  /**
   * Function to request sorting by a specific key.
   * Toggles the direction if the same key is requested again.
   * @param {string} key - The key to sort by.
   */
  const requestSort = (key) => {
    let direction = 'ascending';
    // Toggle direction if the same key is requested
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    // Update the sortConfig state with the new key and direction
    setSortConfig({ key, direction });
  };

  // Return the sorted data, sort configuration, and the requestSort function
  return { sortedData, sortConfig, requestSort };
};

export default useSort;
