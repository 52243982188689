import React from 'react';

const DeleteBookmarkButton = ({ onClick }) => {
  return (
    <div className='Bookmark'>
      <button
        onClick={onClick}
        className="px-4 py-2 h-[40px] text-white bg-red-500 rounded hover:bg-red-700 ml-[8px] lg:text-sm text-xs block"
      >
        Delete Favourite List 
      </button>
    </div>
  );
};

export default DeleteBookmarkButton;
