import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const PagePaginationProjects = ({
  currentPage,
  totalPages,
  paginate,
  links,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  return (
    <nav>
      <ul className={`flex justify-center  p-3 ${links && "rounded"} `}>
        {!links && (
          <li className="mx-1 flex justify-center">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={`flex items-center px-2 py-1 rounded 
              ${
                currentPage === 1
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-mainColor text-white"
              }`}
            >
              <FaArrowLeft size={14} />
            </button>
          </li>
        )}

        {pageNumbers.map((number) => (
          <li key={number} className={`${!links ? "mx-1" : ""}  `}>
            <button
              onClick={() => paginate(number)}
              className={`px-3 py-1 ${!links && "rounded"} ${
                links && number == 1 ? "rounded-l-md" : ""
              } ${links && number == 10 ? "rounded-r-md" : ""} text-sm ${
                currentPage === number
                  ? "bg-mainColor text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
            >
              {number}
            </button>
          </li>
        ))}
        {!links && (
          <li className="mx-1  flex justify-center">
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={`flex items-center px-2 py-1 rounded ${
                currentPage === totalPages
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-mainColor text-white"
              }`}
            >
              <FaArrowRight size={14} />
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default PagePaginationProjects;
