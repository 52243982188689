import React from "react";
import PagePagination from "./PagePagination";

const PaginationControls = ({
  rowsPerPage,
  setRowsPerPage,
  currentPage,
  paginate,
  totalPages,
  tableDataLength,
  showProgressBar,
  filteredData,
}) => (
  <div
    className={`grid   ${
      showProgressBar ? "md:grid-cols-3" : "md:grid-cols-2"
    }  items-center  mt-4 space-y-4 md:space-y-0  `}
  >
    <div className="flex justify-between w-fit ">
      <select
        id="rows-per-page"
        value={rowsPerPage}
        onChange={(e) => setRowsPerPage(Number(e.target.value))}
        className="block w-full px-3 py-1.5 text-base font-normal text-gray-700 dark:text-gray-500
         bg-white dark:bg-darkbg bg-clip-padding bg-no-repeat border border-solid
          border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-mainColor-600 focus:outline-none"
      >
        {[5, 10, 15, 20, 50, 100, 200, 500].map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
        {/* {tableDataLength <= 700 && <option value={tableDataLength}>All</option>} */}
      </select>
    </div>
    <PagePagination
      currentPage={currentPage}
      totalPages={totalPages}
      paginate={paginate}
      showProgressBar={showProgressBar}
    />
  </div>
);

export default PaginationControls;
