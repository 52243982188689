import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({ searchQuery, handleSearchInputChange }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchActive(true);
  };

  const handleBlur = () => {
    if (!searchQuery) {
      setIsSearchActive(false);
    }
  };

  return (
 
<div className={`searching-bar relative flex items-center ${isSearchActive ? 'w-full' : 'w-auto'} sm:w-auto`}>
<button
  onClick={handleSearchIconClick}
  className={`flex items-center justify-center h-[40px] border font-bold rounded-md 
${isSearchActive ? 'hidden' : 'block'} lg:hidden bg-mainColor text-white px-4 `}
>
  <FiSearch size={20} className="text-white" />
</button>
<input
  type="text"
  placeholder="Search..."
  value={searchQuery}
  onChange={handleSearchInputChange}
  onBlur={handleBlur}
  className={`pl-10 pr-4 h-[40px] border dark:border-gray-500 font-bold rounded-md  bg-white dark:bg-darkbg
     ${isSearchActive ? 'block' : 'hidden'} lg:block  focus:outline-gray-300 dark-focus:outline-gray-400`}
  autoFocus={isSearchActive}
/>
</div>
  );
};

export default SearchBar;
