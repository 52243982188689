import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  checkApiHealth,
  checkNetworkStatus,
  clearError,
} from "../../redux/lib/errorSlice";

const ErrorMessage = () => {
  const dispatch = useDispatch();
  // Assuming the error slice is directly under state and not nested
  const errorMessage = useSelector((state) => state.errorSlice.message)
  useEffect(() => {
    // Log when the useEffect hook runs
    // Dispatch checkNetworkStatus immediately
    // Set an interval to periodically check API health
    const apiHealthInterval = setInterval(() => {
      dispatch(checkApiHealth());
    }, 15000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(apiHealthInterval);
    };
  }, [dispatch]); // Ensure this dependency list is correct based on your specific dependencies

  if (!errorMessage) return null;

  return (
    <></>
    // <div
    //   className="fixed bottom-0 right-0 w-full py-2 text-white z-[1500] text-center bg-red-600"
    //   onClick={() => dispatch(clearError())}
    // >
    //   {/* {errorMessage} (click to dismiss) */}
    // </div>
  );
};

export default ErrorMessage;
