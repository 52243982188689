import { extractDomain } from "../../../utils/Functions";

const parseActionText = (text) => {
  let query = "N/A",
    target_url = "N/A",
    project_name = "N/A",
    action = "N/A",
    user = "N/A";

  if (!text) {
    console.error("Action text is undefined or empty");
    return {
      query: "N/A",
      action: "N/A",
      target_url: "N/A",
      project_name: "N/A",
      user: "N/A",
    };
  }

  try {
    const userMatch = text.match(/ by (.+)$/);
    user = userMatch ? userMatch[1].trim() : "N/A";

    if (text.includes("login") || text.includes("logout")) {
      action = text.split(" by ")[0].trim();
    } else if (text.includes("Deleted with Target_url")) {
      action = "Deleted";
      const parts = text.split(" Deleted with Target_url : ");
      query =
        text
          .split(" ")
          .slice(
            text.split(" ").indexOf("Query:") + 1,
            text.split(" ").indexOf("Deleted")
          )
          .join(" ") || "N/A";
      const urlAndRest = parts[1]?.split(" in project ") || [];
      target_url = urlAndRest[0]?.trim() || "N/A";
      target_url = extractDomain(target_url);

      const projectAndRank = urlAndRest[1]?.split(" and it's Rank ") || [];
      project_name = projectAndRank[0]?.split(" by ")[0].trim() || "N/A";
    } else if (text.includes("Added with Target_url")) {
      action = "Added";
      const addedParts = text.split(" Added with Target_url: ");
      query = addedParts[0]?.replace("Query: ", "").trim() || "N/A";

      const urlAndRest = addedParts[1]?.split(" in project ") || [];
      target_url = urlAndRest[0]?.trim() || "N/A";
      target_url = extractDomain(target_url);

      const projectAndRank = urlAndRest[1]?.split(" and it's Rank ") || [];
      project_name = projectAndRank[0]?.split(" by ")[0].trim() || "N/A";
    } else if (text.includes("Updated with Target_url")) {
      action = "Updated";
      const updatedParts = text.split(" Updated with Target_url : ");
      query = updatedParts[0]?.replace("Query: ", "").trim() || "N/A";

      const urlAndRest = updatedParts[1]?.split(" in project ") || [];
      target_url = urlAndRest[0]?.trim() || "N/A";
      target_url = extractDomain(target_url);

      const projectAndRank = urlAndRest[1]?.split(" and it's Rank ") || [];
      project_name = projectAndRank[0]?.split(" by ")[0].trim() || "N/A";
    }
  } catch (error) {
    console.error("Error parsing action text:", error, "Text:", text);
    return {
      query: "Error",
      target_url: "Error",
      project_name: "Error",
      action: "Error",
      user: "Error",
    };
  }
  return { query, target_url, project_name, action, user };
};


export default parseActionText;
