import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { useLocation } from "react-router-dom";

const InputForm = ({ onSubmit, isLoading }) => {
  const { register, handleSubmit } = useForm();
  const verified = useSelector((state) => state.authSlice.verified);
  const EmailUser = useSelector((state) => state.authSlice.user.Email); // Assuming you have EmailUser in your state
  const handleFormSubmit = async (data) => {
    await onSubmit(data.input);
  };

  return (
    <form
      onSubmit={handleSubmit(handleFormSubmit)}
      className="relative md:flex w-full tooltipcontainer bg-[#F5F5F5] dark:bg-darkbg rounded-lg"
    >
      <input
        required
        {...register("input", { required: "Input is required" })}
        className="relative md:w-4/5 w-full p-2 md:rounded-l-lg md:rounded-r-none
         rounded-lg outline-none focus:outline-none focus:ring-0 dark:bg-slate "
        placeholder="Enter Keyword or URL..."
        disabled={isLoading || !verified} // Disable if loading or not verified
      />
      <button
        type="submit"
        disabled={isLoading || !verified} // Disable if loading or not verified
        className={`md:w-1/5 w-full bg-mainColor text-center ml-2  flex items-center justify-center  
          md:rounded-r-lg  md:rounded-l-none rounded-lg md:my-0 my-2 text-white p-2 ${
            isLoading || !verified ? "opacity-50 cursor-not-allowed" : ""
          }`}
      >
        {isLoading && (
          <svg
            className="w-5 h-5 mr-3 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
        )}
        {isLoading ? "Loading..." : "Submit"}
      </button>
      {!verified && (
        // <div className="tooltipcontainer absolute
        // right-0 -bottom-0 hover:bottom-full mb-2 w-full p-2 bg-gray-700 text-white text-center text-sm rounded-md">
        //   Please verify your account through email
        //   <span className="underline"> {EmailUser}</span>
        // </div>
        <div className="  absolute w-full bottom-full  transition-all duration-1000 ease-in-out flex items-end justify-end">
          <span
            className=" hidden md:block tooltiptext 
           mb-2 w-fit py-2 px-5 bg-gray-700 text-white text-center text-sm rounded-md "
          >
            Please verify your account through email
            <span className="underline"> {EmailUser}</span>
          </span>
        </div>
      )}
    </form>
  );
};

export default InputForm;
