export const sidebarLinks = [
  {
    id: 4,
    name: "Projects",
    path: "/projects",
    isPinned: false,
  },
  {
    id: 11,
    name: "keyword Research",
    path: "/main/keywordresearch",
  },
  {
    id: 9,
    name: "Account",
    path: "/account",
  },
  {
    id: 10,
    name: "Log",
    path: "/log",
  },
];
