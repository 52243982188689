import React from 'react';

const StopOperationButton = ({ onClick }) => {
  return (
    <div className='stop-progress'>
      <button
        onClick={onClick}
        className="px-4 py-2 h-[40px] text-white bg-red-500 rounded hover:bg-red-700 ml-[8px] font-bold lg:text-sm text-xs"
      >
        Stop
      </button>
    </div>
  );
};

export default StopOperationButton;
