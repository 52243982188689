const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, title }) => {
    if (!isOpen) return null;
  
    return (
      <div
        className="pointer-events-auto fixed inset-0 z-[1000] 
          grid h-screen w-screen place-items-center bg-black bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
      >
        <div className="bg-white p-6 rounded-md shadow-lg">
          <h2 className="text-lg font-semibold mb-4">Delete Article</h2>
          <p>Are you sure you want to delete the article titled "<strong>{title}</strong>"?</p>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };
  export default DeleteConfirmationModal;