// selectStyles.js


const customSelectStylesLight = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
    borderColor: "#e5e7eb", // Tailwind's border-gray-200
    borderWidth: "2px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgb(156 163 175)", // Tailwind's border-gray-400 for hover state
    },
    minHeight: "20px",
    display: "flex",
    justifyContent: "space-between",
    zIndex: 999 , // Adjust this value based on your layout needs


  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#000",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#ddd" : "#fff",
    color: state.isSelected ? "#000" : "#000",
  }),
};

export default customSelectStylesLight;
