import React from "react";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import { store, persistor } from "./redux/store";
import { createRoot } from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import { ProjectProvider } from "./ProjectProvider";
// import store from "./redux/store"
const root = createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <ProjectProvider>
          <App />
        </ProjectProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </>
);
