import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { registrationSchema } from "../../utils/validation";
import {  FiX, FiEye, FiEyeOff } from 'react-icons/fi';
import { FaSpinner } from 'react-icons/fa';
const RegistrationForm = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({
    resolver: zodResolver(registrationSchema),
    mode:"onChange"
  });
  const [csrfToken, setCsrfToken] = useState('');
  const [passwordStrength, setPasswordStrength] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (token) setCsrfToken(token);
  }, []);

const password = watch("password");
const password2 = watch("password2");

useEffect(() => {
  if (password2 && password !== password2) {
    setError("password2", {
      type: "manual",
      message: "Passwords don't match",
    });
  } else {
    clearErrors("password2");
  }
}, [password, password2, setError, clearErrors]);

  useEffect(() => {
    setPasswordStrength({
      length: password?.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  }, [password]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      const registrationData = {
        username: data.username,
        email: data.email,
        password: data.password,
        password2: data.password2,
      };

      const response = await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/register/`,
          registrationData,
          {
            headers: {
              "Content-Type": "application/json",
              // 'CSRF-Token': csrfToken,
            },
          }
        )
        .then((res) => {

          toast.success("Registration successful");
          localStorage.setItem("showVerifyMessage", "true");
          navigate("/login");
        })
        .catch((err) => {
          const errorSentence = Object.entries(err.response.data)
            .map(
              ([field, messages]) =>
                `${
                  field.charAt(0).toUpperCase() + field.slice(1)
                }: ${messages.join(" ; ")}`
            )
            .join(" and ");
          toast.error(errorSentence);
        });
    } catch (error) {
      toast.dismiss();

      toast.error("Registration failed");
      console.error(
        "Error details:",
        error.response ? error.response.data : error.message
      );
    }
    finally {
      setIsLoading(false);
    }
   
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full max-w-md p-6 mx-auto rounded-lg white mt-4 shadow-custom sm:mt-10">
      <h2 className="mb-3 text-2xl font-bold text-center text-mainColor">
        RankTracker
      </h2>
      <h3 className="mt-3 text-lg font-bold text-mainColor lg:text-xl md:text-2xl">
        Create an Account
      </h3>
      <p className="mt-4 mb-3 text-md">
        Fill in the details below to create your account.
      </p>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Name:
        </label>
        <input
          type="text"
          placeholder="Enter your name"
          {...register("username")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.username && (
          <p className="mt-1 text-xs text-red-500">{errors.username.message}</p>
        )}
      </div>
      <div className="mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Email:
        </label>
        <input
          type="text"
          placeholder="Enter your email address"
          {...register("email")}
          className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
        />
        {errors.email && (
          <p className="mt-1 text-xs text-red-500">{errors.email.message}</p>
        )}
      </div>
      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">
          Password:
        </label>
        <div className="relative">
          <input
            placeholder="Enter your password"
            type={showPassword ? "text" : "password"}
            {...register("password")}
            className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue pr-10"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
          >
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        </div>
        {errors.password && (
          <p className="mt-1 text-xs text-red-500">{errors.password.message}</p>
        )}
      </div>
      <div className="relative mb-6">
  <label className="block mb-2 text-sm font-bold text-gray-700">
    Confirm Password:
  </label>
  <div className="relative">
    <input
      placeholder="Re-enter your password"
      type={showConfirmPassword ? "text" : "password"}
      {...register("password2")}
      className={`w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue pr-10 ${
        errors.password2 ? 'border-red-500' : ''
      }`}
    />
    <button
      type="button"
      onClick={toggleConfirmPasswordVisibility}
      className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700"
    >
      {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
    </button>
  </div>
  {errors.password2 && (
    <p className="mt-1 text-xs text-red-500">{errors.password2.message}</p>
  )}
</div>
<button
  type="submit"
  disabled={isLoading}
  className={`px-4 py-2 mt-5 font-bold text-white rounded transition-colors ${
    isLoading
      ? 'bg-gray-400 cursor-not-allowed'
      : 'bg-mainColor hover:bg-mainColor-600'
  }`}
>
  {isLoading ? (
    <>
      <FaSpinner className="inline-block animate-spin mr-2" />
      Creating...
    </>
  ) : (
    'Create Account'
  )}
</button>
      <div className="mb-4 text-center">
        <p className="mt-4 text-md">
          Already have an account?{" "}
          <span className="cursor-pointer text-mainColor hover:underline">
            <Link to="/login">Login</Link>
          </span>
        </p>
      </div>
    </form>
  );
};

export default RegistrationForm;