import React from "react";

const PagePagination = ({ currentPage, totalPages, paginate, showProgressBar }) => {
    return (
        <nav
            aria-label="Page navigation"
            className={`flex  w-full justify-center  ${showProgressBar ? "" : "ml-auto"
                } md:w-auto p-3`}>
            <ul className="inline-flex items-center -space-x-px">
                <li>
                    <button
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-4 py-2 leading-tight text-gray-500 bg-white dark:bg-darkbg border border-gray-300 dark:border-gray-500
                             rounded-l
               hover:bg-mainColor-100 ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-mainColor-50"
                            }`}
                        aria-label="Previous"
                    >
                        <span aria-hidden="true">&laquo;</span>
                    </button>
                </li>
                <li>
                    <span className="px-4 py-2 text-gray-700 dark:text-gray-500 border-t border-b border-gray-300 dark:border-gray-500">
                        Page {currentPage}
                    </span>
                </li>
                <li>
                    <button
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage >= totalPages}
                        className={`px-4 py-2 leading-tight text-gray-500 bg-white dark:bg-darkbg border border-gray-300 dark:border-gray-500 rounded-r
               hover:bg-mainColor-100 ${currentPage >= totalPages ? "opacity-50 cursor-not-allowed" : "hover:bg-mainColor-50"
                            }`}
                        aria-label="Next"
                    >
                        <span aria-hidden="true">&raquo;</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default PagePagination;

