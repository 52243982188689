import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const fetchRank = createAsyncThunk(
  'rank/fetchRank',
  async (_, { getState, rejectWithValue }) => {
    const state = getState();
    const id = state.authSlice.id;
    const token = state.authSlice.token;

    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/userRank/${id}/`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the request headers
          },
        });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const rankSlice = createSlice({
  name: 'rank',
  initialState: {
    data: [],
    status: 'idle', 
    error: null

  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRank.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRank.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchRank.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default rankSlice.reducer;
