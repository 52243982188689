import React from 'react';
import ConfirmationDialog from '../Modal/ConfirmingDialog';
const ConfirmationModals = ({
  isBulkDeleteDialogOpen,
  handleDeleteConfirmed,
  handleCancelDeletion,
  isDialogOpen,
  cancelDeletion,
  confirmDeletion,
  isDeleting,
}) => {
  return (
    <>
      <ConfirmationDialog
        isOpen={isBulkDeleteDialogOpen}
        onConfirm={handleDeleteConfirmed}
        onClose={handleCancelDeletion}
        message="Are you sure you want to delete the selected queries?"
      />
      {isDialogOpen && (
        <ConfirmationDialog
          isOpen={isDialogOpen}
          onClose={cancelDeletion}
          onConfirm={confirmDeletion}
          message="Are you sure you want to delete this query?"
          isDeleting={isDeleting} // Pass the isDeleting state
        />
      )}
    </>
  );
};

export default ConfirmationModals;
