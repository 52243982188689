import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Login from './pages/auth/Login';
import { useSelector } from 'react-redux';
import PrivateRoutes from './utils/PrivateRoutes';
import Register from './pages/auth/Register';
// import TargetPage from './pages/TargetPage';
import { ToastContainer } from 'react-toastify';
// import ErrorMessage from './components/Error/ErrorMessage';
import RegistrationToken from './pages/Checking/RegistrationToken';
import CheckExecitance from './pages/Checking/CheckExecitance';
import VerifiedEmail from './pages/Checking/VerifiedEmail';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPassword';
import { createSelector } from 'reselect';
import CheckEmailPage from './pages/CheckEmailPage';
// import UseWebSocketUpdate from './hook/useWebSocketUpdate';
const selectAuthToken = createSelector(
  (state) => state.authSlice.user,
  (user) => user.token
);
const App = () => {
  const isAuth = useSelector((state) => state?.authSlice?.user?.token);
  // const isAuth = useSelector(selectAuthToken);


  return (
    <div>
     {/* <ErrorMessage /> */}
{/* <UseWebSocketUpdate/> */}

      <ToastContainer
        position="top-right"

        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>

        <Route element={<PrivateRoutes />}>
          <Route path='/*' element={<Layout />} />
        </Route>
        {/* <Route path='/login' element={ <Login />} /> */}
        <Route path='/login' element={isAuth ? <Navigate to="/projects" /> : <Login />} />


        <Route path='/register' element={isAuth ? <Navigate to="/projects" /> : <Register />} />

        <Route path='/CheckExecitance/:token' element={<CheckExecitance />} />

        <Route path='/verified/:token' element={<VerifiedEmail />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        {/* <Route path="/reset/:token" element={<ResetPassword />} /> */}
        <Route path="/reset-password/:uid/:token" element={<ResetPassword />} />
        <Route path="/check-email" element={<CheckEmailPage />} />

        <Route path="/register/:token" element={<RegistrationToken />} />

      </Routes>
    

           
       </div>
    
  );
};

export default App;
