import React, { useState, useEffect } from "react";
import { Range } from "react-range";

const RankRangeSlider = ({
  minInitialValue,
  maxInitialValue,
  onRangeChange,
}) => {
  // Define the rank boundaries
  const minRank = 1;
  const maxRank = 101; // Treat 101 as a special case representing "100+"

  // Ensure initial values are within bounds and logical
  const safeMinInitial = Math.max(minInitialValue, minRank);
  const safeMaxInitial = Math.min(maxInitialValue, maxRank);
  const initialValues = [safeMinInitial, safeMaxInitial].sort((a, b) => a - b);

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    setValues([safeMinInitial, safeMaxInitial].sort((a, b) => a - b));
  }, [safeMinInitial, safeMaxInitial]);

  const handleChange = (values) => {
    setValues(values);
    onRangeChange({ min: values[0], max: values[1] });
  };

  const renderThumbLabel = (value) => {
    return value === 101 ? "100+" : value.toFixed(0);
  };

  return (
    <div className="space-y-4 mt-[30px]">
      <Range
        step={1}
        min={minRank} // Use the minRank for the minimum value
        max={maxRank}
        values={values}
        onChange={handleChange}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            className={`${
              isDragged ? "bg-green-900" : "bg-slate-900"
            } h-4 w-4 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainColor flex items-center justify-center`}
            style={{
              ...props.style,
            }}
          >
            <div className="absolute mt-[0.5rem] text-xs font-medium text-white -top-7 text-bold p-1 
            rounded bg-[#0d2218]">
              <div>{renderThumbLabel(values[index])}</div>
            </div>
            <div
              className="w-full h-full rounded-full bg-mainColor"
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: isDragged ? "#3db954" : "#ccc",
              }}
            />
          </div>
        )}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
            }}
            className="rounded bg-mainColor my-4 h-[5px] flex !w-full"
          >
            <div
              className="bg-gray-300 rounded absolute w-full h-[5px]" // This is the default track color
            />
            {React.Children.map(children, (child) =>
              React.cloneElement(child, {
                style: {
                  ...child.props.style,
                  zIndex: 1, // Ensure thumbs are above the highlighted range
                },
              })
            )}
            {/* Highlighted range between the thumbs */}
            <div
              className="rounded bg-mainColor" // This is the highlighted range color
              style={{
                height: "5px",
                position: "absolute",
                left: `${((values[0] - minRank) / (maxRank - minRank)) * 100}%`, // Calculate left position based on first thumb
                right: `${
                  100 - ((values[1] - minRank) / (maxRank - minRank)) * 100
                }%`, // Calculate right position based on second thumb
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default RankRangeSlider;
