import SkeletonLoader from "./SkeletonLoader";
import SkeletonRow from "./SkeletonRow";

const SkeletonTable = () => (
    <table className="min-w-full divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
          <th className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"><SkeletonLoader className="h-4 w-1/4" /></th>
         
       
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {/* Display 10 skeleton rows for example */}
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonRow key={index} />
        ))}
      </tbody>
    </table>
  );
export default SkeletonTable;