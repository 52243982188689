import React, { useEffect, useState } from 'react';

const EditProgressBar = ({ isEditInProgress, editProgress, editError, setEditProgress }) => {

  useEffect(() => {
    if (!isEditInProgress && editProgress !== 100) {
      const timer = setTimeout(() => {
        setEditProgress(0);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isEditInProgress, editProgress, setEditProgress]);

  const progressBarColor = editError ? 'bg-red-500' : 'bg-green-500';
  const textColor = editError ? 'text-red-500' : 'text-green-500';
  const progressBarWidth = `${Math.min(editProgress, 100)}%`;

  return (
    <div className="my-2">
      <div className="bg-gray-200 progress-bar-striped rounded-full h-2 overflow-hidden">
        <div
          style={{ width: progressBarWidth }}
          className={`h-full rounded-full ${progressBarColor} transition-all duration-500`}
        ></div>
      </div>
      <div className="mt-2 text-sm">
        <span className={`${textColor}`}>
          {editError ? `Editing failed: ${editError.message}` : editProgress === 100 ? "Edit completed successfully!" : "Editing in progress..."}
        </span>
      </div>
    </div>
  );
};

export default EditProgressBar;
