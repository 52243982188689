import axios from 'axios';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Core styles for Quill
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

const modules = {
  toolbar: [
    [{ 'header': '1'}, { 'header': '2'}, { 'header': [3, 4, 5, 6] }, { 'font': [] }],
    [{ 'size': ['small', false, 'large', 'huge'] }],  // Font size
    [{ 'color': [] }, { 'background': [] }],          // Text and background color
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],     // Subscript / Superscript
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ 'align': [] }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // Indentation
    ['link', 'image', 'code-block'],
    ['clean']                                         
  ],
};

const formats = [
  'header', 'font', 'size', 'color', 'background',
  'list', 'bullet', 'script',
  'bold', 'italic', 'underline', 'blockquote',
  'align', 'indent',
  'link', 'image', 'code-block'
];

const RichTextEditor = () => {
  const location = useLocation();
  const { id } = useParams(); // Extract the ID from the URL
  const { title: initialTitle, content: initialContent } = location.state || {};
  const [title, setTitle] = useState(initialTitle || '');
  const [value, setValue] = useState(initialContent || '');
  const [isSaving, setIsSaving] = useState(false);
  const token= useSelector((state)=>state.authSlice.token)

  const handleChange = (content) => {
    setValue(content);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const updatedArticle = {
        title,
        content: value,
      };
      console.log(updatedArticle,"updatedArticle")
      await axios.put(`${process.env.REACT_APP_API_URL}/keyword/generated-articles/${id}/`, updatedArticle, {
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

    } catch (error) {
      console.error('Error updating article:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Edit Article</h2>

      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="w-full mb-4 p-2 border border-gray-300 rounded-lg"
        placeholder="Enter article title"
      />

      <div className="bg-white p-4 rounded-lg shadow-md border border-gray-300">
        <ReactQuill 
          theme="snow" 
          value={value} 
          onChange={handleChange} 
          modules={modules}
          formats={formats}
          className="min-h-[150px] border-none focus:ring-0"
        />
      </div>

      <div className="flex justify-end mt-4">
        <button
          onClick={handleSave}
          className={`bg-mainColor text-white px-6 py-2 rounded-full shadow-lg hover:bg-darkMainColor transition duration-200 ease-in-out ${
            isSaving ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>
    </div>
  );
};

export default RichTextEditor;
