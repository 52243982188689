import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import extractDomainName from "../../utils/Functions/ExtractDomainName";
import createLogQuery from "../../pages/SidebarPages/Log/createLogQuiries";

export const deleteProject = createAsyncThunk(
  "ranks/deleteProject",
  async (query_id, { getState, rejectWithValue }) => {
    const state = getState();
    const userId = state.authSlice.id;
    const token = state.authSlice.token;
    const originalUser = state.originalUserSlice.name;
    const ProjectName = state.projectCompetitors.projectName;
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/delete-rank/${userId}/${query_id}/`;

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const res = response.data;
        const domainName = extractDomainName(res.target_url);
        const logMessage = `Query: ${res.query} Deleted with Target_url : ${res.target_url} in project ${res.project} and it's Rank ${res.query} - ${res.project} by ${originalUser}`;
        createLogQuery(logMessage, userId);

        return { query_id, ...res }; // Return detailed response data
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      console.error("Error in deleteRank thunk:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
const deleteProjectSlice = createSlice({
  name: "rank",
  initialState: {
    ranks: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ranks = state.ranks.filter(
          (rank) => rank.query_id !== action.payload
        );
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default deleteProjectSlice.reducer;
