const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    colors: ['#2BA950'],
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd MMM',
      },
      min: new Date(new Date().setDate(new Date().getDate() - 5)).getTime(),
      max: new Date().getTime(),
    },
    yaxis: {
      reversed: true,
      labels: {
        formatter: function (value) {
          return Math.round(value);
        },
      },
      title: {
        text: 'Average Rank',
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    markers: {
      size: 5,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
      y: {
        formatter: function (value) {
          return `Rank: ${Math.round(value)}`;
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return Math.round(value);
      },
    },
  };
  
  export default chartOptions;
  