import React, { useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

/**
 * Serpanalysis Component
 *
 * This component renders a detailed analysis of a search engine results page (SERP) for a given keyword.
 * It displays various metrics and information about the keyword, including rank, type, result state, breadcrumb,
 * and target URL. It also features two circular progress bars to visualize rank group and absolute rank.
 *
 * Props:
 * - rowData: An object containing the SERP data for a specific keyword, including:
 *   - query: The keyword query.
 *   - Type_rank: The type of rank.
 *   - result_stat: The result state.
 *   - breadcrumb: The breadcrumb navigation.
 *   - target_url: The target URL.
 *   - rank: The rank group.
 *   - rank_abs: The absolute rank.
 *
 * Usage:
 * <Serpanalysis rowData={data} />
 */

const Serpanalysis = ({ rowData }) => {
  const maxRank = 100; // Define the maximum rank value for percentage calculations
  const rankPercentage = rowData ? (rowData.rank_abs / maxRank) * 100 : 0;
  const rankGroupPercentage = rowData ? (rowData.rank / maxRank) * 100 : 0;
  const userId = useSelector((state) => state?.authSlice?.user?.id); // Get the user ID from the Redux store

  // Define colors for the circular progress bars
  const pathColor = "#2BA950";
  const textColor = "#000000";
  const trailColor = "#d6d6d6";

  // State to manage hover effects for the progress bars
  const [hoverRankGroup, setHoverRankGroup] = useState(false);
  const [hoverRankAbsolute, setHoverRankAbsolute] = useState(false);

  // CustomText component to handle text display based on hover state
  const CustomText = ({ isHovered, text, hoveredText }) => (
    <div className="text-sm">
      {isHovered ? (
        <span
          className="text-black bg-white shadow-md p-1 rounded-md absolute top-1/4 
        left-1/2 transform -translate-x-1/2 -translate-y-1/4 font-semibold text-center
         mx-auto flex items-center justify-center"
        >
          {hoveredText}
        </span>
      ) : (
        <span className="mx-auto font-semibold text-center text-black">
          {text}
        </span>
      )}
    </div>
  );
  const Data = [
    {
      title: "Keyword:",
      details: rowData.query,
      green: true,
    },
    {
      title: "Type:",
      details: rowData.Type_rank,
      green: true,
    },
    {
      title: "Result State:",
      details: rowData.result_stat,
      green: false,
    },
    {
      title: "Breadcrumb:",
      details: rowData.breadcrumb,
      green: false,
    },
    {
      title: "TargetUrl:",
      details: (
        <Link
          to={`/targets/${userId}/${encodeURIComponent(rowData.target_url)}/`}
        >
          {rowData.target_url}
        </Link>
      ),
      green: true,
    },
    {
      title: "Rank Group:",
      details: rowData.rank,
      green: false,
    },
    {
      title: "Rank Absolute:",
      details: rowData.rank_abs,
      green: false,
    },
  ];
  const columnsSERP = [
    {
      name: "Title",
      selector: (row) => row.title,
      width: "150px",
    },
    {
      name: "Details",
      selector: (row) => row.details,
    },
  ];
  return (
    <div
      className=" rounded-lg
   dark:from-gray-700 dark:to-gray-800 h-full"
    >
      {rowData && (
        <div className=" border-2  border-gray-200 dark:border-gray-500  rounded-md bg-white pb-2">
          {/* Left side content */}
          <DataTable
            className="dark:bg-darkbg text-gray-950 dark:text-gray-300 "
            columns={columnsSERP}
            data={Data}
          />
          {/* {Data.map((data, index) => (
            <div key={index} className="mb-4">
              <p
                className={`text-lg  font-medium text-mainColor flex justify-between ${
                  data.green
                    ? "text-mainColor"
                    : "text-gray-600 dark:text-gray-300"
                }`}
              >
                {data.title}
                <span className="  text-gray-700 dark:text-gray-200">
                  {data.details}
                </span>
              </p>
         {index < Data.length - 1 && (
          <div className="border-b border-gray-300 dark:border-gray-600 mt-2"></div>
         
            </div>
          ))} */}

          {/* <div className="p-3 flex items-center justify-center space-x-8 border-t-2  border-gray-200">
            <div
              className="flex items-center"
              onMouseEnter={() => setHoverRankGroup(true)}
              onMouseLeave={() => setHoverRankGroup(false)}
            >
              <div className="w-20 h-20">
                <CircularProgressbarWithChildren
                  value={rankGroupPercentage}
                  styles={buildStyles({ pathColor, trailColor })}
                >
                  <CustomText
                    isHovered={hoverRankGroup}
                    text={rowData.rank_abs !== 101 ? rowData.rank_abs : "-"}
                    hoveredText="Rank Group"
                  />
                </CircularProgressbarWithChildren>
              </div>
            </div>

            <div
              className="flex items-center"
              onMouseEnter={() => setHoverRankAbsolute(true)}
              onMouseLeave={() => setHoverRankAbsolute(false)}
            >
              <div className="w-20 h-20">
                <CircularProgressbarWithChildren
                  value={rankPercentage}
                  styles={buildStyles({ pathColor, trailColor })}
                >
                  <CustomText
                    isHovered={hoverRankAbsolute}
                    text={rowData.rank_abs !== 101 ? rowData.rank_abs : "-"}
                    hoveredText="Rank Absolute"
                  />
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default Serpanalysis;
