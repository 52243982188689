import React from "react";
import ReactApexChart from "react-apexcharts";

const ChartComponent = ({
  chartOptions,
  seriesData,
  openDateFilter,
  setCustomDateChart,
}) => {
  const hasSeriesData = seriesData.some((series) => series.data.length > 0);

  return (
    <div
      className="w-full p-4 xl:col-span-3 lg:col-span-3 transition duration-500 transform bg-white dark:bg-slate border-2
     border-gray-200 dark:border-gray-500 rounded-lg"
    >
      {hasSeriesData && (
        <div className="w-full flex items-center justify-center">
          <button
            className="cursor-pointer text-center
         text-gray-600 border-2
         border-gray-400 rounded-md
          w-fit px-3 py-1
         hover:bg-mainColor
          hover:text-white
          hover:border-mainColor
          transition-all duration-500 ease-in-out
          "
            onClick={() => {
              openDateFilter();
              setCustomDateChart(false);
            }}
          >
            Customize Date
          </button>
        </div>
      )}

      {hasSeriesData ? (
        <ReactApexChart
          options={chartOptions}
          series={seriesData}
          type="line"
          height={250}
        />
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-300 mb-2">
            Chart Ranks
          </p>
          <p className="text-gray-500 dark:text-gray-400  text-center">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
