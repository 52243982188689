import React, { useState ,useEffect} from "react";
import useKeywordAndUrlProcessing from "../../../hook/useKeywordAndUrlProcessing";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../../pages/MainKeyword/keyword/Layout/Title";
import MainKeywordInfo from "../../../pages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";
import Tables from "../../../pages/MainKeyword/keyword/Layout/Tables";
import InputForm from "./InputForm";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import { useNavigate } from "react-router-dom";
import { jsonrepair } from "jsonrepair";
import axios from "axios";
import { toast } from "react-toastify";
import { FaEye, FaSpinner, FaTrash, FaRegFileWord } from "react-icons/fa"; // Import the Word icon
import htmlDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import ConfirmationModal from "../../Modal/ConfirmationModal";
const ResultSample = ({ data }) => {
  const userId = useSelector((state) => state?.authSlice?.user.id);
  const dispatch = useDispatch();
  const { handleSubmit, isKeywordLoading, isUrlLoading, keywordData, urlData } =
    useKeywordAndUrlProcessing(dispatch, userId);
  const [sortDirection, setSortDirection] = useState(null);
  const token = useSelector((state) => state.authSlice?.user.token);
  const url_title = data[0].query;
  const [isGenerating, setIsGenerating] = useState(false); // Track loading state for generating articles
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null); // Track item to delete

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setIsConfirmationModalOpen(true); // Open confirmation modal
  };
  const closeModal = () => {
    setIsHistoryModalOpen(false);
  };
  const handleViewDetails = async (item) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${item.object_id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response,"oror")
      const { Generated_article, headings, user, word_count } = response.data;
      if (Generated_article && headings && word_count) {

        navigate(`/generatedarticle/${user}/${item.object_id}/`, {
          state: {
            content: Generated_article,
            headings,
            url_title: item.title,
   
            fromHistory: true, // Indicate this is from history
            word_count,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching article details:", error);
    }
  };
  const handleDelete = async (objectId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${objectId}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setHistoryData((prevData) =>
        prevData.filter((item) => item.object_id !== objectId)
      );
      toast.success("Article deleted successfully");
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };
  const navigate = useNavigate();
  let jsonString;
  let parsedResultsSample;
  let Query;
  let aggregated_results;
  let aggregated_h2_counts;
  let aggregated_h3_counts;
  let groupedAndSortedKeywords;
  let sortedData;
  if (!keywordData && data) {
    const repaired = jsonrepair(data[0].results_sample);
    jsonString = repaired;
    parsedResultsSample = JSON.parse(jsonString);
    aggregated_results = JSON.parse(jsonrepair(data[0]?.rank_avg));
    aggregated_h2_counts = JSON.parse(jsonrepair(data[0]?.h2_count));
    aggregated_h3_counts = JSON.parse(jsonrepair(data[0]?.h3_count));
    // groupedAndSortedKeywords =
    //   groupAndSortKeywordsByPhrases(aggregated_results);
    // sortedData = groupedAndSortedKeywords;
    sortedData = aggregated_results;
    Query = data[0].query;
  } else if (keywordData) {
    parsedResultsSample = keywordData?.Weights;
    aggregated_results = parsedResultsSample?.aggregated_results;
    aggregated_h2_counts = keywordData.Weights.aggregated_h2_counts;
    aggregated_h3_counts = keywordData.Weights.aggregated_h3_counts;
    // groupedAndSortedKeywords =
    //   groupAndSortKeywordsByPhrases(aggregated_results);
    sortedData = aggregated_results;
    Query = keywordData.Query;
  }
  const filteredLinks = parsedResultsSample?.filtered_links;
  const weighted_word_count = parsedResultsSample?.weighted_word_count;
  function findCommonPhrase(keyword, groups) {
    for (let phrase in groups) {
      const wordsInGroup = phrase.split(" ");
      const wordsInKeyword = keyword.split(" ");
      // Ensure at least 2 words match, and prioritize more than 2-word phrases
      const matchCount = wordsInGroup.filter((word) =>
        wordsInKeyword.includes(word)
      ).length;
      if (matchCount >= 2) {
        return phrase;
      }
    }
    return null;
  }
  function groupAndSortKeywordsByPhrases(keywords) {
    const groups = {}; // Store groups with the original object structure
    keywords.forEach((item) => {
      const foundGroup = findCommonPhrase(item.keyword, groups);
      if (foundGroup) {
        groups[foundGroup].push(item);
      } else {
        // Create a new group based on the first two words (or more) as a phrase
        const phrase = item.keyword.split(" ").slice(0, 3).join(" "); // Adjust this to capture 2-3 word phrases
        if (!groups[phrase]) {
          groups[phrase] = [];
        }
        groups[phrase].push(item);
      }
    });
    // Convert groups to an array of objects and sort them by total count in the group
    const sortedGroups = Object.entries(groups).map(([group, items]) => ({
      group,
      totalCount: items.reduce((acc, curr) => acc + curr.count, 0), // Sum counts
      // Sort the items within each group by count in descending order
      items: items.sort((a, b) => b.count - a.count),
    }));
    const flattenedKeywords = sortedGroups.flatMap((group) => group.items);
    // Sort the final array so that items with only one keyword go to the end
    return flattenedKeywords.sort((a, b) => {
      const aWords = a.keyword.split(" ").length;
      const bWords = b.keyword.split(" ").length;
      // Push items with only one word to the end
      if (aWords === 1 && bWords > 1) return 1;
      if (bWords === 1 && aWords > 1) return -1;
      // Otherwise, maintain original order
      return 0;
    });
  }
  const combinedH2H3DataArray = [
    ...(aggregated_h2_counts
      ? Object.keys(aggregated_h2_counts).map((keyword) => ({
          keyword: keyword,
          count: aggregated_h2_counts[keyword],
          type: "H2",
        }))
      : []),
    ...(aggregated_h3_counts
      ? Object.keys(aggregated_h3_counts).map((keyword) => ({
          keyword: keyword,
          count: aggregated_h3_counts[keyword],
          type: "H3",
        }))
      : []),
  ];
  const handleSort = () => {
    let sorted;
    let newSortDirection;
    if (sortDirection === "asc") {
      sorted = [...aggregated_results].sort((a, b) => b.count - a.count);
      newSortDirection = "desc";
    } else if (sortDirection === "desc") {
      sorted = [...aggregated_results].sort((a, b) => a.count - b.count);
      newSortDirection = null;
    } else {
      sorted = [...aggregated_results];
      newSortDirection = "asc";
    }
    sortedData = sorted;
    setSortDirection(newSortDirection);
  };
  const aggregatedResultsColumns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "85%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
  ];
  const combinedH2H3Columns = [
    {
      name: "Keyword",
      selector: (row) => row.keyword,
      sortable: true,
      width: "70%",
    },
    {
      name: "Count",
      selector: (row) => Math.round(row.count),
      sortable: true,
      width: "15%",
    },
    { name: "Type", selector: (row) => row.type, sortable: true, width: "15%" },
  ];
  const handleHistory = async () => {
    try {
      setIsLoadingHistory(true);
      setIsHistoryModalOpen(true); // Show modal
      // Fetch data whenever the modal is opened
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
        {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // Filter out entries where kwd_flag is true
      const filteredData = response.data.filter(
        (item) => item.kwd_flag === true
      );
      // Sort filtered data by date from newest to oldest
      const sortedData = filteredData.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      );
      setHistoryData(sortedData);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setIsLoadingHistory(false);
    }
  };
  const handleGenerateArticle = async () => {
    const keywordId = data[0].object_id;
    console.log(keywordId, "keywordId");
    if (data) {
      console.log(keywordId, "initialData");
      if (keywordId) {
        try {
          setIsGenerating(true); // Start loading
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/keyword/generated-articles/`,
            { title: url_title, kwd_id: keywordId }, // Using the new API body structure
            {
              headers: {
                Authorization: `Token ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status === 201) {
            const { Generated_article, headings , word_count} = response.data;
            if (Generated_article && headings && word_count) {
              navigate(
                `/generatedarticle/${userId}/${response.data.object_id}/`,
                {
                  state: {
                    content: Generated_article,
                    headings,
                    url_title,
                    word_count,
                    fromPost: true,
                  },
                }
              );
            }
          }
          console.log(response);
        } catch (error) {
          console.error("Error generating article:", error);
          toast.error("Failed to generate article");
        } finally {
          setIsGenerating(false); // Stop loading
        }
      }
    }
  };
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait, your article is being generated. Do not refresh or close this page."
  );
  useEffect(() => {
    if (isGenerating) {
      const firstTimeout = setTimeout(() => {
        setLoadingMessage("Still loading... Please hold on for a moment.");
      }, 30000); // 30 seconds
      const secondTimeout = setTimeout(() => {
        setLoadingMessage(
          "Please be patient, the process is taking a bit longer than expected."
        );
      }, 60000); // 60 seconds (30 more seconds from the previous message)
      return () => {
        clearTimeout(firstTimeout);
        clearTimeout(secondTimeout);
      };
    }
  }, [isGenerating]);


  const handleExport = (item) => {
    const content = item.Generated_article;
    const title = item.title;

    if (content) {
      const converted = htmlDocx.asBlob(content);
      saveAs(converted, `${title}.docx`);
      toast.success("Article exported as Word");
    } else {
      toast.error("No content available to export.");
    }
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/keyword/generated-articles/${itemToDelete.object_id}/`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setHistoryData((prevData) =>
          prevData.filter((item) => item.object_id !== itemToDelete.object_id)
        );
        toast.success("Article deleted successfully");
      } catch (error) {
        console.error("Error deleting article:", error);
      } finally {
        setIsConfirmationModalOpen(false); // Close confirmation modal
        setItemToDelete(null); // Clear the item to delete
      }
    }
  };
  return (
    <div
      className="relative w-full mx-auto mt-[5rem] rounded-lg
   bg-baseColor dark:bg-darkbg dark:text-gray-300 md:mt-14 md:p-5"
    >
       <ConfirmationModal
    show={isConfirmationModalOpen}
    onClose={() => setIsConfirmationModalOpen(false)}
    onConfirm={handleConfirmDelete}
    title={itemToDelete?.title}
  />
      {isGenerating && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[1003] backdrop-blur-sm">
          <div className="flex flex-col items-center gap-4">
            <FaSpinner className="animate-spin text-white text-5xl" />
            <p className="text-white text-lg">{loadingMessage}</p>
          </div>
        </div>
      )}
      {!isKeywordLoading && !isUrlLoading && (
        <div className="flex justify-end gap-4 mb-5">
          <button
            onClick={handleGenerateArticle}
            className="flex items-center gap-2 bg-gradient-to-r
            from-sky-500 to-sky-300 text-white px-4 py-2 rounded-full shadow-lg
            transform transition duration-200 ease-in-out hover:scale-105"
            disabled={isGenerating}
          >
            Generate and View Article
          </button>
          <button
            onClick={handleHistory}
            className="flex items-center gap-2 bg-gradient-to-r from-gray-500 to-gray-300
               text-white px-4 py-2 rounded-full shadow-lg
               transform transition duration-200 ease-in-out hover:scale-105"
          >
            Show History
          </button>
        </div>
      )}
      {isHistoryModalOpen && (
        <div
          onClick={(event) => {
            if (event.target === event.currentTarget) {
              closeModal();
            }
          }}
          className="pointer-events-auto fixed inset-0 z-[1002]
  grid h-screen w-screen place-items-center bg-opacity-30 backdrop-blur-sm transition-opacity duration-300"
        >
          <div className="bg-white rounded-lg p-6 w-[50%]
           max-h-[60vh] overflow-auto shadow-lg border border-gray-300">
            <h2 className="text-xl font-bold mb-4 pb-2 border-b border-gray-300">
              Article History Keyword
            </h2>
            {isLoadingHistory ? (
              <div className="flex justify-center items-center">
                <FaSpinner className="animate-spin text-2xl" />
              </div>
            ) : historyData.length > 0 ? (
              <ul className="space-y-4">
                {historyData
                  .sort(
                    (a, b) =>
                      Date.parse(b.created_at) - Date.parse(a.created_at)
                  ) // Ensuring proper date parsing
                  .map((item) => (
                    <li
                      key={item.object_id}
                      className="border-b pb-2 cursor-pointer flex justify-between items-center"
                    >
                      <div>
                        <h3
                          onClick={() => handleViewDetails(item)}
                          className="font-semibold"
                        >
                          {item.title}
                        </h3>
                        <p className="text-sm text-gray-500">
                          Created At:{" "}
                          {new Date(item.created_at).toLocaleString()}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => handleViewDetails(item)}
                          className="text-mainColor px-2 py-2 rounded-md transition duration-200"
                          title="View Details"
                        >
                          <FaEye />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(item)} // Use the new delete click handler

                          className="text-red-500 px-2 py-2 rounded-md transition duration-200"
                          title="Delete Article"
                        >
                          <FaTrash />
                        </button>
                        <button
                            onClick={() => handleExport(item)}
                          className="text-blue-500 px-2 py-2 rounded-md transition duration-200"
                          title="Export Article as Word"
                        >
                          <FaRegFileWord />
                        </button>

                      </div>
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No history found.</p>
            )}
            <button
              onClick={closeModal}
              className="flex mt-3 ml-auto px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div
        className={`items-center w-full ${
          (parsedResultsSample || urlData) &&
          "grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-3"
        }`}
      >
        <div className="lg:col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>
        {isKeywordLoading ? (
          <SkeletonMainKeywordInfo />
        ) : (
          <MainKeywordInfo
            url={urlData ? true : false}
            keyword={Query}
            wordCount={Number(weighted_word_count).toFixed()}
            optimizationScore={75}
          />
        )}
      </div>
      {data || keywordData ? (
        <Tables
          linksData={filteredLinks}
          aggregatedResults={sortedData}
          aggregatedResultsColumns={aggregatedResultsColumns}
          combinedH2H3DataArray={combinedH2H3DataArray}
          combinedH2H3Columns={combinedH2H3Columns}
          onSort={handleSort}
          isKeywordLoading={isKeywordLoading}
        />
      ) : (
        <div className=" lg:w-[80%] flex items-center justify-center py-8 border border-gray-300 rounded m-auto my-8">
          <p className="font-medium">No data available</p>
        </div>
      )}
    </div>
  );
};
export default ResultSample;
