import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { deleteProjects } from "../redux/lib/deleteBulkProjects";
import { deleteProject } from "../redux/lib/deleteProject";
import { fetchData } from "../redux/lib/fetchData";

const useDeleteOperations = (
  userId,
  projectId,
  setFilteredData,
  setTotalDeletes,
  setCompleteDeletes,
  setIsDelete,
  pin_project,
  setPin_project
) => {
  // setCompletedUpdates((prev) => prev + 1);

  const dispatch = useDispatch();
  const [isBulkDeleteDialogOpen, setIsBulkDeleteDialogOpen] = useState(false);
  const [deletingQueryId, setDeletingQueryId] = useState(null);
  const [checkedRows, setCheckedRows] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false); // Added for delete in progress indicator

  const handleDeleteButtonClick = (query_id) => {
    setDeletingQueryId(query_id);
    setIsDialogOpen(true);
  };

  const confirmDeletion = async () => {
    setIsDialogOpen(false);
    if (!deletingQueryId) return;
    setIsBulkDeleteDialogOpen(false);
    setIsDeleting(true);

    try {
      await dispatch(deleteProject(deletingQueryId)).unwrap();

      setFilteredData((currentData) =>
        currentData.filter((rank) => rank.query_id !== deletingQueryId)
      );

      setPin_project(!pin_project);
      dispatch(fetchData({ userId, projectId }));

      toast.info("Rank deleted successfully");
    } catch (error) {
      console.error("Error deleting rank:", error);
      toast.error(`An error occurred: ${error.message || "Unknown error"}`);
      setPin_project(!pin_project);
    } finally {
      setPin_project(!pin_project);

      setDeletingQueryId(null);
      setIsDeleting(false);
    }
  };

  const cancelDeletion = () => {
    setIsDialogOpen(false);
    setDeletingQueryId(null);
  };

  const handleBulkDelete = () => {
    if (checkedRows.length === 0) {
      toast.info("No queries selected for deletion.");
    } else {
      setIsBulkDeleteDialogOpen(true);
    }
  };
  // const handle
  const handleDeleteConfirmed = async () => {
    setIsDeleting(true);
    setIsDelete(true);
    const total = checkedRows.length;
    setTotalDeletes(total);
    setIsBulkDeleteDialogOpen(false);

    try {
      await dispatch(deleteProjects(checkedRows)).unwrap();

      // Update the filtered data
      setFilteredData((currentData) =>
        currentData.filter((rank) => !checkedRows.includes(rank.query_id))
      );

      setPin_project(!pin_project);
      toast.success(
        `${checkedRows.length} ${
          checkedRows.length > 1 ? "queries" : "query"
        } deleted successfully`
      );

      setCheckedRows([]); // Clear the checked rows after deletion
    } catch (error) {
      console.error("Error in bulk delete:", error);
      toast.error("An error occurred during the bulk delete operation.");
    } finally {
      setIsDeleting(false);
      setIsDelete(false);
      setPin_project(!pin_project);
    }
  };

  const handleCancelDeletion = () => {
    setIsBulkDeleteDialogOpen(false);
  };

  return {
    isDialogOpen,
    isBulkDeleteDialogOpen,
    handleDeleteButtonClick,
    confirmDeletion,
    cancelDeletion,
    handleBulkDelete,
    handleDeleteConfirmed,
    handleCancelDeletion,
    checkedRows,
    setCheckedRows,
    isDeleting, // Return the isDeleting state
  };
};

export default useDeleteOperations;
