import React from "react";
import { Menu } from "@headlessui/react";
import RankRangeSlider from "./RankRangeSlider";

const RankFilter = ({ rankRange, handleRangeChange }) => (
    <div className="relative  ">
          <Menu as="div" className="inline-block w-full text-left">
            <Menu.Button
              className="flex justify-between items-center b
            transition duration-300 border-2 bg-white
             border-gray-200 dark:border-gray-500
              text-gray-500  dark:text-gray-300  dark:bg-darkbg
             pl-4 hover:border-2
             hover:border-gray-400 min-h-[40px] rounded hover:text-gray-700  
              xl:w-[100%] lg:w-[100%] w-full 
             "
            >
              Rank Filter
              <span
                className={`ml-4 transform transition-transform duration-300 `}
              >
                <div className="css-1hb7zxy">
                  <span className="css-1u9des2"></span>
                  <div className="css-1xc3v61" aria-hidden="true">
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      className="css-tj5bde-Svg"
                    >
                      <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                    </svg>
                  </div>
                </div>
              </span>
            </Menu.Button>
            <Menu.Items className="absolute w-[300px] lg:w-full bg-white shadow-md rounded border-2 border-gray-200 mt-1 p-[20px] z-[1000]">
              <RankRangeSlider
                minInitialValue={rankRange.min}
                maxInitialValue={rankRange.max}
                onRangeChange={handleRangeChange}
              />
            </Menu.Items>
          </Menu>
        </div>
);

export default RankFilter;
