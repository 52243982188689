import React from "react";
import GenerateAIBtn from "../keyword/GenerateAIBtn";
import InputForm from "../keyword/InputForm";
import Title from "../../../pages/MainKeyword/keyword/Layout/Title";
import MainKeywordInfo from "../../../pages/MainKeyword/keyword/KeywordInfo/MainKeywordInfo";
import CompanyTabs from "../../keywordresearch/components/url/CompanyTabs";
import DataTableComponent from "../keyword/DataTableComponent";
import SkeletonMainKeywordInfo from "../../Skeleton/SkeletonMainKeywordInfo";
import SkeletonDataTable from "../../Skeleton/SkeletonDataTable";

const InitialDataDisplay = ({
  handleSubmit,
  isKeywordLoading,
  isUrlLoading, // Loading flag
  initialData,
  h2Columns,
  h3Columns,
  urlData,
}) => {
  return (
    <>
      {/* <GenerateAIBtn /> */}
      <div className="items-center w-full grid grid-cols-1 lg:grid-cols-5 md:gap-6 gap-3">
        <div className="col-span-3">
          <Title title="Main Keyword Research" />
          <InputForm
            onSubmit={handleSubmit}
            isLoading={isKeywordLoading || isUrlLoading}
          />
        </div>
        {isUrlLoading
          ? Array.from({ length: 1 }).map((_, index) => (
              <SkeletonMainKeywordInfo key={index} />
            ))
          : initialData &&
            initialData.map((item) => (
              <MainKeywordInfo
                key={item.object_id}
                url={true}
                keyword={item.title}
                wordCount={Number(item.word_count).toFixed()}
                optimizationScore={75}
              />
            ))}
      </div>

      <div className="items-center w-full md:p-5">
        <div className="flex flex-col space-y-8">
          {isUrlLoading ? (
            <SkeletonDataTable />
          ) : (
            <CompanyTabs initialData={initialData} />
          )}

          {isUrlLoading
            ? Array.from({ length: 1 }).map((_, index) => (
                <div
                  key={index}
                  className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
                >
                  <SkeletonDataTable />
                  <SkeletonDataTable />
                </div>
              ))
            : initialData &&
              initialData.map((item) => {
                let h2Data = [];
                let h3Data = [];
                try {
                  h2Data = Object.entries(
                    JSON.parse(
                      item.h2_count.replace(/\\/g, "").replace(/'/g, '"')
                    )
                  ).map(([keyword, count]) => ({ keyword, count }));
                } catch (error) {
                  console.error("Error parsing h2_count:", error);
                }
                try {
                  h3Data = Object.entries(
                    JSON.parse(
                      item.h3_count.replace(/\\/g, "").replace(/'/g, '"')
                    )
                  ).map(([keyword, count]) => ({ keyword, count }));
                } catch (error) {
                  console.error("Error parsing h3_count:", error);
                }

                return (
                  <div
                    key={item.object_id}
                    className="lg:grid lg:grid-cols-2 mt-8 gap-6 md:justify-between max-w-screen-2xl"
                  >
                    <DataTableComponent
                      title="H2 Keywords"
                      columns={h2Columns}
                      data={h2Data}
                    />
                    <DataTableComponent
                      title="H3 Keywords"
                      columns={h3Columns}
                      data={h3Data}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default InitialDataDisplay;
