import React, { useState } from "react";

const AddNewCompetitors = ({
  isOpen,
  onClose,
  handleSelect,
  selectedLinks,
  links,
  projectUrl,
}) => {
  const [selected, setSelected] = useState();
  if (!isOpen) return null;
console.log(selected,'selectedselectedselected');

  return (
    <div
    onClick={(event) => {
      if (event.target === event.currentTarget) {
        onClose();
      }
    }}
    className="pointer-events-auto fixed inset-0 z-[1010] grid h-screen 
    w-screen place-items-center bg-opacity-30 opacity-100 backdrop-blur-sm transition-opacity duration-300"
  >
      {" "}
      <div className="bg-white rounded-lg p-6 w-1/3 shadow-lg flex flex-col ">
        <h2 className="text-lg font-bold mb-4">Add New Competitors</h2>
        <input
          id="linksChoose"
          list="links"
          className="border border-gray-300 rounded my-2 w-full p-1"
          onChange={(e) => {
            setSelected(e.target.value);
          }}
        />
        <datalist id="links">
          {links.map((link) => {
            return (
              <>
                {!link.domain
                  .toLowerCase()
                  .includes(projectUrl.toLowerCase()) &&
                  !selectedLinks.includes(link.domain) && (
                    <option key={link.domain} value={link.domain}>
                      {link.domain}
                    </option>
                  )}
              </>
            );
          })}
        </datalist>
        <button
          onClick={() => {
            handleSelect(selected);
            onClose();
          }}
          className="bg-mainColor text-white px-4 py-2 w-fit ml-auto rounded hover:bg-darkMainColor transition duration-200"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddNewCompetitors;
