const customTableStylesDark = {
  table: {
    style: {
      backgroundColor: "#111417",
      color: "#fff",
      borderColor: "#6b7280",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#111417",
      color: "#fff",
    },
  },
  headCells: {
    style: {
      color: "#fff",
      backgroundColor: "#111417",
    },
  },
  rows: {
    style: {
      backgroundColor: "#111417",
      color: "#fff",
      "&:nth-of-type(odd)": {
        backgroundColor: "#000",
      },
    },
  },
  pagination: {
    style: {
      backgroundColor: "#333",
      color: "#fff",
    },
  },
};
export default customTableStylesDark;