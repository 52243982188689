import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { FaSpinner } from 'react-icons/fa';

// Define the schema for forgot password
const forgotPasswordSchema = z.object({
  email_or_username: z.string().nonempty("Email or Username is required")
});

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(forgotPasswordSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/forgot-password/`, {
        email_or_username: data.email_or_username
      });

      const { Token, uid ,message } = response.data;
      toast.success(message);
      navigate("/check-email");  

      //navigate("/projects")
      // navigate(`/reset-password/${uid}/${Token}`);
    } catch (error) {
      toast.error("Password reset request failed. Please try again.");
      console.error("Password reset error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
  <div className='w-full h-screen flex items-center justify-center'>

    <form onSubmit={handleSubmit(onSubmit)} 
    className="flex flex-col 
    w-full max-w-md p-6 mx-auto 
    mt-20 rounded-lg shadow-custom
     sm:mt-10">
      <h2 className="mb-3 text-2xl font-bold text-center text-mainColor">Forgot Password</h2>
      <p className="mt-4 mb-3 text-md">Enter your email or username below to receive a password reset link.</p>

      <div className="relative mb-6">
        <label className="block mb-2 text-sm font-bold text-gray-700">Email or Username:</label>
        <div className="relative">
          <input
            type="text"
            {...register("email_or_username")}
            className="w-full px-3 py-2 border rounded-lg md:text-lg text-md focus:border-mainColor focus:ring-darkblue"
          />
        </div>
        {errors.email_or_username && <p className="mt-1 text-xs text-red-500">{errors.email_or_username.message}</p>}
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className={`px-4 py-2 mt-5 font-bold text-white rounded transition-colors ${
          isLoading ? 'bg-gray-400 cursor-not-allowed' : 'bg-mainColor hover:bg-mainColor-700'
        }`}
      >
        {isLoading ? (
          <>
            <FaSpinner className="inline-block animate-spin mr-2" />
            Sending Reset Link...
          </>
        ) : (
          'Send Reset Link'
        )}
      </button>
    </form>
    </div>
  );
};

export default ForgotPasswordPage;
